import React, { useEffect, useState } from 'react';
import * as style from './style.scss';

import { sendGetRequest } from 'src/utils/requests';
import RadioButton from 'src/components/RadioButton';
import { championImageUrl } from 'src/utils/helper';

interface Props {
  filteredTeams: any[];
  scrimType?: string;
  startDate: Date | null;
  endDate: Date | null;
  isMain: boolean;
}

const GamesStats: React.FC<Props> = ({ scrimType, startDate, endDate, filteredTeams, isMain }) => {
  const [tableData, setTableData] = useState<any>({ player_games: [] });
  const [role, setRole] = useState<string>('jun');

  const roles = [
    { label: 'Top', value: 'top' },
    { label: 'Jungle', value: 'jun' },
    { label: 'Mid', value: 'mid' },
    { label: 'ADC', value: 'adc' },
    { label: 'Support', value: 'sup' },
  ];

  useEffect(() => {
    const from = startDate ? 'from=' + startDate.toISOString() + '&' : '';
    const to = endDate ? 'to=' + endDate.toISOString() + '&' : '';
    const teams =
      filteredTeams?.length === 0 ? '' : 'teams=' + filteredTeams.map(e => e.name).join(',') + '&';

    const scrimTypeParam = `scrim_type=${scrimType}&`;
    const roleParam = `role=${role}&`;
    const isMainParam = `is_main=${isMain}&`;

    const url = `/scrims/games_stats?${from}${to}${teams}${scrimTypeParam}${roleParam}${isMainParam}`;
    sendGetRequest(url).then(data => {
      setTableData(data);
    });
  }, [filteredTeams.length, scrimType, startDate, endDate, role]);

  const date = (rawDate: string): string => {
    return rawDate.split('T')[0];
  };

  const getRole = (): string | undefined => roles.find(r => r.value === role)?.label;

  return (
    <div className={style.container}>
      <div className={style.role}>
        <RadioButton
          name="role"
          options={roles}
          current={role}
          onChange={r => setRole(r.currentTarget.value)}
        />
      </div>

      <table>
        <thead>
          <tr>
            <th></th>
            <th>Date</th>
            <th>Scrim type</th>
            <th>BK ROG {getRole()}</th>
            <th>Enemy {getRole()}</th>
            <th>XP @ 8min</th>
            <th>E.XP @ 8min</th>
            <th>XP @ 14min</th>
            <th>E.XP @ 14min</th>
          </tr>
        </thead>
        <tbody>
          {tableData.player_games.map((rowData: any) => {
            return (
              <>
                <tr key={rowData.game_id}>
                  <td>
                    <img className={style.logo} src={rowData.opponent.team?.logo}></img>
                  </td>
                  <td>{date(rowData.date)}</td>
                  <td>{rowData.is_blitz === true ? 'Blitz' : 'Normal'}</td>
                  <td>
                    <img
                      className={style.championImage}
                      src={championImageUrl(rowData.ally.champion_slug)}
                      alt={rowData.ally.champion}
                    />
                  </td>
                  <td>
                    <img
                      className={style.championImage}
                      src={championImageUrl(rowData.opponent.champion_slug)}
                      alt={rowData.opponent.champion}
                    />
                  </td>
                  <td>
                    {rowData.ally.at_8.level} - {rowData.ally.at_8.experience}
                  </td>
                  <td>
                    {rowData.opponent.at_8?.level} - {rowData.opponent.at_8?.experience}
                  </td>
                  <td>
                    {rowData.ally.at_14?.level} - {rowData.ally.at_14?.experience}
                  </td>
                  <td>
                    {rowData.opponent.at_14?.level} - {rowData.opponent.at_14?.experience}
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default GamesStats;
