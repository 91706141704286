import React, { useEffect, useReducer, useState } from 'react';
import * as style from './style.scss';

import { ScrimGame } from '../types';

import { reducer as paginationReducer } from 'src/components/Historical/Pagination/reducer';
import { initialState as initialPaginationState } from 'src/components/Historical/Pagination/types';

import InfiniteScroll from 'react-infinite-scroll-component';
import { sendGetRequest } from 'src/utils/requests';
import Draft from 'src/components/Draft';
import { reducer } from '../reducer';
import { initialState } from '../types';

import { Multiselect } from 'multiselect-react-dropdown';
import RadioButton from 'src/components/RadioButton';
import ScrimTable from 'src/components/ScrimTable';

interface Props {
  teamID?: number;
  allyTeam?: string;
  allyAcronym?: string;
  allyLogo?: string;
  isOfficial?: string;
}

const ScrimDrafts: React.FC<Props> = ({
  teamID = 1,
  allyTeam = 'BK ROG Esports',
  allyAcronym = 'BKR',
  isOfficial = 'false',
  allyLogo = '',
}) => {
  const [history, setHistory] = useState<ScrimGame[]>([]);
  const [formState, dispatch] = useReducer(reducer, initialState);

  const [sides, setSides] = useState<('' | 'blue' | 'red')[]>(['blue', 'red']);
  const [results, setResults] = useState<(true | false)[]>([true, false]);

  const displayTypes = [
    { label: 'Draft', value: 'draft' },
    { label: 'Line', value: 'line' },
  ];

  const displayColors = [
    { label: 'Color on Side (blue / red)', value: 'side' },
    { label: 'Color on Result (green / red)', value: 'result' },
  ];

  const displaySides = [
    { label: `${allyAcronym} on game side`, value: 'side' },
    { label: `${allyAcronym} always on the left`, value: 'left' },
  ];

  const [champions, setChampions] = useState([]);

  const [paginationState, dispatchPagination] = useReducer(
    paginationReducer,
    initialPaginationState,
  );

  const [gameTypes, setGameTypes] = useState<('' | 'official' | 'scrim')[]>(['official', 'scrim']);
  const [displayColor, setDisplayColor] = useState<string>('side');
  const [displaySide, setDisplaySide] = useState<string>('side');
  const [displayType, setDisplayType] = useState<string>('draft');

  const getGames = (currentGames: ScrimGame[], page: number): void => {
    const teams =
      formState.filteredTeams.length === 0
        ? ''
        : 'teams=' + formState.filteredTeams.map(e => e.name).join(',') + '&';

    const allyChampions =
      formState.filteredChampions.length === 0
        ? ''
        : 'champions=' + formState.filteredChampions.map((e: any) => e.name).join(',') + '&';

    const url = `/lolesports/teams/${teamID}/drafts?page=${page}&is_official=${isOfficial}&${teams}${allyChampions}`;

    dispatchPagination({ type: 'SET_LOADING', payload: true });
    sendGetRequest(url).then(data => {
      dispatchPagination({ type: 'SET_LOADING', payload: false });
      setHistory(currentGames.concat(data));

      if (data.length === 0) {
        dispatchPagination({ type: 'SET_HAS_MORE', payload: false });
      }
    });
  };

  const groupByDate = (games: ScrimGame[]) => {
    return games.reduce(function(rv, game) {
      const date = game.date ? game.date.toString().split('T')[0] : 'zut';

      (rv[date] = rv[date] || []).push(game);
      return rv;
    }, {} as any);
  };

  useEffect(() => {
    dispatchPagination({ type: 'SET_PAGE', payload: 0 });
    dispatchPagination({ type: 'SET_HAS_MORE', payload: true });
    getGames([], 0);
  }, [formState.filteredTeams.length, formState.filteredChampions.length]);

  useEffect(() => {
    const url = '/champions';
    sendGetRequest(url).then(data => setChampions(data));
  }, []);

  const loadMoreGames = (): void => {
    const newPage = paginationState.page + 1;
    dispatchPagination({ type: 'SET_PAGE', payload: newPage });
    getGames(history, newPage);
  };

  useEffect(() => {
    const url = `/scrims/teams`;
    sendGetRequest(url).then(data => {
      dispatch({
        type: 'SET_TEAMS',
        payload: data.map((t: any) => ({
          id: t.id,
          name: t.name,
          acronym: t.acronym,
          full_name: `${t.name} - ${t.acronym}`,
        })),
      });
    });
  }, []);

  return (
    <div className={style.container}>
      <div className={style.title}>{isOfficial === 'false' ? 'Scrim drafts' : 'All drafts'}</div>

      <div className={style.teamsFilter}>
        <Multiselect
          options={formState.teams}
          onSelect={(list: any[]) => dispatch({ type: 'SET_FILTERED_TEAMS', payload: list })}
          onRemove={(list: any[]) => dispatch({ type: 'SET_FILTERED_TEAMS', payload: list })}
          displayValue="full_name"
          style={{
            optionContainer: { backgroundColor: '#1D2E3E' },
            searchBox: { backgroundColor: 'rgb(167, 167, 171)' },
          }}
          placeholder="Search team"
        />
      </div>

      <div className={style.teamsFilter}>
        <Multiselect
          options={champions}
          onSelect={(list: any[]): void =>
            dispatch({ type: 'SET_FILTERED_CHAMPIONS', payload: list })
          }
          onRemove={(list: any[]): void =>
            dispatch({ type: 'SET_FILTERED_CHAMPIONS', payload: list })
          }
          displayValue="name"
          style={{
            optionContainer: { backgroundColor: '#1D2E3E' },
            searchBox: { backgroundColor: 'rgb(167, 167, 171)' },
          }}
          placeholder="Search ally champion"
        />
      </div>

      <div className={style.filters}>
        <div className={style.dataFilters}>
          <div className={style.teamsFilter}>
            <input
              type="checkbox"
              name="blueSide"
              checked={sides.includes('blue')}
              className={style.inputCheckbox}
              onChange={(): void =>
                sides.includes('blue')
                  ? setSides(sides.filter(side => side !== 'blue'))
                  : setSides(sides.concat(['blue']))
              }
            />
            <label htmlFor="blueSide">Blue side</label>
            <input
              type="checkbox"
              name="redSide"
              checked={sides.includes('red')}
              className={style.inputCheckbox}
              onChange={(): void =>
                sides.includes('red')
                  ? setSides(sides.filter(side => side !== 'red'))
                  : setSides(sides.concat(['red']))
              }
            />
            <label htmlFor="redSide">Red side</label>
          </div>

          <div className={style.teamsFilter}>
            <input
              type="checkbox"
              name="winCheckbox"
              checked={results.includes(true)}
              className={style.inputCheckbox}
              onChange={(): void =>
                results.includes(true)
                  ? setResults(results.filter(result => !result))
                  : setResults(results.concat([true]))
              }
            />
            <label htmlFor="winCheckbox">Wins</label>
            <input
              type="checkbox"
              name="loseCheckbox"
              checked={results.includes(false)}
              className={style.inputCheckbox}
              onChange={(): void =>
                results.includes(false)
                  ? setResults(results.filter(result => !!result))
                  : setResults(results.concat([false]))
              }
            />
            <label htmlFor="loseCheckbox">Losses</label>
          </div>

          {isOfficial === 'all' && (
            <div className={style.teamsFilter}>
              <input
                type="checkbox"
                name="officialGame"
                checked={gameTypes.includes('official')}
                className={style.inputCheckbox}
                onChange={(): void =>
                  gameTypes.includes('official')
                    ? setGameTypes(gameTypes.filter(gameType => gameType !== 'official'))
                    : setGameTypes(gameTypes.concat(['official']))
                }
              />
              <label htmlFor="blueSide">Official Games</label>
              <input
                type="checkbox"
                name="scrims"
                checked={gameTypes.includes('scrim')}
                className={style.inputCheckbox}
                onChange={(): void =>
                  gameTypes.includes('scrim')
                    ? setGameTypes(gameTypes.filter(side => side !== 'scrim'))
                    : setGameTypes(gameTypes.concat(['scrim']))
                }
              />
              <label htmlFor="redSide">Scrims</label>
            </div>
          )}
        </div>

        {isOfficial === 'all' && (
          <div>
            <div>Display options</div>
            <RadioButton
              name="display-type"
              options={displayTypes}
              current={displayType}
              onChange={d => setDisplayType(d.currentTarget.value)}
            />
            {displayType === 'line' && (
              <>
                <RadioButton
                  name="display-color"
                  options={displayColors}
                  current={displayColor}
                  onChange={d => setDisplayColor(d.currentTarget.value)}
                />

                <RadioButton
                  name="display-side"
                  options={displaySides}
                  current={displaySide}
                  onChange={d => setDisplaySide(d.currentTarget.value)}
                />
              </>
            )}
          </div>
        )}
      </div>

      <InfiniteScroll
        dataLength={history.length}
        next={loadMoreGames}
        hasMore={paginationState.hasMore}
        loader={<h4>Loading...</h4>}
      >
        <div className={style.drafts}>
          {history.length > 0 &&
            (isOfficial === 'all'
              ? (
                displayType === 'draft' ? (
                history
                  .filter(game => sides.includes(game.side))
                  .filter(
                    game =>
                      (gameTypes.includes('official') && game.is_official) ||
                      (gameTypes.includes('scrim') && !game.is_official),
                  )
                  .filter(game => game.is_blitz || results.includes(game.result))
                  .map((game: ScrimGame) => (
                    <Draft
                      game={game}
                      key={game.id}
                      allyTeam={allyTeam}
                      allyAcronym={allyAcronym}
                    />
                  )))
                  :
                  <ScrimTable
                  games={history
                    .filter(game => sides.includes(game.side))
                    .filter(
                      game =>
                        (gameTypes.includes('official') && game.is_official) ||
                        (gameTypes.includes('scrim') && !game.is_official),
                    )
                    .filter(game => game.is_blitz || results.includes(game.result))}
                  allyTeam={allyTeam}
                  allyAcronym={allyAcronym}
                  allyLogo={allyLogo}
                  displayColor={displayColor}
                  displaySide={displaySide}
                />
                  )
              : Object.keys(groupByDate(history)).length > 0 &&
                Object.keys(groupByDate(history)).map((day: string) => (
                  <div className={style.dayContainer} key={day}>
                    {groupByDate(history)
                      [day].filter((game: ScrimGame) => sides.includes(game.side))
                      .filter((game: ScrimGame) => results.includes(game.result))
                      .map((game: ScrimGame) => (
                        <Draft
                          game={game}
                          key={game.id}
                          allyTeam={allyTeam}
                          allyAcronym={allyAcronym}
                        />
                      ))}
                  </div>
                )))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default ScrimDrafts;
