import React, { useReducer } from 'react';

import * as style from '../style.scss';
import { cx as classnames } from 'emotion';
import { initialState, reducer } from './reducer'
import { sendGetRequest } from 'src/utils/requests'

import { addAccount, isSubmittable } from './helper'
import { Player } from 'src/pages/Player/types'
import Autocomplete from 'src/components/Autocomplete'
import { Account } from 'src/pages/Account/types';

interface Props {
  close: () => void,
  player: Player,
  onChange: (accounts: Account[]) => void,
}

const AddAccount: React.FC<Props> = ({ player, close, onChange }) => {
  const [formState, dispatch] = useReducer(reducer, initialState)
  const [allAccounts, setAllAccounts] = React.useState<Account[]>([])

  const handleAccount = (value: string) => {
    dispatch({ type: 'SET_ACCOUNT', payload: value })
  }

  React.useEffect(() => {
    const url = '/accounts'

    sendGetRequest(url).then(data => setAllAccounts(data));
  }, []);

  const add = () => {
    if (!isSubmittable(formState)) {
      return
    }
    addAccount(player, formState).then(data => onChange(data));
    close()
  }

  return <div className={style.container}>
    <div className={style.title}>Add an account</div>
    <div className={style.form}>
      <Autocomplete allSuggestions={allAccounts} field='summoner_name' handleChange={handleAccount} value={formState.account} />
    </div>
    <div className={style.footer}>
      <div className={style.button} onClick={close}>Cancel</div>
      <div className={classnames(style.button, style.add, isSubmittable(formState) ? '' : style.disabled)} onClick={add}>Add</div>
    </div>
  </div>;
}

export default AddAccount;