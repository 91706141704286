import { Account } from 'src/pages/Account/types'
import { Role } from 'src/utils/helper';

interface Player {
  id: number,
  accounts: Account[],
  team: string,
  role: Role,
  name: string,
  totals?: Totals,
}

interface Totals {
  champions: Champion[],
  games: number | null,
  number_of_champions: number,
  wins: number,
}

interface Champion {
  date: string,
  games: number,
  name: string,
  wins: number,
  losses: number,
  assists?: number,
  deaths?: number,
  kills?: number,
  slug?: string,
  blitz_games: number,
  total: number,
}

const initialState: Player = {
  id: -1,
  accounts: [],
  team: '',
  role: '',
  name: '',
}

export { initialState, Player, Champion, Totals };