import React from 'react';
import * as style from './style.scss';

interface Option {
  label: string;
  value: string;
}

interface Props {
  name: string;
  options: Option[];
  current: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton: React.FC<Props> = ({ name, options, current, onChange }) => {
  return (
    <div className={style.container}>
      {options.map(option => (
        <label key={option.value} className={style.button}>
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={current === option.value}
            onChange={onChange}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};

export default RadioButton;
