import React from 'react';

import * as style from './style.scss'

interface Props {
  leftLabel: string;
  rightLabel: string;
  progressValue: number;
  color: string;
}

const LoadingBar: React.FC<Props> = ({ leftLabel, rightLabel, progressValue, color }) => {
  return (
    <div>
      <div className={style.title}>
        <div>{leftLabel}</div>
        <div className={style.rightLabel}>{rightLabel}</div>
      </div>
      <div className={style.loadingBar}>
        <div
          className={style.loadingProgress}
          style={{ width: `${progressValue}%`, backgroundColor: color }}
        />
      </div>
    </div>
  );
};

export default LoadingBar;
