import React from 'react';

import { XYPlot, VerticalBarSeries, XAxis, YAxis, LabelSeries, Hint } from 'react-vis';

import * as style from './style.scss';

interface Props {
  data: any[];
}

interface HoveredCell {
  cell: {
    x: React.ReactText;
    y: number;
  };
  tooltip: string;
}

const VerticalBarChart: React.FC<Props> = ({ data }) => {
  const [hoveredCell, setHoveredCell] = React.useState<HoveredCell | null>(null);

  return (
    <div className={style.container}>
      <XYPlot xType="ordinal" width={500} height={200} yDomain={[0, 100]} yBaseValue={0}>
        <XAxis style={{ fill: 'white' }} />
        <YAxis style={{ fill: 'white' }} />
        <VerticalBarSeries
          data={data}
          barWidth={0.3}
          colorType="literal"
          onValueMouseOver={value =>
            setHoveredCell({ cell: { x: value.x, y: value.y }, tooltip: value.tooltip })
          }
          onValueMouseOut={_value => setHoveredCell(null)}
        />
        <LabelSeries
          data={data.map(obj => {
            return { ...obj, label: obj.y.toString() + '%' };
          })}
          labelAnchorX="middle"
          style={{ fill: 'white' }}
        />
        {hoveredCell && (
          <Hint
            style={{ backgroundColor: 'white', border: '1px solid #C4C4C4' }}
            value={hoveredCell.cell}
          >
            {hoveredCell.tooltip}
          </Hint>
        )}
      </XYPlot>
    </div>
  );
};

export default VerticalBarChart;
