import { FormState, SpellBaseData, SpellKey } from './types';

// base value, ok if outdated
const CURRENT_PATCH = '11.10.1';

const formatFormStateToSend = (formState: FormState): FormState => {
  return {
    ...formState,
    items: formState.items.filter(item => item !== ''),
  };
};

const findSpellByKey = (spells: SpellBaseData[], key: SpellKey): SpellBaseData | undefined => {
  return spells.find(spell => spell.key === key);
};

const hasSteroidForm = (spells: SpellBaseData[]): boolean => {
  return spells.filter(spell => spell.damage_formula.includes('Steroid_Form')).length > 0;
};

const pctEnemyHealth = (enemyStats: { hp: number } | undefined, spellDamages: number): number => {
  if (!enemyStats || !spellDamages) {
    return 0;
  }

  return parseFloat(((spellDamages / enemyStats.hp) * 100).toFixed(2));
};

export { CURRENT_PATCH, formatFormStateToSend, findSpellByKey, hasSteroidForm, pctEnemyHealth };
