import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import HistoricalSidebar from 'src/components/Historical/Sidebar';

import { Champion, Duo, Matchup } from '../types';
import { FilterObject, FormState } from 'src/components/Historical/Sidebar/types';

import * as style from './style.scss';

import { sendGetRequest } from 'src/utils/requests';
import HistoricalChampionsList from './List';
import HistoricalChampionsDuos from './Duos';
import HistoricalChampionsMatchups from './Matchups';

const HistoricalChampions: React.FC = () => {
  // tabs data
  const [champions, setChampions] = useState<Champion[]>([]);
  const [duos, setDuos] = useState<Duo[]>([]);
  const [matchups, setMatchups] = useState<Matchup[]>([]);

  // in tab filters
  const [role1, setRole1] = useState<string>('top');
  const [role2, setRole2] = useState<string>('jun');
  const [r1Champion, setR1Champion] = useState<string>('');

  const [allChampions, setAllChampions] = useState<FilterObject[]>([]);
  const [champion, setChampion] = useState<string>('Gangplank');

  const [filters, setFilters] = useState<string>('');

  const getChampions = (currentChampions: Champion[], url_params: string): void => {
    const url = `/bayes/champions?${url_params}`;

    sendGetRequest(url).then(data => {
      setChampions(currentChampions.concat(data));
    });
  };

  const getDuos = (currentDuos: Duo[], url_params: string): void => {
    const url = `/lolesports/duos?${url_params}&role1=${role1}&role2=${role2}&champion=${r1Champion}`;

    sendGetRequest(url).then(data => {
      setDuos(currentDuos.concat(data));
    });
  };

  const getMatchups = (currentMatchups: Matchup[], url_params: string): void => {
    const url = `/lolesports/matchups?${url_params}&champion=${champion}`;

    sendGetRequest(url).then(data => {
      setMatchups(data);
    });
  };

  useEffect(() => {
    getChampions([], '');
    sendGetRequest('/champions').then(data => setAllChampions(data));
  }, []);

  useEffect(() => {
    getDuos([], filters);
  }, [role1, role2, r1Champion]);

  useEffect(() => {
    getMatchups([], filters);
  }, [champion]);

  const applyFilters = (filters: FormState): void => {
    const leagues = filters.leagues.map(league => league.id);
    const patches = filters.patches.map(
      patch => patch.name.split('.')[0] + '.' + patch.name.split('.')[1],
    );
    const teams = filters.teams.map(team => team.id);
    // const roles = filters.roles.map(role => role.id);
    const newUrlParams = `teams=${teams}&leagues=${leagues}&patches=${patches}`;
    setFilters(newUrlParams);
    getChampions([], newUrlParams);
    getDuos([], newUrlParams);
    getMatchups([], newUrlParams);
  };

  return (
    <div className={style.container}>
      <HistoricalSidebar onApply={applyFilters} pageType="champions" />

      <Tabs className={style.tabsContainer} selectedTabClassName={style.selectedTab}>
        <TabList className={style.tabsHeader}>
          <Tab className={style.tab} key="list">
            Champions list
          </Tab>
          <Tab className={style.tab} key="duos">
            Duos
          </Tab>
          <Tab className={style.tab} key="matchups">
            Matchups
          </Tab>
        </TabList>

        <TabPanel>
          <HistoricalChampionsList champions={champions} />
        </TabPanel>
        <TabPanel>
          <div className={style.rolePicker}>
            <select
              value={role1}
              placeholder="Role"
              onChange={(event): void => setRole1(event.target.value)}
            >
              <option value={'top'}>Top</option>
              <option value={'jun'}>Jungle</option>
              <option value={'mid'}>Mid</option>
              <option value={'adc'}>Adc</option>
              <option value={'sup'}>Support</option>
            </select>

            <select
              value={role2}
              placeholder="Role"
              onChange={(event): void => setRole2(event.target.value)}
            >
              <option value={'top'}>Top</option>
              <option value={'jun'}>Jungle</option>
              <option value={'mid'}>Mid</option>
              <option value={'adc'}>Adc</option>
              <option value={'sup'}>Support</option>
            </select>
          </div>
          <div className={style.rolePicker}>
            <select
              value={r1Champion}
              placeholder="Champion"
              onChange={(event): void => setR1Champion(event.target.value)}
            >
              <option key="none" value="" />
              {allChampions.map(champion => {
                return (
                  <option key={champion.id} value={champion.name}>
                    {champion.name}
                  </option>
                );
              })}
            </select>
          </div>
          <HistoricalChampionsDuos duos={duos} />
        </TabPanel>
        <TabPanel>
          <div className={style.rolePicker}>
            <select
              value={champion}
              placeholder="Champion"
              onChange={(event): void => setChampion(event.target.value)}
            >
              {allChampions.map(champion => {
                return (
                  <option key={champion.id} value={champion.name}>
                    {champion.name}
                  </option>
                );
              })}
            </select>
          </div>

          <HistoricalChampionsMatchups matchups={matchups} />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default HistoricalChampions;
