import React, { useEffect, useReducer, useState } from 'react';

import Autocomplete from 'src/components/Autocomplete';
import { sendGetRequest, sendPostRequest } from 'src/utils/requests';
import { Champion, Item } from '../Champion/types';
import { findSpellByKey, formatFormStateToSend, hasSteroidForm, pctEnemyHealth } from './helper';
import { reducer } from './reducer';
import { ApheliosWeapon, initialState, SpellBaseData } from './types';

import * as style from './style.scss';
import Toggle from 'src/components/Toggle';

const Theorycraft: React.FC<{}> = (): JSX.Element => {
  const [allChampions, setAllChampions] = useState<Champion[]>([]);
  const [allItems, setAllItems] = useState<Item[]>([]);
  const [formState, dispatch] = useReducer(reducer, initialState);

  const [isEditItems, setEditItems] = useState<boolean>(false);
  const [isEditSylasUlt, setEditSylasUlt] = useState<boolean>(false);

  useEffect((): void => {
    sendGetRequest('/champions/names').then(data => setAllChampions(data));
    sendGetRequest('/patches/current').then(data =>
      dispatch({ type: 'SET_PATCH', payload: data + '.1' }),
    );
  }, []);

  useEffect((): void => {
    sendGetRequest(`/data/items?patch=${formState.patch}`).then(data => setAllItems(data));
  }, [formState.patch]);

  const onSendChampion = (): void => {
    // it validates first step of the form
    // which impacts the rest, like spells has to be resetted
    sendPostRequest(`/champions/${formState.name}/stats`, formatFormStateToSend(formState)).then(
      data => {
        dispatch({ type: 'SET_CHAMPION_DATA', payload: data.champion });
        dispatch({ type: 'SET_STATS', payload: data.stats });
        dispatch({ type: 'SET_SPELLS_DMG', payload: data.spells });
        dispatch({ type: 'SET_ENEMY_STATS', payload: data.enemy_stats });
      },
    );
  };

  // only partial reset of the form
  const onSendBuild = (): void => {
    sendPostRequest(`/champions/${formState.name}/stats`, formatFormStateToSend(formState)).then(
      data => {
        dispatch({ type: 'SET_STATS', payload: data.stats });
        dispatch({ type: 'SET_SPELLS_DMG', payload: data.spells });
        dispatch({ type: 'SET_ENEMY_STATS', payload: data.enemy_stats });
      },
    );
  };

  const onClearChampion = (): void => {
    dispatch({ type: 'CLEAR_STATE' });
  };

  const onSaveItems = (): void => {
    setEditItems(!isEditItems);
    onSendBuild();
  };

  const onSetRune = (tier: number, value: string): void => {
    dispatch({ type: 'SET_RUNE', payload: { tier, value } });
    const runes = formState.runes;
    runes[tier] = value;
    sendPostRequest(
      `/champions/${formState.name}/stats`,
      formatFormStateToSend({ ...formState, runes }),
    ).then(data => {
      dispatch({ type: 'SET_STATS', payload: data.stats });
      dispatch({ type: 'SET_SPELLS_DMG', payload: data.spells });
      dispatch({ type: 'SET_ENEMY_STATS', payload: data.enemy_stats });
    });
  };

  const onSetEnemy = (type: string): void => {
    dispatch({ type: 'SET_ENEMY_TYPE', payload: type });
    sendPostRequest(
      `/champions/${formState.name}/stats`,
      formatFormStateToSend({ ...formState, enemy: { ...formState.enemy, type } }),
    ).then(data => {
      dispatch({ type: 'SET_STATS', payload: data.stats });
      dispatch({ type: 'SET_SPELLS_DMG', payload: data.spells });
      dispatch({ type: 'SET_ENEMY_STATS', payload: data.enemy_stats });
    });
  };

  const onSetTimestamp = (time: string): void => {
    dispatch({ type: 'SET_INGAME_TIMER', payload: time });
    sendPostRequest(
      `/champions/${formState.name}/stats`,
      formatFormStateToSend({ ...formState, enemy: { ...formState.enemy, time } }),
    ).then(data => {
      dispatch({ type: 'SET_STATS', payload: data.stats });
      dispatch({ type: 'SET_SPELLS_DMG', payload: data.spells });
      dispatch({ type: 'SET_ENEMY_STATS', payload: data.enemy_stats });
    });
  };

  const onSetApheliosWeapon = (weapon: ApheliosWeapon): void => {
    dispatch({ type: 'SET_APHELIOS_WEAPON', payload: weapon });
    sendPostRequest(
      `/champions/${formState.name}/stats`,
      formatFormStateToSend({ ...formState, aphelios_weapon: weapon }),
    ).then(data => {
      dispatch({ type: 'SET_SPELLS_DMG', payload: data.spells });
    });
  };

  const onSetSylasUlt = (): void => {
    setEditSylasUlt(false);
    sendPostRequest(`/champions/${formState.name}/stats`, formatFormStateToSend(formState)).then(
      data => {
        dispatch({ type: 'SET_SPELLS_DMG', payload: data.spells });
      },
    );
  };

  const onSpellDown = (spell: SpellBaseData): void => {
    if (spell.key === 'Passive') {
      return;
    }

    if (formState.spells_levels[spell.key] === 0) {
      return;
    }

    dispatch({
      type: 'SET_SPELL_LEVEL',
      payload: { key: spell.key, level: formState.spells_levels[spell.key] - 1 },
    });
    onSendBuild();
  };

  const onSpellUp = (spell: SpellBaseData): void => {
    if (spell.key === 'Passive') {
      return;
    }

    if (spell.maxrank === formState.spells_levels[spell.key]) {
      return;
    }

    dispatch({
      type: 'SET_SPELL_LEVEL',
      payload: { key: spell.key, level: formState.spells_levels[spell.key] + 1 },
    });
    onSendBuild();
  };

  const onSteroid = (steroid: 'Q' | 'W' | 'E' | 'R' | 'P' | 'Form'): void => {
    dispatch({
      type: 'SET_STEROID',
      payload: { key: steroid, value: !formState.steroids[steroid] },
    });
    onSendBuild();
  };

  const passiveBlock = (): JSX.Element => {
    if (!formState.champion) {
      return <></>;
    }

    const spell: SpellBaseData | undefined = findSpellByKey(formState.champion.spells, 'Passive');
    if (!spell) {
      return <>no passive</>;
    }

    return (
      <div className={style.spellBlock}>
        <img
          key={spell.name}
          className={style.spellImg}
          src={`http://ddragon.leagueoflegends.com/cdn/13.9.1/img/passive/${spell.slug}.png`}
        />
        <div className={style.spellName}>
          {spell.name} ({spell.key})
        </div>
        {spell.damage_formula.includes('Steroid_P') && (
          <Toggle on={formState.steroids.P} click={(): void => onSteroid('P')} />
        )}
      </div>
    );
  };

  const spellBlock = (key: 'Q' | 'W' | 'E' | 'R'): JSX.Element => {
    if (!formState.champion) {
      return <></>;
    }

    const spell: SpellBaseData | undefined = findSpellByKey(formState.champion.spells, key);
    if (!spell) {
      return <></>;
    }

    const steroidName = `Steroid_${key[0]}`;

    return (
      <div className={style.spellBlock}>
        <img
          key={spell.name}
          className={style.spellImg}
          src={`https://opgg-static.akamaized.net/images/lol/spell/${spell.slug}.png`}
        />
        <div className={style.spellName}>
          {spell.name} ({spell.key}):
        </div>
        <button onClick={(): void => onSpellDown(spell)} className={style.spellButton}>
          -
        </button>
        <div className={style.spellLevel}>{formState.spells_levels[key]}</div>
        <button onClick={(): void => onSpellUp(spell)} className={style.spellButton}>
          +
        </button>
        {spell.damage_formula.includes(steroidName) && (
          <Toggle on={formState.steroids[key]} click={(): void => onSteroid(key)} />
        )}
      </div>
    );
  };

  return (
    <div className={style.container}>
      <div className={style.title}>Damage Calculator</div>

      <div className={style.topFilters}>
        <div className={style.topFilter}>
          <div>Champion</div>
          <Autocomplete
            allSuggestions={allChampions}
            field="name"
            handleChange={(value: string): void =>
              dispatch({ type: 'SET_CHAMPION_NAME', payload: value })
            }
            value={formState.name}
          />
        </div>
        <div className={style.topFilter}>
          <div>Level</div>
          <input
            className={style.level}
            type="number"
            min="1"
            max="18"
            onChange={(value): void =>
              dispatch({ type: 'SET_LEVEL', payload: parseInt(value.target.value) })
            }
            value={formState.level}
          ></input>
        </div>
        <div className={style.topFilter}>
          <div>Patch</div>
          <input
            className={style.patch}
            type="text"
            onChange={(value): void => dispatch({ type: 'SET_PATCH', payload: value.target.value })}
            value={formState.patch}
          ></input>
        </div>
        <button className={style.okButton} onClick={onSendChampion}>
          OK
        </button>
        <button className={style.okButton} onClick={onClearChampion}>
          Clear
        </button>
      </div>

      {formState.champion && (
        <div className={style.resultsContainer}>
          <div className={style.buildContainer}>
            <div className={style.subTitle}>Rune shards</div>
            <div className={style.runesContainer}>
              <div className={style.runesCategory}>Slot 1 - Offense</div>
              <select
                value={formState.runes[0]}
                onChange={(event): void => onSetRune(0, event.target.value)}
              >
                <option value="none">None</option>
                <option value="adaptative">Adaptative Force</option>
                <option value="attack_speed">Attack Speed</option>
                <option value="ability_haste">Ability Haste</option>
              </select>
              <div className={style.runesCategory}>Slot 2 - Flex</div>
              <select
                value={formState.runes[1]}
                onChange={(event): void => onSetRune(1, event.target.value)}
              >
                <option value="none">None</option>
                <option value="adaptative">Adaptative Force</option>
                <option value="armor">Armor</option>
                <option value="magic_res">Magic Resist</option>
              </select>
              <div className={style.runesCategory}>Slot 3 - Defense</div>
              <select
                value={formState.runes[2]}
                onChange={(event): void => onSetRune(2, event.target.value)}
              >
                <option value="none">None</option>
                <option value="health">Health</option>
                <option value="armor">Armor</option>
                <option value="magic_res">Magic Resist</option>
              </select>
            </div>

            <div className={style.subTitle}>Items</div>
            <div className={style.itemsContainer}>
              {formState.items.map(
                (item: string, index: number): JSX.Element => (
                  <>
                    {isEditItems ? (
                      <Autocomplete
                        allSuggestions={allItems}
                        field="name"
                        handleChange={(value: string): void =>
                          dispatch({ type: 'SET_ITEM', payload: { name: value, index } })
                        }
                        value={item}
                        key={index}
                      />
                    ) : (
                      <div key={index}>{item}</div>
                    )}
                  </>
                ),
              )}
              {isEditItems ? (
                <button className={style.okButton} onClick={onSaveItems}>
                  Save Items
                </button>
              ) : (
                <button className={style.okButton} onClick={(): void => setEditItems(!isEditItems)}>
                  Edit Items
                </button>
              )}
            </div>

            <div className={style.subTitle}>Spells</div>
            <div>
              {passiveBlock()}
              {spellBlock('Q')}
              {spellBlock('W')}
              {spellBlock('E')}
              {spellBlock('R')}
            </div>
            {hasSteroidForm(formState.champion.spells) && (
              <div className={style.steroidBlock}>
                <div className={style.steroidLabel}>Steroid Form:</div>
                <Toggle on={formState.steroids['Form']} click={(): void => onSteroid('Form')} />
              </div>
            )}

            {formState.name === 'Aphelios' && (
              <div className={style.steroidBlock}>
                <div className={style.steroidLabel}>Main Weapon:</div>
                <select
                  value={formState.aphelios_weapon}
                  onChange={(event): void =>
                    onSetApheliosWeapon(event.target.value as ApheliosWeapon)
                  }
                >
                  <option value="Calibrum">Calibrum</option>
                  <option value="Severum">Severum</option>
                  <option value="Gravitum">Gravitum</option>
                  <option value="Infernum">Infernum</option>
                </select>
              </div>
            )}

            {formState.name === 'Sylas' && (
              <div className={style.steroidBlock}>
                <div className={style.steroidLabel}>Ultimate:</div>
                {isEditSylasUlt ? (
                  <>
                    <Autocomplete
                      allSuggestions={allChampions.filter(champion => champion.name != 'Sylas')}
                      field="name"
                      handleChange={(value: string): void =>
                        dispatch({ type: 'SET_SYLAS_ULT', payload: value })
                      }
                      value={formState.sylas_ult}
                    />
                    <button className={style.sylasButton} onClick={onSetSylasUlt}>
                      OK
                    </button>
                  </>
                ) : (
                  <>
                    <div>{formState.sylas_ult}</div>
                    <button
                      className={style.sylasButton}
                      onClick={(): void => setEditSylasUlt(true)}
                    >
                      Edit
                    </button>
                  </>
                )}
              </div>
            )}
          </div>

          <div className={style.statsContainer}>
            <div className={style.subTitle}>Champion stats</div>
            <div className={style.statBar}>
              <div className={style.statHp}>{formState.stats?.hp.toFixed(2)}</div>
              <div className={style.statMp}>{formState.stats?.mp.toFixed(2)}</div>
            </div>
            <div className={style.statsTable}>
              <div className={style.statColumn}>
                <div className={style.statBlock}>
                  <img src="https://i.gyazo.com/69f8b2ba2c1be7d385c48b358ae0793f.gif" />
                  <div className={style.statText}>{formState.stats?.ad.toFixed(2)}</div>
                </div>
                <div className={style.statBlock}>
                  <img src="https://i.gyazo.com/b2d37a81f9d0aca2e771ee8fc9af1b49.gif" />
                  <div className={style.statText}>{formState.stats?.armor.toFixed(2)}</div>
                </div>
                <div className={style.statBlock}>
                  <img src="https://i.gyazo.com/582178bf9bfbfaf76abdef8315eebf3b.gif" />
                  <div className={style.statText}>{formState.stats?.as.toFixed(2)}</div>
                </div>
                <div className={style.statBlock}>
                  <img src="https://i.gyazo.com/61fde9a6cc206bb4036fcf7f4c33fded.gif" />
                  <div className={style.statText}>{formState.stats?.crit_chance.toFixed(2)}</div>
                </div>
              </div>
              <div className={style.statColumn}>
                <div className={style.statBlock}>
                  <img src="https://i.gyazo.com/85ebe1603cdd03618d2f7c77cf7d723f.gif" />
                  <div className={style.statText}>{formState.stats?.ap.toFixed(2)}</div>
                </div>
                <div className={style.statBlock}>
                  <img src="https://i.gyazo.com/a2fce099e50f1043c0bfe71afc283177.gif" />
                  <div className={style.statText}>{formState.stats?.magic_res.toFixed(2)}</div>
                </div>
                <div className={style.statBlock}>
                  <img src="https://i.gyazo.com/3814d5fd268d8fa343676af6ba0575b1.gif" />
                  <div className={style.statText}>{formState.stats?.ah.toFixed(2)}</div>
                </div>
                <div className={style.statBlock}>
                  <img src="https://i.gyazo.com/c44c01cab583985fa7de2b84a02f91d4.gif" />
                  <div className={style.statText}>{formState.stats?.ms.toFixed(0)}</div>
                </div>
              </div>
              <div className={style.statColumn}>
                <div className={style.statBlock}>
                  <img src="https://i.gyazo.com/23a84e16b01d69c546b354397b7a8aef.gif" />
                  <div className={style.statText}>{formState.stats?.hp5.toFixed(2)}</div>
                </div>
                <div className={style.statBlock}>
                  <img src="https://i.gyazo.com/e636eeeae258465814cfd26e003506c7.gif" />
                  <div className={style.statText}>{formState.stats?.armorpen_flat.toFixed(2)} </div>
                </div>
                <div className={style.statBlock}>
                  <img src="https://i.gyazo.com/1d60c633da83edb7aa5ec9ae0507de94.gif" />
                  <div className={style.statText}>{formState.stats?.lifesteal.toFixed(2)}</div>
                </div>
              </div>
              <div className={style.statColumn}>
                <div className={style.statBlock}>
                  <img src="https://i.gyazo.com/e8d8fa93b8afec353e761a6a06a9ac18.gif" />
                  <div className={style.statText}>{formState.stats?.mp5.toFixed(2)}</div>
                </div>
                <div className={style.statBlock}>
                  <img src="https://i.gyazo.com/93f20dd8d537ad29be9acc4f9f161927.gif" />
                  <div className={style.statText}>{formState.stats?.magic_pen.toFixed(2)}</div>
                </div>
                <div className={style.statBlock}>
                  <img src="https://i.gyazo.com/ab517e3be67b261286cda7e88208983e.gif" />
                  <div className={style.statText}>{formState.stats?.spellvamp.toFixed(2)}</div>
                </div>
              </div>
            </div>

            <div className={style.subTitle}>Spell damages</div>
            <div className={style.damageBlock}>
              <div className={style.enemyStats}>
                <div className={style.subTitle}>Opponent</div>
                <select
                  value={formState.enemy.type}
                  onChange={(event): void => onSetEnemy(event.target.value)}
                >
                  <option value="none">none</option>
                  <option value="melee_minion">Melee Minion</option>
                  <option value="caster_minion">Caster Minion</option>
                  <option value="canon_minion">Canon Minion</option>
                </select>

                <div className={style.timerBlock}>
                  <div className={style.subTitle}>In-game timer</div>
                  <input
                    type="time"
                    id="timer"
                    name="ingame-time"
                    min="00:00"
                    max="60:00"
                    onChange={(event): void => onSetTimestamp(event.target.value)}
                    value={formState.enemy.time}
                  />
                </div>
              </div>
              {formState.enemy_stats && (
                <div className={style.enemyStats}>
                  {formState.enemy.type === 'melee_minion' && (
                    <img
                      src="https://static.wikia.nocookie.net/leagueoflegends/images/9/96/Order_Minion_Melee_Render.png"
                      width="120"
                    />
                  )}
                  {formState.enemy.type === 'caster_minion' && (
                    <img
                      src="https://static.wikia.nocookie.net/leagueoflegends/images/7/78/Order_Minion_Caster_Render.png"
                      width="120"
                    />
                  )}
                  {formState.enemy.type === 'canon_minion' && (
                    <img
                      src="https://static.wikia.nocookie.net/leagueoflegends/images/f/fa/Order_Minion_Siege_Render.png"
                      width="120"
                    />
                  )}
                  <div className={style.minionStats}>
                    <div className={style.minionHp}>{formState.enemy_stats.hp}</div>
                    <div className={style.statBlock}>
                      <img src="https://i.gyazo.com/b2d37a81f9d0aca2e771ee8fc9af1b49.gif" />
                      <div className={style.statText}>{formState.enemy_stats.armor.toFixed(2)}</div>
                    </div>
                    <div className={style.statBlock}>
                      <img src="https://i.gyazo.com/a2fce099e50f1043c0bfe71afc283177.gif" />
                      <div className={style.statText}>
                        {formState.enemy_stats.magic_res.toFixed(0)}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className={style.spellsTable}>
                <table>
                  <thead>
                    <tr>
                      <th>Spell</th>
                      <th>Damage</th>
                      <th>Opponent %</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Q</td>
                      <td>{formState.spells.q_dmg}</td>
                      <td>{pctEnemyHealth(formState.enemy_stats, formState.spells.q_dmg)}</td>
                    </tr>
                    <tr>
                      <td>W</td>
                      <td>{formState.spells.w_dmg}</td>
                      <td>{pctEnemyHealth(formState.enemy_stats, formState.spells.w_dmg)}</td>
                    </tr>
                    <tr>
                      <td>E</td>
                      <td>{formState.spells.e_dmg}</td>
                      <td>{pctEnemyHealth(formState.enemy_stats, formState.spells.e_dmg)}</td>
                    </tr>
                    <tr>
                      <td>R</td>
                      <td>{formState.spells.r_dmg}</td>
                      <td>{pctEnemyHealth(formState.enemy_stats, formState.spells.r_dmg)}</td>
                    </tr>
                    <tr>
                      <td>Passive</td>
                      <td>{formState.spells.passive_dmg}</td>
                      <td>{pctEnemyHealth(formState.enemy_stats, formState.spells.passive_dmg)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Theorycraft;
