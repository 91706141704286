import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { cx as classnames } from 'emotion';

import { sendGetRequest } from 'src/utils/requests';

import { Game } from '../types';
import * as style from './style.scss';
import HistoricalGameSummary from './Summary';
import { gameName } from '../helper';
import HistoricalGameTimeline from './Timeline';

const HistoricalGame: React.FC = () => {
  const { gameId } = useParams<{ gameId: string | undefined }>();
  const [game, setGame] = useState<Game | null>(null);
  // const [events, setEvents] = useState<Event[]>([]);
  const [visibleElement, setVisibleElement] = useState<string>('summary');

  const isVisible = (blockId: string): boolean => {
    const element = document.getElementById(blockId);
    const rect = element?.getBoundingClientRect();

    return !!rect && rect.top >= 150 && rect.top <= 180;
  };

  const currentSelectedHeader = (): string => {
    if (isVisible('summary')) return 'summary';
    if (isVisible('timeline')) return 'timeline';
    if (isVisible('builds')) return 'builds';
    if (isVisible('pathings')) return 'pathings';

    return 'none';
  };

  const handleScroll = (): void => {
    const currentEl = currentSelectedHeader();
    if (currentEl !== 'none') {
      setVisibleElement(currentEl);
    }
  };

  useEffect(() => {
    sendGetRequest(`/bayes/games/${gameId}`).then(data => setGame(data));
    // sendGetRequest(`/plays_library/main_events/${gameId}`).then(data => setEvents(data));
    window.addEventListener('scroll', handleScroll);
  }, [gameId]);

  const scrollToBlock = (blockId: string): void => {
    const yOffset = -152;
    const element = document.getElementById(blockId);
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <div>
      {game && (
        <div className={style.header}>
          <div className={style.gameName}>{gameName(game)}</div>
          <div
            className={classnames(
              style.headerNav,
              visibleElement === 'summary' ? style.selectedHeader : '',
            )}
            onClick={(): void => scrollToBlock('summary')}
          >
            Summary
          </div>
          <div
            className={classnames(
              style.headerNav,
              visibleElement === 'timeline' ? style.selectedHeader : '',
            )}
            onClick={(): void => scrollToBlock('timeline')}
          >
            Timeline data
          </div>
          {/*
          <div
            className={classnames(
              style.headerNav,
              visibleElement === 'builds' ? style.selectedHeader : '',
            )}
            onClick={(): void => scrollToBlock('builds')}
          >
            Build Orders
          </div>
          <div
            className={classnames(
              style.headerNav,
              visibleElement === 'pathings' ? style.selectedHeader : '',
            )}
            onClick={(): void => scrollToBlock('pathings')}
          >
            Pathings
          </div> */}
        </div>
      )}
      <div className={style.container}>
        {game ? (
          <div id="body" className={style.body}>
            <div id="summary" className={style.summaryBlock}>
              <HistoricalGameSummary game={game} />
            </div>
            <div id="timeline" className={style.timelineBlock}>
              <HistoricalGameTimeline game={game} />
            </div>
            {/*
            <div id="builds" className={style.buildsBlock}>
              Build Orders
            </div>
            <div id="pathings" className={style.pathingsBlock}>
              Pathings
            </div> */}
          </div>
        ) : (
          <div>Game is loading...</div>
        )}
      </div>
    </div>
  );
};

export default HistoricalGame;
