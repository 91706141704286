import React from 'react';
import { Pick } from 'src/pages/Historical/types';
import { championImageUrl } from 'src/utils/helper';

import * as style from './style.scss';

interface Props {
  picks: Pick[];
}
const HistoricalPicksTable: React.FC<Props> = ({ picks }) => {
  const getWinrateColor = (wins: number, losses: number): string => {
    if (wins > losses) {
      return style.winColor;
    } else if (wins < losses) {
      return style.lossColor;
    }

    return style.drawColor;
  };

  return (
    <div className={style.picks}>
      <table>
        <tr>
          <th>Picks</th>
          <th></th>
          <th>Record</th>
          <th>Bans</th>
          <th>Last patch</th>
        </tr>
        {picks.map(pick => (
          <tr key={pick.champion}>
            <td>
              <img src={championImageUrl(pick.slug)} className={style.championImage} />
            </td>
            <td>{pick.total}</td>
            <td className={getWinrateColor(pick.wins, pick.total - pick.wins)}>
              {pick.wins} - {pick.total - pick.wins}
            </td>
            <td>{pick.bans}</td>
            <td>{pick.last_patch}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default HistoricalPicksTable;
