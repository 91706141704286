import { Player } from "../Player/types";
import { ScrimGame } from "../Scrims/types";

interface Team {
  id: number,
  acronym: string,
  name: string,
  logo: string,
  players: Player[],
}

interface FormState {
  team_id: number,
  team: Team | null,
  players: Player[],
  startDate: Date | null,
  endDate: Date | null,
  scrimGames: ScrimGame[]
}

const initialState: FormState = {
  team_id: -1,
  team: null,
  players: [],
  startDate: new Date(Date.now() - 12096e5),
  endDate: null,
  scrimGames: []
}

export { Team, FormState, initialState };