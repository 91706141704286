import React from 'react';
import * as style from './style.scss';
import { ScrimGame } from 'src/pages/Scrims/types';

interface Props {
  game: ScrimGame;
  allyTeam: string;
  allyAcronym: string;
}

const Draft: React.FC<Props> = ({ game, allyTeam, allyAcronym }) => {
  const teamAcronym = (side: string): string =>
    game.side === side ? allyAcronym : game.team_acronym;

  const sideTeam = (side: string): string => (game.side === side ? allyTeam : game.team);
  const sideBans = (side: string) => game.bans.filter(ban => ban.team == sideTeam(side));

  const ban = (side: string, position: number) =>
    sideBans(side).find(ban => ban.order === position);
  const pick = (side: string, position: number) =>
    game.picks.filter(pick => pick.team === sideTeam(side)).sort((a, b) => a.order - b.order)[
      position - 1
    ];

  const result = (team: string) =>
    game.result ? (team === allyAcronym ? 'W' : 'L') : team === allyAcronym ? 'L' : 'W';

  const color = (position: number, side: string) => {
    if ([1, 4, 5].includes(position) && side === 'blue') {
      return style.blue;
    }
    if (side === 'blue') {
      return style.lightblue;
    }

    if ([3, 5].includes(position) && side === 'red') {
      return style.red;
    }

    return style.lightred;
  };

  const date = game.date ? game.date.toString().split('T')[0] : '';

  return (
    <table className={style.container}>
      <thead>
        <tr>
          <th>
            {game.patch} - {date}
          </th>
          <th>{game.is_official ? 'official' : 'scrim'}</th>
        </tr>
        <tr>
          <th>
            {teamAcronym('blue')} - {result(teamAcronym('blue'))}
          </th>
          <th>
            {teamAcronym('red')} - {result(teamAcronym('red'))}
          </th>
        </tr>
      </thead>

      <tbody>
        {[1, 2, 3].map(position => (
          <tr className={style.ban} key={`${game.id}-${position}-bans`}>
            <td>{ban('blue', position)?.champion}</td>
            <td>{ban('red', position)?.champion}</td>
          </tr>
        ))}
        {[1, 2, 3].map(position => (
          <tr key={`${game.id}-${position}-picks`}>
            <td className={color(position, 'blue')}>{pick('blue', position)?.champion}</td>
            <td className={color(position, 'red')}>{pick('red', position)?.champion}</td>
          </tr>
        ))}

        <tr className={style.ban} key={`${game.id}-4-red-bans`}>
          <td className={style.empty}></td>
          <td>{ban('red', 4)?.champion}</td>
        </tr>
        <tr className={style.ban} key={`${game.id}-4-blue-bans`}>
          <td>{ban('blue', 4)?.champion}</td>
          <td className={style.empty}></td>
        </tr>

        <tr className={style.ban} key={`${game.id}-5-red-bans`}>
          <td className={style.empty}></td>
          <td>{ban('red', 5)?.champion}</td>
        </tr>
        <tr className={style.ban} key={`${game.id}-5-blue-bans`}>
          <td>{ban('blue', 5)?.champion}</td>
          <td className={style.empty}></td>
        </tr>

        {[4, 5].map(position => (
          <tr key={`${game.id}-${position}-picks`}>
            <td className={color(position, 'blue')}>{pick('blue', position)?.champion}</td>
            <td className={color(position, 'red')}>{pick('red', position)?.champion}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Draft;
