import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();
const BASE_URL = process.env.BASE_URL;

function sendGetRequest(url: string, payload?: any) {
  const token = localStorage.getItem('token');
  return axios({
    url: BASE_URL + url,
    method: 'get',
    params: payload,
    headers: {
      authorization: `Bearer ${token}`,
    },
  }).then(response => {
    console.log(response);
    return response.data;
  });
}

function sendPatchRequest(url: string, payload: any) {
  const token = localStorage.getItem('token');
  return axios({
    url: BASE_URL + url,
    data: payload,
    method: 'patch',
    headers: {
      authorization: `Bearer ${token}`,
    },
  }).then(response => {
    console.log(response);
    return response.data;
  });
}

function sendPostRequest(url: string, payload: any) {
  const token = localStorage.getItem('token');
  return axios({
    url: BASE_URL + url,
    data: payload,
    method: 'post',
    headers: {
      authorization: `Bearer ${token}`,
    },
  }).then(response => {
    console.log(response);
    return response.data;
  });
}

function formatUrl(url: string): string {
  const token = localStorage.getItem('token');
  return BASE_URL + url + `?token=${token}`;
}

export { sendGetRequest, sendPatchRequest, sendPostRequest, formatUrl };
