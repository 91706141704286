import { assertNever } from "src/utils/types";
import { ScrimRole, FormState, ScrimDuo } from "./types";

interface SetTeams {
  type: 'SET_TEAMS',
  payload: any[],
}

interface SetChampions {
  type: 'SET_CHAMPIONS',
  payload: any[],
}

interface SetVs {
  type: 'SET_VS',
  payload: any[],
}

interface SetSide {
  type: 'SET_SIDE',
  payload: any[],
}

interface SetRole {
  type: 'SET_ROLE',
  payload: ScrimRole[],
}

interface SetVsRole {
  type: 'SET_VS_ROLE',
  payload: ScrimRole[]
}

interface SetStartDate {
  type: 'SET_START_DATE',
  payload: Date | null,
}

interface SetEndDate {
  type: 'SET_END_DATE',
  payload: Date | null,
}

interface SetFilteredTeams {
  type: 'SET_FILTERED_TEAMS',
  payload: any
}

interface SetFilteredChampions {
  type: 'SET_FILTERED_CHAMPIONS',
  payload: any
}

interface SetScrimType {
  type: 'SET_SCRIM_TYPE',
  payload: string
}

interface SetDuos {
  type: 'SET_DUOS',
  payload: ScrimDuo[]
}

interface SetVsDuos {
  type: 'SET_VS_DUOS',
  payload: ScrimDuo[]
}

type FormStateAction =
  | SetChampions
  | SetTeams
  | SetVs
  | SetSide
  | SetRole
  | SetVsRole
  | SetStartDate
  | SetEndDate
  | SetFilteredTeams
  | SetFilteredChampions
  | SetScrimType
  | SetDuos
  | SetVsDuos

const reducer = (state: FormState, action: FormStateAction): FormState => {
  switch (action.type) {
    case 'SET_TEAMS':
      return {...state, teams: action.payload}
    case 'SET_CHAMPIONS':
      return {...state, champions: action.payload}
    case 'SET_VS':
      return {...state, vs: action.payload}
    case 'SET_SIDE':
      return {...state, side: action.payload}
    case 'SET_ROLE':
      return {...state, role: action.payload}
    case 'SET_VS_ROLE':
      return {...state, vsrole: action.payload}
    case 'SET_START_DATE':
      return {...state, startDate: action.payload}
    case 'SET_END_DATE':
      return {...state, endDate: action.payload}
    case 'SET_FILTERED_TEAMS':
      return {...state, filteredTeams: action.payload}
    case 'SET_FILTERED_CHAMPIONS':
      return {...state, filteredChampions: action.payload}
    case 'SET_SCRIM_TYPE':
      return {...state, scrim_type: action.payload}
    case 'SET_DUOS':
      return {...state, duos: action.payload}
    case 'SET_VS_DUOS':
        return {...state, vs_duos: action.payload}
    default:
      assertNever(action)
  }
}

export { reducer }