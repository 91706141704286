import React from 'react';
import * as style from './style.scss';
import { useParams } from 'react-router-dom';

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import ChampionGames from '../Champions/Games';
import PatchDetail from './PatchDetail';

const Champion: React.FC = () => {
  const { championSlug } = useParams<{ championSlug: string }>();

  return (
    <div className={style.container}>
      <div className={style.title}>{championSlug}</div>

      <PatchDetail championSlug={championSlug} />

      <Tabs selectedTabClassName={style.selectedTab}>
        <TabList className={style.tabs}>

          <Tab key="soloq_games" className={style.tab}>
            Solo Queue Games
          </Tab>

          {/* <Tab key="champions_queue_games" className={style.tab}>
            Champions Queue Games
          </Tab> */}

          <Tab key="competitive_games" className={style.tab}>
            Competitive Games
          </Tab>
        </TabList>

        <TabPanel key="soloq_games">
          <ChampionGames type="soloq" championSlug={championSlug} />
        </TabPanel>

        {/* <TabPanel key="champions_queue_games">
          <ChampionGames type="champions_queue" championSlug={championSlug} />
        </TabPanel> */}
        <TabPanel key="competitive_games">
          <ChampionGames type="official" championSlug={championSlug} />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Champion;
