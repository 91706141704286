import React, { useEffect, useReducer, useState } from 'react';
import { sendGetRequest } from 'src/utils/requests';
import { Profile, initialState } from './types';
import { reducer } from './reducer';
import { cx as classnames } from 'emotion';
import { Multiselect } from 'multiselect-react-dropdown';
import * as style from './style.scss';
import AddProfile from 'src/components/Profiles/AddProfile';
import AddJob from 'src/components/Profiles/AddJob';
import { championImageUrl } from 'src/utils/helper';

const Profiles: React.FC = () => {
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [formState, dispatch] = useReducer(reducer, initialState);

  const [addJob, setAddJob] = useState<boolean>(false);
  const isAddJob = () => {
    setAddJob(!addJob);
  };

  const [addProfile, setAddProfile] = useState<boolean>(false);
  const isAddProfile = () => {
    setAddProfile(!addProfile);
  };

  const [editedProfile, setEditedProfile] = useState<number>(0);
  const isEdited = (profile_id: number) => {
    return profile_id === editedProfile;
  };

  useEffect(() => {
    const dmg =
      formState.filteredDmg.length === 0
        ? ''
        : 'dmg=' + formState.filteredDmg.map(e => e.name).join(',') + '&';
    const jobs =
      formState.filteredJobs.length === 0
        ? ''
        : 'jobs=' + formState.filteredJobs.map(e => e.name).join(',') + '&';
    const roles =
      formState.filteredRoles.length === 0
        ? ''
        : 'roles=' + formState.filteredRoles.map(e => e.name).join(',') + '&';
    const url = '/profiles?' + dmg + jobs + roles;

    sendGetRequest(url).then(data => setProfiles(data));
  }, [formState.filteredDmg.length, formState.filteredJobs.length, formState.filteredRoles.length]);

  useEffect(() => {
    const url = `/profiles/jobs`;
    sendGetRequest(url).then(data => {
      dispatch({ type: 'SET_JOBS', payload: data });
    });
  }, []);

  const dmgChipColor = (value: number) => {
    switch (value) {
      case 1:
        return style.dmg1;
      case 2:
        return style.dmg2;
      case 3:
        return style.dmg3;

      default:
        break;
    }
  };

  const jobChipColor = (value: number) => {
    switch (value) {
      case 1:
        return style.job1;
      case 2:
        return style.job2;
      case 3:
        return style.job3;
      case 4:
        return style.job4;
      case 5:
        return style.job5;

      default:
        break;
    }
  };

  const onAddProfile = (data: any[]) => {
    setProfiles(data);
    setEditedProfile(0);
    setAddProfile(false);
  };

  return (
    <div className={style.container}>
      <div className={style.title}>Champion profiles</div>

      <div className={style.filters}>
        <div className={style.filter}>
          <div className={style.filterTitle}>Roles</div>
          <div>
            <Multiselect
              options={formState.roles}
              onSelect={(list: any[]) => dispatch({ type: 'SET_FILTERED_ROLES', payload: list })}
              onRemove={(list: any[]) => dispatch({ type: 'SET_FILTERED_ROLES', payload: list })}
              displayValue="name"
              style={{
                optionContainer: { backgroundColor: '#1D2E3E' },
                inputField: { color: 'rgb(167, 167, 171)' },
              }}
            />
          </div>
        </div>

        <div className={style.filter}>
          <div className={style.filterTitle}>Damage profiles</div>
          <div>
            <Multiselect
              options={formState.dmg}
              onSelect={(list: any[]) => dispatch({ type: 'SET_FILTERED_DMG', payload: list })}
              onRemove={(list: any[]) => dispatch({ type: 'SET_FILTERED_DMG', payload: list })}
              displayValue="name"
              style={{
                optionContainer: { backgroundColor: '#1D2E3E' },
                inputField: { color: 'rgb(167, 167, 171)' },
              }}
            />
          </div>
        </div>

        <div className={style.filter}>
          <div className={style.filterTitle}>Jobs</div>
          <div>
            <Multiselect
              options={formState.jobs}
              onSelect={(list: any[]) => dispatch({ type: 'SET_FILTERED_JOBS', payload: list })}
              onRemove={(list: any[]) => dispatch({ type: 'SET_FILTERED_JOBS', payload: list })}
              displayValue="name"
              style={{
                optionContainer: { backgroundColor: '#1D2E3E' },
                inputField: { color: 'rgb(167, 167, 171)' },
              }}
            />
          </div>
        </div>
      </div>

      <div className={style.profiles}>
        <table className={style.profilesTable}>
          <thead>
            <tr>
              <th className={style.img}></th>
              <th className={style.champion}>Champion</th>
              <th className={style.roles}>Primary Roles</th>
              <th className={style.roles}>Secondary Roles</th>
              <th className={style.damages}>Damage profile</th>
              <th className={style.jobs}>Jobs</th>
              <th className={style.img}></th>
            </tr>
          </thead>
          <tbody>
            {profiles.length > 0 &&
              profiles.map(profile => {
                return isEdited(profile.id) ? (
                  <AddProfile
                    onPatch={onAddProfile}
                    onCancel={() => setEditedProfile(0)}
                    profile={profile}
                  />
                ) : (
                  <tr key={profile.id}>
                    <td className={style.img}>
                      <img
                        className={style.championImg}
                        src={championImageUrl(
                          profile.champion_slug ? profile.champion_slug : profile.champion,
                        )}
                      />
                    </td>
                    <td className={style.champion}>{profile.champion}</td>
                    <td className={style.roles}>{profile.primary_roles.join(', ')}</td>
                    <td className={style.roles}>{profile.secondary_roles.join(', ')}</td>
                    <td className={style.damages}>
                      <div className={style.damagesRow}>
                        {profile.damage_profiles.map(dmg => {
                          return (
                            <div
                              className={classnames(style.damage, dmgChipColor(dmg.level))}
                              key={dmg.label}
                            >
                              <p className={style.damageText}>{dmg.label}</p>
                            </div>
                          );
                        })}
                      </div>
                    </td>
                    <td className={style.jobs}>
                      {profile.jobs.map(job => {
                        return (
                          <div
                            className={classnames(style.job, jobChipColor(job.level))}
                            key={job.name}
                          >
                            <p className={style.jobText}>{job.name}</p>
                          </div>
                        );
                      })}
                    </td>
                    <td className={style.edit} onClick={() => setEditedProfile(profile.id)}>
                      Edit
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {addProfile ? (
          <>
            <div className={style.profileTitle}>Add a Profile</div>
            <AddProfile onPatch={onAddProfile} onCancel={isAddProfile} />
          </>
        ) : (
          <button className={style.isAddProfile} onClick={isAddProfile}>
            Add a profile
          </button>
        )}
        {addJob ? (
          <AddJob isAddJob={isAddJob} />
        ) : (
          <button className={style.isAddJob} onClick={isAddJob}>
            Add a job
          </button>
        )}
      </div>
    </div>
  );
};

export default Profiles;
