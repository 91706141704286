interface FilterObject {
  name: string;
  id: number;
}

interface FormState {
  leagues: FilterObject[];
  patches: FilterObject[];
  start_date: Date | null;
  end_date: Date | null;
  champions: FilterObject[];
  roles: FilterObject[];
  teams: FilterObject[];
  has_proview: boolean;
}

const initialState: FormState = {
  leagues: [],
  patches: [],
  start_date: null,
  end_date: null,
  champions: [],
  roles: [],
  teams: [],
  has_proview: false,
};

export { initialState, FilterObject, FormState };
