import React from 'react';

import { ScrimGame, ChampionPick } from 'src/pages/Scrims/types';

import { cx as classnames } from 'emotion';
import * as style from './style.scss';
import { championImageUrl, imgUrl, Role } from 'src/utils/helper';
import { formatUrl } from 'src/utils/requests';

interface Props {
  game: ScrimGame;
  displayColor: string;
  displaySide: string;
  champions: any[];
}

const ScrimCard: React.FC<Props> = ({ game, displayColor, displaySide, champions }) => {
  const border = () => {
    if (displayColor === 'result') return game.result ? style.victoryBorder : style.defeatBorder;

    return game.side === 'blue' ? style.blue : style.red;
  };

  const roles: Role[] = ['top', 'jun', 'mid', 'adc', 'sup'];
  const allyTeam = game.is_main ? 'BK ROG Esports' : '?';

  const ogBans = game.bans.filter(ban => ban.team === allyTeam);
  const opBans = game.bans.filter(ban => ban.team != allyTeam);
  const positions = [1, 2, 3, 4, 5];

  const date = (): string => {
    return game.date ? game.date.toString().split('T')[0] : 'zut';
  };

  const getPick = (team: string, role: string): ChampionPick | undefined => {
    return game.picks.find(pick => pick.team === team && pick.role === role);
  };

  const sideTeam = (side: string): string => (game.side === side ? allyTeam : game.team);

  const leftTeam = displaySide === 'side' ? sideTeam('blue') : allyTeam;
  const rightTeam = displaySide === 'side' ? sideTeam('red') : game.team;

  const getReplayUrl = (gameId: number): string => {
    return formatUrl(`/lolesports/scrim_replay_file_url/${gameId}`);
  };

  return (
    <div className={classnames(border(), style.container)}>
      <div className={style.mainInfo}>
        <div>{date()}</div>
        {game.is_blitz == false && (
          <div className={style.resultContainer}>
            <div className={classnames(game.result ? style.victory : style.defeat, style.result)} />
            <div>{game.result ? 'Victory' : 'Defeat'}</div>
          </div>
        )}
        <div>
          <img className={style.logo} src={game.logo}></img>
        </div>
        <div>Patch {game.patch}</div>
      </div>

      <div className={style.pbBlock}>
        <div className={style.team}>{allyTeam} bans</div>
        <div className={style.champinsHorizontal}>
          {positions.map(position => {
            const ban = ogBans.find(ban => ban.order === position);

            return (
              <div key={position}>
                {ban ? (
                  <img className={style.championImage} src={championImageUrl(ban.slug)} />
                ) : (
                  <img className={style.championImage} src={imgUrl('ban')} />
                )}
              </div>
            );
          })}
        </div>

        <div className={style.team}>{game.team} bans</div>
        <div className={style.champinsHorizontal}>
          {positions.map(position => {
            const ban = opBans.find(ban => ban.order === position);

            return (
              <div key={position}>
                {ban ? (
                  <img className={style.championImage} src={championImageUrl(ban.slug)} />
                ) : (
                  <img className={style.championImage} src={imgUrl('ban')} />
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div className={style.pbBlock}>
        <div className={style.team}>{allyTeam} picks</div>
        <div className={style.champinsHorizontal}>
          {game.picks
            .filter(pick => pick.team === allyTeam)
            .sort((a, b) => a.order - b.order)
            .map(pick => (
              <div key={pick.champion}>
                <img className={style.championImage} src={championImageUrl(pick.slug)} />
              </div>
            ))}
        </div>

        <div className={style.team}>{game.team} picks</div>
        <div className={style.champinsHorizontal}>
          {game.picks
            .filter(pick => pick.team === game.team)
            .sort((a, b) => a.order - b.order)
            .map(pick => (
              <div key={pick.champion}>
                <img className={style.championImage} src={championImageUrl(pick.slug)} />
              </div>
            ))}
        </div>
      </div>

      <div className={style.pbBlock}>
        <div className={style.pickTeam}>{leftTeam}</div>
        <div>
          {roles.map(role => (
            <div className={style.pick} key={role}>
              <img className={style.championImage} src={imgUrl(role)} />
              <img
                className={style.championImage}
                src={championImageUrl(getPick(leftTeam, role)?.slug)}
              />
              <div>{getPick(leftTeam, role)?.champion}</div>
            </div>
          ))}
        </div>
      </div>

      <div className={style.pbBlock}>
        <div className={style.pickTeam}>{rightTeam}</div>
        <div>
          {roles.map(role => (
            <div className={style.pick} key={role}>
              <div className={style.championName}>{getPick(rightTeam, role)?.champion}</div>
              <img
                className={style.championImage}
                src={championImageUrl(getPick(rightTeam, role)?.slug)}
              />
              <img className={style.championImage} src={imgUrl(role)} />
            </div>
          ))}
        </div>
      </div>
      <div>
        {game.id && (
          <>
            <div className={style.replayLink}>
              <a href={`/historical/games/${game.id}`} target="_blank" rel="noopener noreferrer">
                View scoreboard
              </a>
            </div>
            <div className={style.replayLink}>
              <a href={getReplayUrl(game.id)} target="_blank" rel="noopener noreferrer">
                Replay File
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ScrimCard;
