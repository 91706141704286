import { CURRENT_PATCH } from './helper';

type SpellKey = 'Q' | 'W' | 'E' | 'R' | 'Passive';
type ApheliosWeapon = 'Calibrum' | 'Severum' | 'Gravitum' | 'Infernum';

interface FormState {
  name: string;
  patch: string;
  level: number;

  items: string[];
  runes: string[];
  stats: Stats | undefined;
  champion: ChampionBaseData | undefined;
  spells_levels: {
    Q: number;
    W: number;
    E: number;
    R: number;
  };
  spells: {
    q_dmg: number;
    w_dmg: number;
    e_dmg: number;
    r_dmg: number;
    passive_dmg: number;
  };
  steroids: {
    Q: boolean;
    W: boolean;
    E: boolean;
    R: boolean;
    P: boolean;
    Form: boolean;
  };
  aphelios_weapon: ApheliosWeapon;
  sylas_ult: string;

  enemy: {
    type: string;
    time: string;
  };

  enemy_stats?: {
    armor: number;
    hp: number;
    magic_res: number;
  };
}

interface ChampionBaseData {
  passive: {
    description: string;
    name: string;
  };
  spells: SpellBaseData[];
}

interface SpellBaseData {
  name: string;
  maxrank: number;
  id: string;
  key: SpellKey;
  slug: string;
  level: number;
  damage_formula: string;
  cooldownBurn: string;
}

interface Stats {
  ad: number;
  ap: number;
  armor: number;
  armorpen_flat: number;
  as: number;
  ah: number;
  crit_chance: number;
  crit_dmg: number;
  hp: number;
  hp5: number;
  lifesteal: number;
  magic_pen: number;
  magic_res: number;
  mp: number;
  mp5: number;
  ms: number;
  spellvamp: number;
}

const initialState: FormState = {
  name: '',
  patch: CURRENT_PATCH,
  level: 1,

  items: ['', '', '', '', ''],
  runes: ['none', 'none', 'none'],
  stats: undefined,
  champion: undefined,
  spells_levels: { Q: 0, W: 0, E: 0, R: 0 },
  spells: {
    q_dmg: 0,
    w_dmg: 0,
    e_dmg: 0,
    r_dmg: 0,
    passive_dmg: 0,
  },
  steroids: {
    Q: false,
    W: false,
    E: false,
    R: false,
    P: false,
    Form: false,
  },
  aphelios_weapon: 'Calibrum',
  sylas_ult: 'Ashe',
  enemy: {
    type: 'melee_minion',
    time: '06:00',
  },
};

export {
  FormState,
  ChampionBaseData,
  SpellBaseData,
  SpellKey,
  Stats,
  ApheliosWeapon,
  initialState,
};
