import React from 'react';

const TestWidget: React.FC = () => {


  return <div>
    <iframe src="https://widget.dev.pandascore.co/v2/tournament?id=12207" />

  </div>
}

export default TestWidget