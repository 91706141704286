import React, { ReactNode } from 'react';
import { cx as classnames } from 'emotion';

import * as style from '../style.scss';

import { championImageUrl } from 'src/utils/helper';
import { assertNever } from 'src/utils/types';
import { Matchup } from '../../types';

import Icon from 'src/components/Icon';
import sort from 'src/icons/sort.svg';
import up from 'src/icons/up.svg';
import down from 'src/icons/down.svg';

type SortableField =
  | 'games'
  | 'winrate'
  | 'games_blue'
  | 'games_red'
  | 'winrate_blue'
  | 'winrate_red';


  interface Props {
    matchups: Matchup[]
  }

  const HistoricalChampionsMatchups: React.FC<Props> = ({matchups}) => {
    const [sortedField, setSortedField] = React.useState<SortableField>('games');
  const [sortOrder, setSortOrder] = React.useState<number>(1);

  const winrate = (nbGames: number, nbWins: number): string => {
    if (nbGames === 0) {
      return '-';
    }
    return ((nbWins * 100) / nbGames).toFixed(0) + '%';
  };

  const numWinrate = (nbGames: number, nbWins: number): number => {
    if (nbGames === 0) {
      return 0;
    }
    return ((nbWins * 100) / nbGames);
  }

  const handleSort = (field: SortableField): void => {
    if (field === sortedField) {
      setSortOrder(-sortOrder);
      return;
    }
    setSortedField(field);
    field === 'games' ? setSortOrder(1) : setSortOrder(-1);
  };

  const sortedHeader = (
    label: string,
    rowSortedField: SortableField,
    rowStyle: string,
  ): ReactNode => {
    return (
      <div
        className={classnames(style.sortButton, rowStyle)}
        onClick={(): void => handleSort(rowSortedField)}
      >
        {sortedField === rowSortedField ? (
          sortOrder === 1 ? (
            <Icon className={style.icon} href={down} />
          ) : (
            <Icon className={style.icon} href={up} />
          )
        ) : (
          <Icon className={style.icon} href={sort} />
        )}
        <div>{label}</div>
      </div>
    );
  };

  const sortMatchups = (m1: Matchup, m2: Matchup): number => {
    switch (sortedField) {
      case 'games':
        return sortOrder * (m2.games - m1.games);
      case 'games_blue':
        return sortOrder * (m2.games_blue - m1.games_blue);
      case 'games_red':
        return sortOrder * (m2.games_red - m1.games_red);
      case 'winrate':
        return numWinrate(m2.games, m2.wins) > numWinrate(m1.games, m1.wins)
          ? sortOrder * 1
          : sortOrder * -1;
      case 'winrate_blue':
        return numWinrate(m2.games_blue, m2.wins_blue) > numWinrate(m1.games_blue, m1.wins_blue)
          ? sortOrder * 1
          : sortOrder * -1;
      case 'winrate_red':
        return numWinrate(m2.games_red, m2.wins_red) > numWinrate(m1.games_red, m1.wins_red)
          ? sortOrder * 1
          : sortOrder * -1;
      default:
        assertNever(sortedField);
    }
  };

  return (
    <div className={style.table}>
      <div className={style.header}>
        <div className={style.headerLarge}>Opponent</div>
        <div className={style.headerText}>Role</div>
        {sortedHeader('Games', 'games', style.headerText)}
        {sortedHeader('Winrate', 'winrate', style.headerText)}
        {sortedHeader('Blue Games', 'games_blue', style.headerText)}
        {sortedHeader('Blue Winrate', 'winrate_blue', style.headerText)}
        {sortedHeader('Red Games', 'games_red', style.headerText)}
        {sortedHeader('Red Winrate', 'winrate_red', style.headerText)}
      </div>
      <div>
        {matchups.sort(sortMatchups).map(matchup => {
          return (
            <div key={`${matchup.champion}.${matchup.role}`} className={style.row}>
              <div className={style.rowLarge}>
                <img
                  className={style.championImage}
                  src={championImageUrl(matchup.slug)}
                ></img>
                <div>{matchup.champion}</div>
              </div>
              <div className={style.rowText}>{matchup.role}</div>
              <div className={style.rowText}>{matchup.games}</div>
              <div className={style.rowText}>{winrate(matchup.games, matchup.wins)}</div>
              <div className={classnames(style.rowText, style.blue)}>{matchup.games_blue}</div>
              <div className={classnames(style.rowText, style.blue)}>
                {winrate(matchup.games_blue, matchup.wins_blue)}
              </div>
              <div className={classnames(style.rowText, style.red)}>{matchup.games_red}</div>
              <div className={classnames(style.rowText, style.red)}>
                {winrate(matchup.games_red, matchup.wins_red)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
  }

  export default HistoricalChampionsMatchups;
