import { assertNever } from 'src/utils/types';
import { FormState } from './types';

interface SetProfile {
  type: 'SET_PROFILE';
  payload: string;
}

interface SetPrimaryRoles {
  type: 'SET_PRIMARY_ROLES';
  payload: any[];
}

interface SetSecondaryRoles {
  type: 'SET_SECONDARY_ROLES';
  payload: any[];
}

interface SetDmgProfiles {
  type: 'SET_DMG_PROFILES';
  payload: { value: string; type: 'AD' | 'AP' | 'MULTIPLE' | 'TRUE' };
}

interface SetJobs {
  type: 'SET_JOBS';
  payload: any[];
}

type FormStateAction = SetProfile | SetPrimaryRoles | SetSecondaryRoles | SetDmgProfiles | SetJobs;

const reducer = (state: FormState, action: FormStateAction): FormState => {
  switch (action.type) {
    case 'SET_PROFILE':
      return { ...state, profile: action.payload };
    case 'SET_PRIMARY_ROLES':
      return { ...state, primary_roles: action.payload };
    case 'SET_SECONDARY_ROLES':
      return { ...state, secondary_roles: action.payload };

    case 'SET_DMG_PROFILES':
      switch (action.payload.type) {
        case 'AD':
          return { ...state, ad_dmg: action.payload.value };
        case 'AP':
          return { ...state, ap_dmg: action.payload.value };
        case 'MULTIPLE':
          return { ...state, multiple_dmg: action.payload.value };
        case 'TRUE':
          return { ...state, true_dmg: action.payload.value };
        default:
          assertNever(action.payload.type);
      }

    case 'SET_JOBS':
      return { ...state, jobs: action.payload };

    default:
      assertNever(action);
  }
};

export { reducer };
