import React from 'react';
import { cx as classnames } from 'emotion';
import { championImageUrl, formatGameTime } from 'src/utils/helper';

import * as style from './style.scss';
import { BuildingKillEvent } from 'src/pages/Historical/types';

interface Props {
  event: BuildingKillEvent;
  isSelected: boolean;
}

const BuildingKill: React.FC<Props> = ({ event, isSelected }) => {
  const selectedStyle = () => {
    if (!isSelected) {
      return ''
    }

    return event.side === 'blue' ? style.selectedBlue : style.selectedRed
  }

  return (
    <div className={classnames(style.eventCard, selectedStyle())}>
      {event.champion ? (
        <>
          <div className={style.championPortrait}>
            <img
              src={championImageUrl(event.champion.slug)}
              alt={`${event.champion.name} Portrait`}
              className={style.championImage}
            />
          </div>
          <div className={style.eventName}>{event.player}</div>
          <div>
            destroyed{' '}
            {getBuildingDescription(event.building, event.building_lane, event.building_side)}
          </div>
        </>
      ) : (
        <>
          <div>
            {getBuildingDescription(event.building, event.building_lane, event.building_side)} was
            destroyed
          </div>
        </>
      )}
      <div className={style.gameTime}>{formatGameTime(event.game_time)}</div>
    </div>
  );
};

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const getBuildingDescription = (building: string, buildingLane: string, buildingSide: string) => {
  const buildingType = capitalizeFirstLetter(building);
  const buildingLaneDescription = capitalizeFirstLetter(buildingLane);
  const buildingSideDescription = capitalizeFirstLetter(buildingSide);
  return `${buildingSideDescription} ${buildingLaneDescription} ${buildingType}`;
};

export default BuildingKill;
