import React, { useEffect, useReducer, useState } from 'react';
import * as style from './style.scss';

import { sendGetRequest } from 'src/utils/requests';
import { imgUrl, sortByRole } from 'src/utils/helper';

import Icon from 'src/components/Icon';
import StatsTable from 'src/components/StatsTable';
// import RadioButton from 'src/components/RadioButton';
import VerticalBarChart from 'src/components/VerticalBarChart';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import arrow from 'src/icons/arrow.svg';
import calendar from 'src/icons/calendar.svg';
import { Multiselect } from 'multiselect-react-dropdown';

import { reducer } from './reducer';
import { initialState } from './types';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import GamesStats from './GamesStats';
import DuosTable from 'src/components/DuosTable';
import TeamProximity from 'src/pages/Team/Proximity';

interface Props {
  is_main?: boolean;
}

const ScrimStats: React.FC<Props> = ({ is_main = true }) => {
  const [formState, dispatch] = useReducer(reducer, initialState);
  const [role1, setRole1] = useState<string>('top');
  const [role2, setRole2] = useState<string>('jun');

  const [oppRole1, setOppRole1] = useState<string>('top');
  const [oppRole2, setOppRole2] = useState<string>('jun');

  const roleName: { [key: string]: string } = {
    top: 'Top lane',
    jun: 'Jungle',
    mid: 'Mid lane',
    adc: 'AD Carry',
    sup: 'Support',
  };

  // const scrimTypes = [
  //   { label: 'Normal', value: 'normal' },
  //   { label: 'Blitz', value: 'blitz' },
  //   { label: 'All', value: 'all' },
  // ];

  const championsSortedField = (): string => {
    if (formState.scrim_type === 'all') return 'total';
    if (formState.scrim_type === 'blitz') return 'blitz_games';
    return 'games';
  };

  useEffect(() => {
    const from = formState.startDate ? 'from=' + formState.startDate.toISOString() + '&' : '';
    const to = formState.endDate ? 'to=' + formState.endDate.toISOString() + '&' : '';
    const teams =
      formState.filteredTeams.length === 0
        ? ''
        : 'teams=' + formState.filteredTeams.map(e => e.name).join(',') + '&';

    const allyChampions =
      formState.filteredChampions.length === 0
        ? ''
        : 'champions=' + formState.filteredChampions.map(e => e.name).join(',') + '&';

    const scrimTypeParam = `scrim_type=${formState.scrim_type}&`;

    const isMainTeamParam = `is_main=${is_main}&`;

    const url = `/scrims/totals?${from}${to}${teams}${scrimTypeParam}${isMainTeamParam}${allyChampions}`;
    sendGetRequest(url).then(data => {
      dispatch({ type: 'SET_VS', payload: data.vs });
      dispatch({ type: 'SET_SIDE', payload: data.sides });
      dispatch({ type: 'SET_ROLE', payload: data.per_role });
      dispatch({ type: 'SET_VS_ROLE', payload: data.vs_role });
    });
  }, [
    formState.startDate,
    formState.endDate,
    formState.filteredChampions.length,
    formState.filteredTeams.length,
    formState.scrim_type,
  ]);

  useEffect(() => {
    const from = formState.startDate ? 'from=' + formState.startDate.toISOString() + '&' : '';
    const to = formState.endDate ? 'to=' + formState.endDate.toISOString() + '&' : '';
    const teams =
      formState.filteredTeams.length === 0
        ? ''
        : 'teams=' + formState.filteredTeams.map(e => e.name).join(',') + '&';

    const allyChampions =
      formState.filteredChampions.length === 0
        ? ''
        : 'champions=' + formState.filteredChampions.map(e => e.name).join(',') + '&';

    const scrimTypeParam = `scrim_type=${formState.scrim_type}&`;

    const isMainTeamParam = `is_main=${is_main}&`;
    const roles = `role1=${role1}&role2=${role2}&`;
    const oppRoles = `opp_role1=${oppRole1}&opp_role2=${oppRole2}&`;

    const duosUrl = `/scrims/duos?${from}${to}${teams}${scrimTypeParam}${isMainTeamParam}${roles}${oppRoles}${allyChampions}`;
    sendGetRequest(duosUrl).then(data => {
      dispatch({ type: 'SET_DUOS', payload: data.duos });
      dispatch({ type: 'SET_VS_DUOS', payload: data.vs_duos });
    });
  }, [
    formState.startDate,
    formState.endDate,
    formState.filteredChampions.length,
    formState.filteredTeams.length,
    formState.scrim_type,
    role1,
    role2,
    oppRole1,
    oppRole2,
  ]);

  useEffect(() => {
    const url = `/scrims/teams`;
    sendGetRequest(url).then(data => {
      dispatch({
        type: 'SET_TEAMS',
        payload: data.map((t: any) => ({
          id: t.id,
          name: t.name,
          acronym: t.acronym,
          full_name: `${t.name} - ${t.acronym}`,
        })),
      });
    });
  }, []);

  useEffect(() => {
    const url = '/champions';
    sendGetRequest(url).then(data => dispatch({ type: 'SET_CHAMPIONS', payload: data }));
  }, []);

  return (
    <div className={style.container}>
      <div className={style.title}>Scrim stats</div>

      <div className={style.calendar}>
        <Icon className={style.calendarIcon} href={calendar} />
        <DatePicker
          selected={formState.startDate}
          onChange={(date): void => dispatch({ type: 'SET_START_DATE', payload: date })}
          selectsStart
          startDate={formState.startDate}
          endDate={formState.endDate}
          className={style.datepicker}
          dateFormat="dd/MM/yyyy"
        />
        <Icon className={style.icon} href={arrow} />
        <DatePicker
          selected={formState.endDate}
          onChange={(date): void => dispatch({ type: 'SET_END_DATE', payload: date })}
          selectsEnd
          startDate={formState.startDate}
          endDate={formState.endDate}
          className={style.datepicker}
          dateFormat="dd/MM/yyyy"
        />
      </div>

      {/* <div>
        <RadioButton
          options={scrimTypes}
          current={formState.scrim_type}
          onChange={(e): void =>
            dispatch({ type: 'SET_SCRIM_TYPE', payload: e.currentTarget.value })
          }
        />
      </div> */}

      <div className={style.teamsFilter}>
        <Multiselect
          options={formState.teams}
          onSelect={(list: any[]): void => dispatch({ type: 'SET_FILTERED_TEAMS', payload: list })}
          onRemove={(list: any[]): void => dispatch({ type: 'SET_FILTERED_TEAMS', payload: list })}
          displayValue="full_name"
          style={{
            optionContainer: { backgroundColor: '#1D2E3E' },
            searchBox: { backgroundColor: 'rgb(167, 167, 171)' },
          }}
          placeholder="Search team"
        />
      </div>

      <div className={style.teamsFilter}>
        <Multiselect
          options={formState.champions}
          onSelect={(list: any[]): void =>
            dispatch({ type: 'SET_FILTERED_CHAMPIONS', payload: list })
          }
          onRemove={(list: any[]): void =>
            dispatch({ type: 'SET_FILTERED_CHAMPIONS', payload: list })
          }
          displayValue="name"
          style={{
            optionContainer: { backgroundColor: '#1D2E3E' },
            searchBox: { backgroundColor: 'rgb(167, 167, 171)' },
          }}
          placeholder="Search ally champion"
        />
      </div>

      <Tabs selectedTabClassName={style.selectedTab}>
        <TabList className={style.tabs}>
          <Tab key="winrates" className={style.tab}>
            Winrates
          </Tab>
          <Tab key="per_game" className={style.tab}>
            XP at 8 and 14
          </Tab>
          <Tab key="proximity" className={style.tab}>Proximity</Tab>
        </TabList>

        <TabPanel key="winrates">
          {formState.scrim_type != 'blitz' && (
            <>
              <div className={style.subtitle}>Team stats</div>
              <div className={style.mainStats}>
                <div className={style.teamStats}>
                  <VerticalBarChart data={formState.vs} />
                </div>

                <div className={style.teamStats}>
                  <VerticalBarChart data={formState.side} />
                </div>
              </div>
            </>
          )}

          <div className={style.subtitle}>Champions played</div>
          <div className={style.roles}>
            {formState.role.length > 0 &&
              sortByRole(formState.role).map(role => {
                return (
                  <div key={role.role}>
                    <div>
                      <img className={style.role} src={imgUrl(role.role)}></img>
                      {roleName[role.role]}
                    </div>
                    <div className={style.statsTable}>
                      <StatsTable
                        history={role}
                        hasKda={false}
                        hasWinrate={formState.scrim_type != 'blitz'}
                        hasBlitzGames={formState.scrim_type != 'normal'}
                        defaultSortedField={championsSortedField()}
                      />
                    </div>
                  </div>
                );
              })}
          </div>

          <div className={style.duosContainer}>
            <div>
              <div className={style.subtitle}>Champions duos played</div>
              <div className={style.duos}>
                <div className={style.duosSelect}>
                  <select
                    value={role1}
                    placeholder="Role"
                    onChange={(event): void => setRole1(event.target.value)}
                  >
                    <option value={'top'}>Top</option>
                    <option value={'jun'}>Jungle</option>
                    <option value={'mid'}>Mid</option>
                    <option value={'adc'}>Adc</option>
                    <option value={'sup'}>Support</option>
                  </select>

                  <select
                    value={role2}
                    placeholder="Role"
                    onChange={(event): void => setRole2(event.target.value)}
                  >
                    <option value={'top'}>Top</option>
                    <option value={'jun'}>Jungle</option>
                    <option value={'mid'}>Mid</option>
                    <option value={'adc'}>Adc</option>
                    <option value={'sup'}>Support</option>
                  </select>
                </div>

                <DuosTable duos={formState.duos} />
              </div>
            </div>

            <div>
              <div className={style.subtitle}>Vs champions duos</div>
              <div className={style.duos}>
                <div className={style.duosSelect}>
                  <select
                    value={oppRole1}
                    placeholder="Role"
                    onChange={(event): void => setOppRole1(event.target.value)}
                  >
                    <option value={'top'}>Top</option>
                    <option value={'jun'}>Jungle</option>
                    <option value={'mid'}>Mid</option>
                    <option value={'adc'}>Adc</option>
                    <option value={'sup'}>Support</option>
                  </select>

                  <select
                    value={oppRole2}
                    placeholder="Role"
                    onChange={(event): void => setOppRole2(event.target.value)}
                  >
                    <option value={'top'}>Top</option>
                    <option value={'jun'}>Jungle</option>
                    <option value={'mid'}>Mid</option>
                    <option value={'adc'}>Adc</option>
                    <option value={'sup'}>Support</option>
                  </select>
                </div>

                <DuosTable duos={formState.vs_duos} />
              </div>
            </div>
          </div>

          <div className={style.subtitle}>Win Rates vs Champions</div>
          <div className={style.roles}>
            {formState.vsrole.length > 0 &&
              sortByRole(formState.vsrole).map(role => {
                return (
                  <div key={role.role}>
                    <div>
                      <img className={style.role} src={imgUrl(role.role)}></img>
                      {roleName[role.role]}
                    </div>
                    <div className={style.statsTable}>
                      <StatsTable
                        history={role}
                        hasKda={false}
                        hasWinrate={formState.scrim_type != 'blitz'}
                        hasBlitzGames={formState.scrim_type != 'normal'}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </TabPanel>

        <TabPanel key="per_game">
          <div className={style.subtitle}>Player stats</div>
          <GamesStats
            filteredTeams={formState.filteredTeams}
            scrimType={formState.scrim_type}
            startDate={formState.startDate}
            endDate={formState.endDate}
            isMain={is_main}
          />

          {/* <div>Team stats</div>
          <div>que le tableau pour linstant</div> */}
        </TabPanel>

        <TabPanel key="proximity">
          <TeamProximity teamID={1} gameType='scrim' />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default ScrimStats;
