import React from 'react';
import * as style from './style.scss';
import { Team } from '../Team/types';
import { sendGetRequest } from 'src/utils/requests';
import TeamCard from 'src/components/TeamCard';

const Teams: React.FC = () => {
  const [teams, setTeams] = React.useState<Team[]>([]);

  React.useEffect(() => {
    const url = '/teams/soloq';
    sendGetRequest(url).then(data => setTeams(data));
  }, []);

  const lecTeams = [
    'GIANTX',
    'Fnatic',
    'G2 Esports',
    'Karmine Corp',
    'Rogue',
    'MAD Lions',
    'SK Gaming',
    'Team BDS',
    'Team Heretics',
    'Team Vitality',
  ];

  const lflTeams = [
    'Aegis',
    'BK ROG Esports',
    'GameWard',
    'Gentle Mates',
    'Karmine Corp Blue',
    'Solary',
    'Team BDS Academy',
    'Team GO',
    'Team Du Sud',
    'Vitality.Bee',
  ];

  const slTeams = [
    'Barça eSports',
    'Case Esports',
    'GIANTX PRIDE',
    'Guasones',
    'Movistar KOI',
    'Ramboot',
    'Rebels',
    'Los Heretics',
    'UCAM',
    'ZETA',
  ];

  const emeaTeams = [
    'BK ROG Esports',
    'Macko Esports',
    'Team GO',
    'Vitality.Bee',
    'Karmine Corp Blue',
    'Team BDS Academy',
    'Los Heretics',
    'Papara SuperMassive'
  ]

  const inOtherCat = (name: string) =>
    !lflTeams.includes(name) && !lecTeams.includes(name) && !slTeams.includes(name);

  return (
    <div className={style.container}>
      <div className={style.title}>Teams soloq data index</div>
      <div className={style.subtitle}>
        Only listing players with at least one soloq account registered.
      </div>

      <div className={style.title}>EMEA Playoffs Teams</div>

      <div>
        <div className={style.teams}>
          {teams
            .filter(team => emeaTeams.includes(team.name))
            .map(team => (
              <TeamCard team={team} key={team.name} />
            ))}
        </div>
      </div>

      <div className={style.title}>LFL Teams</div>

      <div>
        <div className={style.teams}>
          {teams
            .filter(team => lflTeams.includes(team.name))
            .map(team => (
              <TeamCard team={team} key={team.name} />
            ))}
        </div>
      </div>

      <div className={style.title}>LEC Teams</div>

      <div>
        <div className={style.teams}>
          {teams
            .filter(team => lecTeams.includes(team.name))
            .map(team => (
              <TeamCard team={team} key={team.name} />
            ))}
        </div>
      </div>

      <div className={style.title}>Superliga Teams</div>

      <div>
        <div className={style.teams}>
          {teams
            .filter(team => slTeams.includes(team.name))
            .map(team => (
              <TeamCard team={team} key={team.name} />
            ))}
        </div>
      </div>

      <div className={style.title}>Others</div>

      {teams && teams.length > 0 ? (
        <div>
          <div className={style.teams}>
            {teams
              .filter(team => inOtherCat(team.name))
              .filter(team => team.players.length > 0)
              .map(team => (
                <TeamCard team={team} key={team.name} />
              ))}
          </div>
        </div>
      ) : (
        <div>loading...</div>
      )}
    </div>
  );
};

export default Teams;
