import React, { useEffect, useState } from 'react';
import { initialState, Totals } from './types';
import { sendGetRequest } from 'src/utils/requests';

import * as style from './style.scss';
import { lolalyticsChampionName } from 'src/utils/helper';

interface Props {
  championSlug: string;
}

const PatchDetail: React.FC<Props> = ({ championSlug }) => {
  const [totals, setTotals] = useState<Totals>(initialState);

  useEffect(() => {
    const url = `/champions/${championSlug}/detail`;

    sendGetRequest(url).then(data => setTotals(data));
  }, [championSlug]);

  const cleanPatch = (patch: string): string => {
    return patch.substring(0, patch.length - 2);
  };

  return (
    <div className={style.container}>
      {totals.champion && totals.soloq ? (
        <div>
          <div className={style.title}>SoloQ - {cleanPatch(totals.soloq.patch)}</div>
          <div>Winrate - {totals.soloq.winrate}%</div>
          <div>Presence - {totals.soloq.presence}%</div>
          <div>
            <a className={style.link}
              href={`https://lolalytics.com/lol/${lolalyticsChampionName(totals.champion)}/build/?tier=diamond_plus&region=euw`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View more on lolalytics
            </a>
          </div>
        </div>
      ) : (
        <div>loading...</div>
      )}
    </div>
  );
};

export default PatchDetail;
