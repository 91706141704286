import React from 'react';
import * as style from './style.scss';

import Accounts from './Accounts';
import StatsTotal from './StatsTotal';
import { sendGetRequest } from 'src/utils/requests';

import { useParams, Link } from 'react-router-dom';
import { initialState, Player } from './types';
import { Account } from '../Account/types';
import { imgUrl } from 'src/utils/helper';

const Player: React.FC = () => {
  const { playerName } = useParams<{ playerName: string }>();
  const [player, setPlayer] = React.useState<Player>(initialState);
  const [accounts, setAccounts] = React.useState<Account[]>([]);

  React.useEffect(() => {
    const url = `/players/${playerName}`;
    sendGetRequest(url).then(data => {
      setPlayer(data);
      setAccounts(data.accounts);
    });
  }, [playerName]);

  const handleAccounts = (newAccounts: Account[]): void => {
    setAccounts(newAccounts);
  };

  return (
    <div className={style.container}>
      {player ? (
        <>
          <div className={style.title}>
            <div>{playerName}</div>
            <img className={style.role} src={imgUrl(player.role)}></img>
          </div>
          <Link to={`/teams/${player.team}`} className={style.teamLink}>
            <div className={style.team}>{player.team}</div>
          </Link>
          <StatsTotal player={player} />

          <Accounts player={player} accounts={accounts} handleNewAccount={handleAccounts} />
        </>
      ) : (
        <div className={style.title}>
          <div>{playerName}</div>
        </div>
      )}
    </div>
  );
};

export default Player;
