import React from 'react';
import * as style from './style.scss';
import Autocomplete from '../Autocomplete';
import { useHistory } from "react-router-dom";
interface Props {

}

const SearchBar: React.FC<Props> = ({ }) => {
  const [search, setSearch] = React.useState<string>('');
  const [type, setType] = React.useState<string>('');
  const history = useHistory();

  const onChange = (value: string, section?: any) => {
    setSearch(value);
    if(section) {
      setType(section.title)
    }
  }

  const onSearch = () => {
    history.push(`/${type}/${search}`)
  }

  return <div className={style.container}>
    <Autocomplete
      allSuggestions={[]}
      field='name'
      handleChange={onChange}
      value={search}
      placeholder='Search for a player, a team...'
      multiSection={true}
      handleEnter={onSearch}
    />
  </div>
}

export default SearchBar;