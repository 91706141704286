import { assertNever } from 'src/utils/types';

interface FormState {
  player: string;
  team: string | null;
  role: string;
}

const initialState: FormState = {
  player: '',
  team: null,
  role: '',
};

interface SetPlayer {
  type: 'SET_PLAYER';
  payload: string;
}

interface SetTeam {
  type: 'SET_TEAM';
  payload: string;
}

interface SetRole {
  type: 'SET_ROLE';
  payload: string;
}

type FormStateAction = SetPlayer | SetTeam | SetRole;

const reducer = (state: FormState, action: FormStateAction): FormState => {
  switch (action.type) {
    case 'SET_PLAYER':
      return {
        ...state,
        player: action.payload,
      };
    case 'SET_TEAM':
      return {
        ...state,
        team: action.payload === '' ? null : action.payload,
      };
    case 'SET_ROLE':
      return {
        ...state,
        role: action.payload,
      };
    default:
      assertNever(action);
  }
};

export { initialState, reducer, FormState };
