// Teach Autosuggest how to calculate suggestions for any given input value.
// Only get first 3 elements for display concerns
const getSuggestions = (allSuggestions: any[], field: string, value: string) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : allSuggestions.map(suggestion => suggestion[field])
    .filter(suggestion => suggestion.toLowerCase().slice(0, inputLength) === inputValue)
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion: string) => suggestion;

export { getSuggestions, getSuggestionValue }