import React, { ReactNode } from 'react';
import { cx as classnames } from 'emotion';

import * as style from '../style.scss';

import { championImageUrl } from 'src/utils/helper';
import { assertNever } from 'src/utils/types';
import { Duo } from '../../types';

import Icon from 'src/components/Icon';
import sort from 'src/icons/sort.svg';
import up from 'src/icons/up.svg';
import down from 'src/icons/down.svg';

type SortableField =
  | 'games'
  | 'winrate'
  | 'games_blue'
  | 'games_red'
  | 'winrate_blue'
  | 'winrate_red';

interface Props {
  duos: Duo[];
}

const HistoricalChampionsDuos: React.FC<Props> = ({ duos }) => {
  const [sortedField, setSortedField] = React.useState<SortableField>('games');
  const [sortOrder, setSortOrder] = React.useState<number>(1);

  const winrate = (nbGames: number, nbWins: number): string => {
    if (nbGames === 0) {
      return '-';
    }
    return ((nbWins * 100) / nbGames).toFixed(0) + '%';
  };

  const numWinrate = (nbGames: number, nbWins: number): number => {
    if (nbGames === 0) {
      return 0;
    }
    return ((nbWins * 100) / nbGames);
  }

  const handleSort = (field: SortableField): void => {
    if (field === sortedField) {
      setSortOrder(-sortOrder);
      return;
    }
    setSortedField(field);
    field === 'games' ? setSortOrder(1) : setSortOrder(-1);
  };

  const sortedHeader = (
    label: string,
    rowSortedField: SortableField,
    rowStyle: string,
  ): ReactNode => {
    return (
      <div
        className={classnames(style.sortButton, rowStyle)}
        onClick={(): void => handleSort(rowSortedField)}
      >
        {sortedField === rowSortedField ? (
          sortOrder === 1 ? (
            <Icon className={style.icon} href={down} />
          ) : (
            <Icon className={style.icon} href={up} />
          )
        ) : (
          <Icon className={style.icon} href={sort} />
        )}
        <div>{label}</div>
      </div>
    );
  };

  const sortDuos = (d1: Duo, d2: Duo): number => {
    switch (sortedField) {
      case 'games':
        return sortOrder * (d2.games - d1.games);
      case 'games_blue':
        return sortOrder * (d2.games_blue - d1.games_blue);
      case 'games_red':
        return sortOrder * (d2.games_red - d1.games_red);
      case 'winrate':
        return numWinrate(d2.games, d2.wins) > numWinrate(d1.games, d1.wins)
          ? sortOrder * 1
          : sortOrder * -1;
      case 'winrate_blue':
        return numWinrate(d2.games_blue, d2.wins_blue) > numWinrate(d1.games_blue, d1.wins_blue)
          ? sortOrder * 1
          : sortOrder * -1;
      case 'winrate_red':
        return numWinrate(d2.games_red, d2.wins_red) > numWinrate(d1.games_red, d1.wins_red)
          ? sortOrder * 1
          : sortOrder * -1;
      default:
        assertNever(sortedField);
    }
  };

  return (
    <div className={style.table}>
      <div className={style.header}>
        <div className={style.headerLarge}>Champion 1</div>
        <div className={style.headerLarge}>Champion 2</div>
        {sortedHeader('Games', 'games', style.headerText)}
        {sortedHeader('Winrate', 'winrate', style.headerText)}
        {sortedHeader('Blue Games', 'games_blue', style.headerText)}
        {sortedHeader('Blue Winrate', 'winrate_blue', style.headerText)}
        {sortedHeader('Red Games', 'games_red', style.headerText)}
        {sortedHeader('Red Winrate', 'winrate_red', style.headerText)}
      </div>
      <div>
        {duos.sort(sortDuos).map(duo => {
          return (
            <div key={`${duo.champion1}.${duo.champion2}`} className={style.row}>
              <div className={style.rowLarge}>
                <img
                  className={style.championImage}
                  src={championImageUrl(duo.champion1_slug)}
                ></img>
                <div>{duo.champion1}</div>
              </div>
              <div className={style.rowLarge}>
                <img
                  className={style.championImage}
                  src={championImageUrl(duo.champion2_slug)}
                ></img>
                <div>{duo.champion2}</div>
              </div>
              <div className={style.rowText}>{duo.games}</div>
              <div className={style.rowText}>{winrate(duo.games, duo.wins)}</div>
              <div className={classnames(style.rowText, style.blue)}>{duo.games_blue}</div>
              <div className={classnames(style.rowText, style.blue)}>
                {winrate(duo.games_blue, duo.wins_blue)}
              </div>
              <div className={classnames(style.rowText, style.red)}>{duo.games_red}</div>
              <div className={classnames(style.rowText, style.red)}>
                {winrate(duo.games_red, duo.wins_red)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HistoricalChampionsDuos;
