import React, { useEffect, useState } from 'react';
import { sendGetRequest } from 'src/utils/requests';
import { ProximityGame } from './types';
import { championImageUrl } from 'src/utils/helper';

import { cx as classnames } from 'emotion';
import * as style from './style.scss';
import RadioButton from 'src/components/RadioButton';

interface Props {
  teamID: number;
  gameType: string;
}

const TeamProximity: React.FC<Props> = ({ teamID, gameType }) => {
  const [role, setRole] = useState<string>('jun');
  const [displayedRole, setDisplayedRole] = useState<string>('jun');
  const from = '2023-05-20';

  const [loading, setLoading] = useState<boolean>(false);
  const [apply, setApply] = useState<number>(0);

  const [tsStart, setTsStart] = useState<string>('01:45');
  const [tsEnd, setTsEnd] = useState<string>('08:00');

  const [games, setGames] = useState<ProximityGame[]>([]);

  const roles = ['top', 'jun', 'mid', 'adc', 'sup'];
  const rolesOptions = [
    { label: 'Top', value: 'top' },
    { label: 'Jungle', value: 'jun' },
    { label: 'Mid', value: 'mid' },
    { label: 'ADC', value: 'adc' },
    { label: 'Support', value: 'sup' },
  ];

  const handleRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRole(event.currentTarget.value);
  };

  const date = (game: ProximityGame): string => {
    return game.date ? game.date.toString().split('T')[0] : 'zut';
  };

  const getRoleChampion = (game: ProximityGame, r: string) => {
    const rolePlayer = game.players.find(p => p.role === r);

    return rolePlayer;
  };

  // http://localhost:4000/plays_library/role_proximity/1?ts_start=0&ts_end=30&base_role=jun&game_type=official&from=2023-05-20&token=password
  useEffect(() => {
    setLoading(true);
    const ts_start = parseInt(tsStart.split(':')[0]) * 60 + parseInt(tsStart.split(':')[1]);
    const ts_end = parseInt(tsEnd.split(':')[0]) * 60 + parseInt(tsEnd.split(':')[1]);

    setDisplayedRole(role);

    const url =
      `/plays_library/role_proximity/${teamID}?` +
      `ts_start=${ts_start}&` +
      `ts_end=${ts_end}&` +
      `base_role=${role}&` +
      `game_type=${gameType}&` +
      `from=${from}&`;

    sendGetRequest(url).then(data => {
      setLoading(false);
      setGames(data);
    });
  }, [teamID, apply]);

  const rowColor = (game: ProximityGame) => {
    // if (displayColor === 'result') return game.result ? style.victory : style.defeat;
    return game.side === 'blue' ? style.blue : style.red;
  };

  const row = (game: ProximityGame) => {
    return (
      <tr className={classnames(rowColor(game))}>
        <td>{date(game)}</td>
        <td>{game.patch}</td>
        <td>{game.opp_team.acronym}</td>
        <td>
          <img className={style.baseChampionImage} src={championImageUrl(game.base_player.slug)} />
        </td>
        {roles
          .filter(r => r !== displayedRole)
          .map(r => (
            <td>
              <div className={style.roleCell}>
                <img
                  className={style.championImage}
                  src={championImageUrl(getRoleChampion(game, r)?.slug)}
                />
                <p className={style.proximityValue}>
                  {getRoleChampion(game, r)?.proximity.toFixed(0)}%
                </p>
              </div>
            </td>
          ))}
      </tr>
    );
  };

  return (
    <div>
      <div className={style.filters}>
        <div className={style.filterLabel}>Role</div>
        <RadioButton name="role" options={rolesOptions} current={role} onChange={handleRole} />
      </div>
      <div className={style.filters}>
        <div className={style.filterLabel}>From ingame-time</div>
        <input
          className={style.filterTimeInput}
          type="time"
          name="from-time"
          value={tsStart}
          onChange={e => setTsStart(e.currentTarget.value)}
        />
        <div className={style.filterLabel}>To ingame-time</div>
        <input
          className={style.filterTimeInput}
          type="time"
          name="to-time"
          value={tsEnd}
          onChange={e => setTsEnd(e.currentTarget.value)}
        />

        <button className={style.applyButton} onClick={() => setApply(apply + 1)}>
          Apply filters
        </button>
      </div>

      {loading ? (
        'loading...'
      ) : (
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>P</th>
              <th>VS</th>
              <th>Champion</th>
              {roles
                .filter(r => r !== displayedRole)
                .map(r => (
                  <th>{r}</th>
                ))}
            </tr>
          </thead>
          <tbody>{games.map(g => row(g))}</tbody>
        </table>
      )}
    </div>
  );
};

export default TeamProximity;
