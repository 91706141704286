import { Job, Profile } from "src/pages/Champions/Profiles/types";

interface FormJob extends Job {
  added?: boolean,
  removed?: boolean,
}

interface FormState {
  id?: number,
  profile: string,

  primary_roles: any[],
  secondary_roles: any[],

  ad_dmg: string,
  ap_dmg: string,
  true_dmg: string,
  multiple_dmg: string,

  jobs: FormJob[],
  old_jobs: FormJob[],

}

interface RequestState {
  id: number | null,
  champion_name: string,

  primary_roles: string,
  secondary_roles: string,

  ad_dmg: number | null,
  ap_dmg: number | null,
  true_dmg: number | null,
  multiple_dmg: number | null,

  jobs: FormJob[],
}


const initialState: FormState = {
  profile: '',

  primary_roles: [],
  secondary_roles: [],

  ad_dmg: '0',
  ap_dmg: '0',
  true_dmg: '0',
  multiple_dmg: '0',

  jobs: [],
  old_jobs: [],
}

const setInitialState = (profile: Profile | undefined) : FormState  => {
  if (!profile) {
    return initialState;
  }

  const adDmg = profile.damage_profiles.find((dmg) => {return dmg.label === 'AD'})?.level.toString();
  const apDmg = profile.damage_profiles.find((dmg) => {return dmg.label === 'AP'})?.level.toString();
  const trueDmg = profile.damage_profiles.find((dmg) => {return dmg.label === 'True'})?.level.toString();
  const multDmg = profile.damage_profiles.find((dmg) => {return dmg.label === 'Multiple'})?.level.toString();

  const primRoles = profile.primary_roles.map(roles => {
    return {
      name: roles
    }
  })

  const secRoles = profile.secondary_roles.map(roles => {
    return {
      name: roles
    }
  })

  return {
    id: profile.id,
    profile: profile.champion,

    primary_roles: primRoles,
    secondary_roles: secRoles,

    ad_dmg: adDmg ? adDmg : "0",
    ap_dmg: apDmg ? apDmg : "0",
    true_dmg: trueDmg ? trueDmg : "0",
    multiple_dmg: multDmg ? multDmg : "0",

    jobs: profile.jobs,
    old_jobs: profile.jobs,
  }
}

export { FormState, FormJob, RequestState, setInitialState}