import React, { useState } from 'react';
import minimapImage from '../../img/Summoners_Rift_Minimap.png';
import { championImageUrl } from 'src/utils/helper';
import { GameEvent, RecallEvent, TeleportationEvent } from 'src/pages/Historical/types';

interface MapPlayer {
  position_x: number;
  position_y: number;
  champion_slug: string;
}

interface Props {
  blue_players: MapPlayer[];
  red_players: MapPlayer[];
  events: GameEvent[];
}

const Minimap: React.FC<Props> = ({ blue_players, red_players, events }) => {
  const [minimapSize] = useState({ width: 500, height: 500 });

  const resize_x = (x: number): number => ((x + 120) * 500) / (14870 + 120);
  const resize_y = (y: number): number => ((y + 120) * 500) / (14980 + 120);

  const renderPlayerPositions = (players: MapPlayer[], side: 'blue' | 'red') => {
    return players.map((player, index) => (
      <div
        key={index}
        style={{
          position: 'absolute',
          left: `${resize_x(player.position_x)}px`,
          bottom: `${resize_y(player.position_y)}px`,
          width: '30px',
          height: '30px',
          backgroundImage: `url(${championImageUrl(player.champion_slug)})`,
          backgroundSize: 'cover',
          borderRadius: '50%',
          border: `2px solid ${side}`,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    ));
  };

  const renderTeleportation = (event: TeleportationEvent) => {
    return (
      <div
        style={{
          position: 'absolute',
          left: `${resize_x(event.position_x)}px`,
          bottom: `${resize_y(event.position_y)}px`,
          width: '30px',
          height: '30px',
          borderRadius: '50%',
          border: `5px solid purple`,
          borderStyle: 'dashed',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    );
  };

  const renderRecall = (event: RecallEvent) => {
    return (
      <div
        style={{
          position: 'absolute',
          left: `${resize_x(event.position_x)}px`,
          bottom: `${resize_y(event.position_y)}px`,
          width: '30px',
          height: '30px',
          borderRadius: '50%',
          border: `5px solid turquoise`,
          borderStyle: 'dashed',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    );
  };

  return (
    <div
      style={{
        position: 'relative',
        width: `${minimapSize.width}px`,
        height: `${minimapSize.height}px`,
        backgroundImage: `url(${minimapImage})`,
        backgroundSize: 'cover',
        border: '1px solid black',
      }}
    >
      {renderPlayerPositions(blue_players, 'blue')}
      {renderPlayerPositions(red_players, 'red')}
      {events.map(event => {
        if (event.type === 'teleportation') {
          return renderTeleportation(event as TeleportationEvent);
        }
        if (event.type === 'recall') {
          return renderRecall(event as RecallEvent);
        }
        return null;
      })}
    </div>
  );
};

export default Minimap;
