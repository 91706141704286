import React, { useState } from 'react';
import ChampionKill from './ChampionKill';
import { GameEvent } from 'src/pages/Historical/types';
import Teleportation from './Teleportation';
import BuildingKill from './BuildingKill';
import Recall from './Recall';

interface Props {
  events: GameEvent[];
  onSelect: (event: GameEvent) => void;
  gameTime: number | undefined;
}

const EventsList: React.FC<Props> = ({ events, onSelect, gameTime }) => {
  const [filters, setFilters] = useState({
    team: 'all',
    // player: 'all',
    eventType: 'all',
  });

  const filteredEvents = events
    .filter(event => {
      const teamFilter = filters.team === 'all' || event.side === filters.team;
      // const playerFilter = filters.player === 'all' || event.player === filters.player;
      const eventTypeFilter = filters.eventType === 'all' || event.type === filters.eventType;

      return teamFilter && eventTypeFilter; //  && playerFilter
    })
    .sort((a, b) => a.game_time - b.game_time);

  const handleFilterChange = (filterType: any, value: any) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const handleEventClick = (event: any) => {
    onSelect(event);
  };

  const isSelected = (event: GameEvent): boolean => {
    if (gameTime === undefined) {
      return false;
    }

    return gameTime === event.game_time;
  };

//to put back sometimes after
//   <label>
//   Player:
//   <select
//     value={filters.player}
//     onChange={e => handleFilterChange('player', e.target.value)}
//   >
//     <option value="all">All</option>
//     {/* Add options for players based on your data */}
//   </select>
// </label>

  return (
    <div style={{ height: '800px', overflowY: 'auto' }}>
      <div>
        <label>
          Team:
          <select value={filters.team} onChange={e => handleFilterChange('team', e.target.value)}>
            <option value="all">All</option>
            <option value="blue">Blue Team</option>
            <option value="red">Red Team</option>
          </select>
        </label>

        <label>
          Event Type:
          <select
            value={filters.eventType}
            onChange={e => handleFilterChange('eventType', e.target.value)}
          >
            <option value="all">All</option>
            <option value="championKill">Champion Kill</option>
            <option value="teleportation">Teleportation</option>
            {/* <option value="dragonKill">Dragon Kill</option> */}
            <option value="towerKill">Tower Kill</option>
            <option value="recall">Recall</option>
            {/* Add more event types based on your data */}
          </select>
        </label>
      </div>
      <ul>
        {filteredEvents.map((event, index) => (
          <li key={index} onClick={() => handleEventClick(event)}>
            {/* Render event details with champion portraits, tower icons, dragon icons, and game time */}
            {/* You can use additional components or HTML elements based on your design */}
            {event.type === 'championKill' && <ChampionKill event={event} isSelected={isSelected(event)} />}
            {event.type === 'teleportation' && <Teleportation event={event} isSelected={isSelected(event)} />}
            {/* {event.type === 'dragonKill' && <span>Dragon Kill</span>}*/}
            {event.type === 'towerKill' && <BuildingKill event={event} isSelected={isSelected(event)} />}
            {event.type === 'recall' && <Recall event={event} isSelected={isSelected(event)} />}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EventsList;
