import React, { ReactNode } from 'react';
import { cx as classnames } from 'emotion';

import Icon from 'src/components/Icon';
import baron from 'src/icons/baron.svg';
import drake from 'src/icons/drake.svg';
import gold from 'src/icons/gold.svg';
// import herald from 'src/icons/herald.svg';
import inhib from 'src/icons/inhib.svg';
import kills from 'src/icons/kills.svg';
import tower from 'src/icons/tower.svg';
import {
  championImageUrl,
  imgUrl,
  itemImageUrl,
  summonerSpellUrl,
} from 'src/utils/helper';

import { formatCs, formatGold, formatLength, getPlayerByRole } from '../../helper';
import { Game, GamePlayer, GameTeam } from '../../types';

import * as style from './style.scss';

interface Props {
  game: Game;
}

const HistoricalGameSummary: React.FC<Props> = ({ game }) => {
  const playerRow = (game_player: GamePlayer): ReactNode => {
    return (
      <div className={style.playerRow}>
        <div className={style.playerCol}>
          <img className={style.championImage} src={championImageUrl(game_player.champion_slug)} />
          <div>
            <div className={style.spells}>
              <img
                className={style.spell}
                src={summonerSpellUrl(game_player.summoner_1_name, game.version)}
              />
              <img
                className={style.spell}
                src={summonerSpellUrl(game_player.summoner_2_name, game.version)}
              />
            </div>
          </div>
          <div>{game_player.player.name}</div>
        </div>
        <div className={style.roleCol}>
          <img className={style.roleImage} src={imgUrl(game_player.role)}></img>
        </div>
        <div className={style.kdaCol}>
          {game_player.champion_kills}/{game_player.deaths}/{game_player.assists}
        </div>
        <div className={style.csCol}>{formatCs(game_player)}</div>
        <div className={style.visionCol}>{Math.round(game_player.vision_score)}</div>
        <div className={style.goldCol}>{formatGold(game_player.total_gold)}</div>
        <div className={style.itemsBlock}>
          {game_player.items
            .filter(item => item.id !== 0)
            .map(item => (
              <img
                className={style.itemImage}
                key={item.id}
                src={itemImageUrl(game.version, item.id)}
              />
            ))}
        </div>
      </div>
    );
  };

  const blue_team = game.game_teams.find(game_team => game_team.side === 'blue') as GameTeam;
  const red_team = game.game_teams.find(game_team => game_team.side === 'red') as GameTeam;

  const winner_name = game.winner?.name;

  const blue_players = game.game_players.filter(
    game_player => game_player.side === 'blue',
  ) as GamePlayer[];
  const red_players = game.game_players.filter(
    game_player => game_player.side === 'red',
  ) as GamePlayer[];

  return (
    <div>
      <div>
        <div className={style.headerLine}>
          <div className={classnames(style.teamName, style.blueTeamName)}>
            <div>
              <a href={`/historical/teams/${blue_team.team.id}`} className={style.teamLink}>
                {blue_team.team.name} {blue_team.team.name === winner_name && ' - WIN'}
              </a>
            </div>

            <img className={style.blueTeamLogo} src={blue_team.team.logo}></img>
          </div>

          <div className={classnames(style.teamMainStats, style.blueTeamMainStats)}>
            <div className={style.teamStat}>
              <div>{blue_team.inhibitor_kills}</div>
              <Icon className={style.blueIcon} href={inhib} />
            </div>
            <div className={style.teamStat}>
              <div>{blue_team.tower_kills}</div>
              <Icon className={style.blueIcon} href={tower} />
            </div>
            <div className={style.teamStat}>
              <div>{formatGold(blue_team.total_gold)}</div>
              <Icon className={style.blueIcon} href={gold} />
            </div>
          </div>

          <div className={classnames(style.killsBlock)}>
            <div>{blue_team.champion_kills}</div>
            <Icon className={style.blackIcon} href={kills} />
            <div>{red_team.champion_kills}</div>
          </div>

          <div className={classnames(style.teamMainStats, style.redTeamMainStats)}>
            <div className={style.teamStat}>
              <div>{formatGold(red_team.total_gold)}</div>
              <Icon className={style.redIcon} href={gold} />
            </div>
            <div className={style.teamStat}>
              <div>{red_team.tower_kills}</div>
              <Icon className={style.redIcon} href={tower} />
            </div>
            <div className={style.teamStat}>
              <div>{red_team.inhibitor_kills}</div>
              <Icon className={style.redIcon} href={inhib} />
            </div>
          </div>

          <div className={classnames(style.teamName, style.redTeamName)}>
            <img className={style.redTeamLogo} src={red_team.team.logo}></img>
            <div>
              <a href={`/historical/teams/${red_team.team.id}`} className={style.teamLink}>
                {red_team.team.name} {red_team.team.name === winner_name && ' - WIN'}
              </a>
            </div>
          </div>
        </div>
        <div className={classnames(style.headerLine, style.secondLine)}>
          <div className={style.teamStat}>
            <div>{blue_team.baron_kills}</div>
            <Icon className={style.blueIcon} href={baron} />
          </div>
          <div className={style.teamStat}>
            <div>{blue_team.dragon_kills}</div>
            <Icon className={style.blueIcon} href={drake} />
          </div>

          <div className={style.lengthBlock}>{formatLength(game.length)}</div>

          <div className={style.teamStat}>
            <div>{red_team.dragon_kills}</div>
            <Icon className={style.redIcon} href={drake} />
          </div>
          <div className={style.teamStat}>
            <div>{red_team.baron_kills}</div>
            <Icon className={style.redIcon} href={baron} />
          </div>
        </div>
      </div>

      <div className={style.statsRows}>
        <div>
          <div className={style.statsHeader}>
            <div className={style.playerCol}>Player</div>
            <div className={style.roleCol}>Role</div>
            <div className={style.kdaCol}>KDA</div>
            <div className={style.csCol}>CS</div>
            <div className={style.visionCol}>Vision</div>
            <div className={style.goldCol}>Gold</div>
            <div className={style.itemsCol}>Items</div>
          </div>
          <div>{playerRow(getPlayerByRole(blue_players, 'top'))}</div>
          <div>{playerRow(getPlayerByRole(blue_players, 'jun'))}</div>
          <div>{playerRow(getPlayerByRole(blue_players, 'mid'))}</div>
          <div>{playerRow(getPlayerByRole(blue_players, 'adc'))}</div>
          <div>{playerRow(getPlayerByRole(blue_players, 'sup'))}</div>
        </div>
        <div className={style.rowSpacing}></div>
        <div>
          <div className={style.statsHeader}>
            <div className={style.playerCol}>Player</div>
            <div className={style.roleCol}>Role</div>
            <div className={style.kdaCol}>KDA</div>
            <div className={style.csCol}>CS</div>
            <div className={style.visionCol}>Vision</div>
            <div className={style.goldCol}>Gold</div>
            <div className={style.itemsCol}>Items</div>
          </div>
          <div>{playerRow(getPlayerByRole(red_players, 'top'))}</div>
          <div>{playerRow(getPlayerByRole(red_players, 'jun'))}</div>
          <div>{playerRow(getPlayerByRole(red_players, 'mid'))}</div>
          <div>{playerRow(getPlayerByRole(red_players, 'adc'))}</div>
          <div>{playerRow(getPlayerByRole(red_players, 'sup'))}</div>
        </div>
      </div>
    </div>
  );
};

export default HistoricalGameSummary;
