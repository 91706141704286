import { sendPostRequest } from "src/utils/requests"
import { Account } from 'src/pages/Account/types'
import { FormState } from './reducer'

const isSubmittable = (formState: FormState): boolean => {

  if (!formState.player || !formState.role) {
    return false
  }
  return true
}

const addPlayer = (account: Account, formState: FormState) => {
  const url = `/accounts/by-id/${account.id}`
  return sendPostRequest(url, formState);
}

export { isSubmittable, addPlayer }