import React from 'react'
import { cx as classnames } from 'emotion';

import * as style from './style.scss'

interface Props {
  on: boolean,
  onBlue?: boolean,
  click: () => void,
}

const Toggle: React.FC<Props> = ({ on, onBlue, click }) => {

  const onClass = classnames(style.on, onBlue ? style.onBlue : style.onGreen)

  return <button className={style.container} onClick={click}>
    <div className={on ? onClass : style.off}></div>
  </button>
}

export default Toggle;