import React, { useState, useReducer, useEffect } from 'react';
import { FormState } from 'src/components/Historical/Sidebar/types';
import { sendGetRequest } from 'src/utils/requests';
import { BaseGame, Team } from '../types';

import { reducer } from 'src/components/Historical/Pagination/reducer';
import { initialState } from 'src/components/Historical/Pagination/types';

import InfiniteScroll from 'react-infinite-scroll-component';
import { cx as classnames } from 'emotion';

import * as style from './style.scss';
import HistoricalSidebar from 'src/components/Historical/Sidebar';
import { championImageUrl } from 'src/utils/helper';
import { getTournamentTag } from '../helper';

const HistoricalTeams: React.FC = () => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [urlParams, setUrlParams] = useState<string>('');
  const [paginationState, dispatch] = useReducer(reducer, initialState);

  const getTeams = (currentTeams: Team[], page: number, url_params: string): void => {
    const url = `/lolesports/teams?page=${page}&${url_params}`;
    dispatch({ type: 'SET_LOADING', payload: true });

    sendGetRequest(url).then(data => {
      dispatch({ type: 'SET_LOADING', payload: false });
      setTeams(currentTeams.concat(data));

      if (data.length === 0) {
        dispatch({ type: 'SET_HAS_MORE', payload: false });
      }
    });
  };

  useEffect(() => {
    getTeams([], paginationState.page, '');
  }, []);

  const loadMoreTeams = (): void => {
    const newPage = paginationState.page + 1;
    dispatch({ type: 'SET_PAGE', payload: newPage });
    getTeams(teams, newPage, urlParams);
  };

  const applyFilters = (filters: FormState): void => {
    dispatch({ type: 'SET_PAGE', payload: 0 });
    dispatch({ type: 'SET_HAS_MORE', payload: true });

    const leagues = filters.leagues.map(league => league.id);
    const roles = filters.roles.map(role => role.id);
    const teams = filters.teams.map(team => team.id);

    const newUrlParams = `teams=${teams}&leagues=${leagues}&roles=${roles}`;
    setUrlParams(newUrlParams);
    getTeams([], 0, newUrlParams);
  };

  const recentWinrate = (recentGames: BaseGame[]): string => {
    const wonGames = recentGames.filter(game => game.result).length;

    return ((100 * wonGames) / recentGames.length).toFixed(1);
  };

  return (
    <div className={style.container}>
      <HistoricalSidebar onApply={applyFilters} pageType="teams" />
      <div className={style.table}>
        <div className={style.header}>
          <div className={style.headerLarge}>Team</div>
          <div className={style.headerText}>League</div>
          <div className={style.headerText}>Score</div>
          <div className={style.headerLarge}>Most picked</div>
          <div className={style.headerLarge}>Last games</div>
          <div className={style.headerLink}>Team Stats</div>
        </div>
        <div>
          <InfiniteScroll
            dataLength={teams.length}
            next={loadMoreTeams}
            hasMore={paginationState.hasMore}
            loader={<h4>Loading...</h4>}
          >
            {teams.map(team => {
              return (
                <div key={team.id} className={style.row}>
                  <div className={style.rowLarge}>
                    <img className={style.icon} src={team.logo} />
                    <div className={style.teamName}>{team.name}</div>
                  </div>
                  <div className={style.rowText}>{getTournamentTag(team.league)}</div>
                  <div className={style.rowText}>
                    {team.wins} - {team.losses}
                  </div>
                  <div className={style.rowLarge}>
                    {team.most_picked.map(champion => (
                      <div key={champion.slug} className={style.mostPlayed}>
                        <img className={style.icon} src={championImageUrl(champion.slug)}></img>
                        <div>({champion.count})</div>
                      </div>
                    ))}
                  </div>
                  <div className={style.recentFormRow}>
                    <div className={style.recentGames}>
                      {team.recent_form
                        .sort((gameA, gameB) => {
                          if (gameA.date > gameB.date) {
                            return 1;
                          }
                          if (gameA.date < gameB.date) {
                            return -1;
                          }
                          return 0;
                        })
                        .map(game => (
                          <div key={game.id}>
                            <div
                              className={classnames(
                                style.recentGame,
                                game.result ? style.gameWin : style.gameLoss,
                              )}
                            >
                              <img src={game.opponent_logo} className={style.teamFormIcon} />
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className={style.timeline}>
                      <span className={style.winrate}>{recentWinrate(team.recent_form)}%</span>
                    </div>
                  </div>
                  <div className={style.rowLink}>
                    <a href={`/historical/teams/${team.id}`}>link</a>
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default HistoricalTeams;
