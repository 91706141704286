import { sendPostRequest } from "src/utils/requests"
import { Player } from 'src/pages/Player/types'
import { FormState } from './reducer'

const isSubmittable = (formState: FormState) => {

  if (!formState.account) {
    return false
  }
  return true
}

const addAccount = (player: Player, formState: FormState) => {
  const url = `/players/${player.name}`
  return sendPostRequest(url, formState);
}

export { isSubmittable, addAccount }