import React from 'react';
import Header from 'src/components/Header';
import Sidebar from 'src/components/Sidebar';
import 'src/styleUtils/fonts.css';
import * as style from './style.scss';

import Account from 'src/pages/Account';
import Champion from 'src/pages/Champion';
import Champions from 'src/pages/Champions';
import Leaderboard from 'src/pages/Leaderboard';
import Login from 'src/pages/Login';
import Patch from 'src/pages/Patch';
import Player from 'src/pages/Player';
import Team from 'src/pages/Team';
import Teams from 'src/pages/Teams';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ContextProvider, { LogContext } from 'src/context/provider';
import Scrims from 'src/pages/Scrims';
import ScrimDrafts from 'src/pages/Scrims/ScrimDrafts';
import ScrimStats from 'src/pages/Scrims/ScrimStats';
import Profiles from 'src/pages/Champions/Profiles';
import Theorycraft from 'src/pages/Theorycraft';
import Bayes from 'src/pages/Bayes';
import OfficialStats from 'src/pages/OfficialStats';

import HistoricalGames from 'src/pages/Historical/Games';
import HistoricalGame from 'src/pages/Historical/Game';
import HistoricalPlayers from 'src/pages/Historical/Players';
import HistoricalTeams from 'src/pages/Historical/Teams';
import HistoricalTeam from 'src/pages/Historical/Team';
import HistoricalChampions from 'src/pages/Historical/Champions';

// import ChampionsQueueGames from 'src/pages/ChampionsQueueGames';
import TestWidget from 'src/pages/TestWidget';

const Application: React.FC = () => {
  return (
    <Router>
      <Header />
      <Sidebar />
      <div className={style.container}>
        <ContextProvider>
          <LogContext.Consumer>
            {logState => (
              <>
                {logState ? (
                  <Switch>
                    <Route path="/test-widget">
                      <TestWidget />
                    </Route>
                    <Route path="/leaderboards">
                      <Leaderboard />
                    </Route>
                    <Route path="/teams/:teamName">
                      <Team />
                    </Route>
                    <Route path="/teams">
                      <Teams />
                    </Route>
                    <Route path={`/players/:playerName`}>
                      <Player />
                    </Route>
                    <Route path={`/accounts/:accountName`}>
                      <Account />
                    </Route>
                    <Route path="/patch">
                      <Patch />
                    </Route>
                    <Route path={`/champions/:championSlug`}>
                      <Champion />
                    </Route>
                    <Route path="/champions">
                      <Champions />
                    </Route>
                    <Route path="/scrims">
                      <ScrimStats />
                    </Route>
                    <Route path="/scrim_games">
                      <Scrims />
                    </Route>
                    <Route path="/scrim_drafts">
                      <ScrimDrafts />
                    </Route>
                    <Route path="/scrim_blitz_games">
                      <Scrims is_blitz={true} />
                    </Route>
                    {/* <Route path="/academy_scrims">
                      <ScrimStats is_main={false} />
                    </Route>
                    <Route path="/academy_scrim_games">
                      <Scrims is_main={false} />
                    </Route>
                    <Route path="/academy_scrim_blitz_games">
                      <Scrims is_blitz={true} is_main={false} />
                    </Route> */}
                    <Route path="/official_stats">
                      <OfficialStats />
                    </Route>
                    <Route path="/profiles">
                      <Profiles />
                    </Route>
                    <Route path="/theorycraft">
                      <Theorycraft />
                    </Route>
                    <Route path="/bayes">
                      <Bayes />
                    </Route>
                    <Route path="/historical/champions">
                      <HistoricalChampions />
                    </Route>
                    <Route path="/historical/games/:gameId">
                      <HistoricalGame />
                    </Route>
                    <Route path="/historical/games">
                      <HistoricalGames />
                    </Route>
                    <Route path="/historical/players">
                      <HistoricalPlayers />
                    </Route>
                    <Route path="/historical/teams/:teamId">
                      <HistoricalTeam />
                    </Route>
                    <Route path="/historical/teams">
                      <HistoricalTeams />
                    </Route>
                    {/* <Route path="/historical/champions_queue_games">
                      <ChampionsQueueGames />
                    </Route> */}
                    <Route path="/">
                      <Teams />
                    </Route>
                  </Switch>
                ) : (
                  <Switch>
                    <Route path="/">
                      <Login />
                    </Route>
                  </Switch>
                )}
              </>
            )}
          </LogContext.Consumer>
        </ContextProvider>
      </div>
    </Router>
  );
};

export default Application;
