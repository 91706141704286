import { assertNever } from 'src/utils/types';
import { Player } from '../Player/types';
import { ScrimGame } from '../Scrims/types';
import { FormState, Team } from './types';

interface SetTeam {
  type: 'SET_TEAM';
  payload: Team;
}

interface SetPlayers {
  type: 'SET_PLAYERS';
  payload: Player[];
}

interface SetStartDate {
  type: 'SET_START_DATE';
  payload: Date | null;
}

interface SetEndDate {
  type: 'SET_END_DATE';
  payload: Date | null;
}

interface SetScrimGames {
  type: 'SET_SCRIM_GAMES';
  payload: ScrimGame[];
}

type FormStateAction = SetTeam | SetPlayers | SetStartDate | SetEndDate | SetScrimGames;

const reducer = (state: FormState, action: FormStateAction): FormState => {
  switch (action.type) {
    case 'SET_TEAM':
      return { ...state, team: action.payload, team_id: action.payload.id };
    case 'SET_PLAYERS':
      return { ...state, players: action.payload };
    case 'SET_START_DATE':
      return { ...state, startDate: action.payload };
    case 'SET_END_DATE':
      return { ...state, endDate: action.payload };
    case 'SET_SCRIM_GAMES':
      return { ...state, scrimGames: action.payload };
    default:
      assertNever(action);
  }
};

export { reducer };
