import React from 'react';
import { Account } from 'src/pages/Account/types';

import * as style from './style.scss';

interface Props {
  account: Account;
}

const AccountMedal: React.FC<Props> = ({ account }) => {
  const medal = account.tier
    ? `https://opgg-static.akamaized.net/images/medals_new/${account.tier}.png`
    : 'https://opgg-static.akamaized.net/images/medals/default.png';

  const prettyTier = (tier: string): string => {
    return tier[0].toUpperCase() + tier.slice(1);
  };

  const winrate = (): number => {
    if (account.wins + account.losses == 0) {
      return 0;
    }
    return Math.round((100 * account.wins) / (account.wins + account.losses));
  };

  return (
    <div className={style.container}>
      <div className={style.main}>
        <div className={style.title}>
          {account.riot_name && account.riot_tag
            ? `${account.riot_name}#${account.riot_tag}`
            : account.summoner_name}
        </div>
        {account.tier ? (
          <div className={style.wins}>
            {account.wins}/{account.losses} ({winrate()}%)
          </div>
        ) : (
          ''
        )}

        <div>
          <a
            className={style.link}
            href={`https://www.op.gg/summoners/euw/${
              account.riot_name && account.riot_tag
                ? `${account.riot_name}-${account.riot_tag}`
                : account.summoner_name
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            View on opgg
          </a>
        </div>
      </div>
      <div>
        <img className={style.medal} src={medal} />
        {account.tier ? (
          <div>
            <div className={style.tier}>
              {prettyTier(account.tier)} {account.rank}
            </div>
            <div> {account.league_points} LP </div>
          </div>
        ) : (
          'Unranked'
        )}
      </div>
    </div>
  );
};

export default AccountMedal;
