import React, { useEffect, useState } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import { useParams } from 'react-router';
import { cx as classnames } from 'emotion';

import { sendGetRequest } from 'src/utils/requests';
import { FullTeam } from '../types';

import * as style from './style.scss';
import HistoricalTeamSummary from './Summary';
import HistoricalTeamsPicksAndBans from './PicksAnsBans';
// import HistoricalTeamObjectives from './Objectives';

const HistoricalTeam: React.FC = () => {
  const { teamId } = useParams<{ teamId: string | undefined }>();
  const [team, setTeam] = useState<FullTeam | null>(null);
  const [visibleElement, setVisibleElement] = useState<string>('summary');

  const [allLeagues, setAllLeagues] = useState<FilterObject[]>([]);
  const [leagues, setLeagues] = useState<number[]>([]);

  const isVisible = (blockId: string): boolean => {
    const element = document.getElementById(blockId);
    const rect = element?.getBoundingClientRect();

    return !!rect && rect.top >= 150 && rect.top <= 180;
  };

  const currentSelectedHeader = (): string => {
    if (isVisible('summary')) return 'summary';
    if (isVisible('picks_and_bans')) return 'picks_and_bans';
    if (isVisible('objectives')) return 'objectives';
    if (isVisible('second_buffs')) return 'second_buffs';
    if (isVisible('pathings')) return 'pathings';

    return 'none';
  };

  const handleScroll = (): void => {
    const currentEl = currentSelectedHeader();
    if (currentEl !== 'none') {
      setVisibleElement(currentEl);
    }
  };

  interface FilterObject {
    name: string;
    id: number;
  }

  // useEffect(() => {
  //   sendGetRequest(`/tournaments/tags?team_id=${teamId}`).then(data => {
  //     const tags = data.map((tag: string) => ({ id: tag, name: tag }));
  //     setAllLeagues(tags);
  //   });
  // }, [teamId]);

  useEffect(() => {
    sendGetRequest(`/lolesports/teams/${teamId}?leagues=${leagues}`).then(data => setTeam(data));
    window.addEventListener('scroll', handleScroll);
  }, [teamId, leagues.length]);

  const scrollToBlock = (blockId: string): void => {
    const yOffset = -152;
    const element = document.getElementById(blockId);
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <div>
      {team && (
        <div className={style.header}>
          <img src={team.logo} className={style.teamImage} />
          <div className={style.teamName}>
            <div>{team?.name}</div>
            <a href={`/teams/${team.full_name}`}>go to scrims/soloq data</a>
          </div>
          <div className={style.filtersBlock}>
            <div className={style.filtersTitle}>Tournaments</div>
            <Multiselect
              options={allLeagues}
              onSelect={(list: FilterObject[]): void => setLeagues(list.map(league => league.id))}
              onRemove={(list: FilterObject[]): void => setLeagues(list.map(league => league.id))}
              displayValue="name"
            />
          </div>
          <div
            className={classnames(
              style.headerNav,
              visibleElement === 'summary' ? style.selectedHeader : '',
            )}
            onClick={(): void => scrollToBlock('summary')}
          >
            Summary
          </div>
          <div
            className={classnames(
              style.headerNav,
              visibleElement === 'picks_and_bans' ? style.selectedHeader : '',
            )}
            onClick={(): void => scrollToBlock('picks_and_bans')}
          >
            Picks & Bans
          </div>
          {/* <div
            className={classnames(
              style.headerNav,
              visibleElement === 'objectives' ? style.selectedHeader : '',
            )}
            onClick={(): void => scrollToBlock('objectives')}
          >
            Objectives Control
          </div> */}
          {/* <div
            className={classnames(
              style.headerNav,
              visibleElement === 'second_buffs' ? style.selectedHeader : '',
            )}
            onClick={(): void => scrollToBlock('second_buffs')}
          >
            2nd Buffs
          </div>
          <div
            className={classnames(
              style.headerNav,
              visibleElement === 'pathings' ? style.selectedHeader : '',
            )}
            onClick={(): void => scrollToBlock('pathings')}
          >
            Pathings
          </div> */}
        </div>
      )}
      <div className={style.container}>
        {team ? (
          <div id="body" className={style.body}>
            <div id="summary" className={style.summaryBlock}>
              <HistoricalTeamSummary team={team} />
            </div>
            <div id="picks_and_bans" className={style.picksAndBansBlock}>
              <HistoricalTeamsPicksAndBans teamId={team.id} leagues={leagues} />
            </div>
            {/* <div id="objectives" className={style.objectivesBlock}>
              <HistoricalTeamObjectives team={team} />
            </div> */}
            {/* <div id="second_buffs" className={style.secondBuffsBlock}>
              secondBuffs
            </div>
            <div id="pathings" className={style.pathingsBlock}>
              Pathings
            </div> */}
          </div>
        ) : (
          <div>Team is loading...</div>
        )}
      </div>
    </div>
  );
};

export default HistoricalTeam;
