import React from 'react';


import * as style from './style.scss';

const Bayes: React.FC<{}> = (): JSX.Element => {

  React.useEffect(() => {

  }, []);

  return (
    <div className={style.container}>
      <div className={style.title}>Bayes data fetcher</div>
    </div>
  );
};

export default Bayes;
