import React, { useEffect, useReducer } from 'react'
import { sendGetRequest } from 'src/utils/requests';
import { Link } from 'react-router-dom';

import * as style from '../style.scss';
import { reducer } from '../reducer';
import { initialState } from '../types';

import StatsTable from 'src/components/StatsTable';
import { Player } from 'src/pages/Player/types';
import { imgUrl, sortByRole } from 'src/utils/helper';

import Icon from 'src/components/Icon';
import arrow from 'src/icons/arrow.svg';
import calendar from 'src/icons/calendar.svg';

import DatePicker from 'react-datepicker';

interface Props {
  teamName: string;
}

const TeamSoloqCounts: React.FC<Props> = ({teamName}) => {
  const [formState, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const from = formState.startDate ? 'from=' + formState.startDate.toISOString() + '&' : '';
    const to = formState.endDate ? 'to=' + formState.endDate.toISOString() + '&' : '';

    const url = `/teams/${teamName}?${from}${to}`;

    sendGetRequest(url).then(data => {
      dispatch({ type: 'SET_PLAYERS', payload: data.players });
      dispatch({ type: 'SET_TEAM', payload: data });
    });

  }, [teamName])

  const handleStartDate = (date: Date | null): void => {
    if (date && formState.endDate && date > formState.endDate) {
      dispatch({ type: 'SET_START_DATE', payload: formState.endDate });
      dispatch({ type: 'SET_END_DATE', payload: date });

      return;
    }
    dispatch({ type: 'SET_START_DATE', payload: date });
  };

  const handleEndDate = (date: Date | null): void => {
    if (date && formState.startDate && date < formState.startDate) {
      dispatch({ type: 'SET_END_DATE', payload: formState.startDate });
      dispatch({ type: 'SET_START_DATE', payload: date });
      return;
    }
    dispatch({ type: 'SET_END_DATE', payload: date });
  };

  return <div>
        <div className={style.calendar}>
          <Icon className={style.calendarIcon} href={calendar} />
          <DatePicker
            selected={formState.startDate}
            onChange={(date): void => handleStartDate(date)}
            selectsStart
            startDate={formState.startDate}
            endDate={formState.endDate}
            className={style.datepicker}
            dateFormat="dd/MM/yyyy"
          />
          <Icon className={style.icon} href={arrow} />
          <DatePicker
            selected={formState.endDate}
            onChange={(date): void => handleEndDate(date)}
            selectsEnd
            startDate={formState.startDate}
            endDate={formState.endDate}
            className={style.datepicker}
            dateFormat="dd/MM/yyyy"
          />
        </div>
        {formState.players.length > 0 ? (
          <div className={style.players}>
            {sortByRole(formState.players).map((player: Player) => (
              <div key={player.name}>
                <Link key={player.name} to={`/players/${player.name}`}>
                  <div className={style.playerHeader}>
                    <img className={style.role} src={imgUrl(player.role)}></img>
                    <div className={style.player}>{player.name}</div>
                  </div>
                </Link>
                {player.totals && (
                  <div className={style.playerTable}>
                    <StatsTable history={player.totals} hasKda={true} />
                    <div className={style.playerTotal}>Total: {player.totals.games}</div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div>loading...</div>
        )}
  </div>
}

export default TeamSoloqCounts;