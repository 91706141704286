import React from 'react';
import * as style from './style.scss';

import AccountCard from 'src/components/AccountCard';
import { sendGetRequest } from 'src/utils/requests';
import { Account } from 'src/pages/Account/types';

const Leaderboard: React.FC = () => {
  const [accounts, setAccounts] = React.useState<Account[]>([]);

  React.useEffect(() => {
    const url = '/leaderboard'

    sendGetRequest(url).then(data => setAccounts(data));
  }, []);

  return (
    <div className={style.container}>
      <div className={style.title}>SoloQ Leaderboards</div>
      {accounts && accounts.length > 0 ?
        <div>
          {accounts
            .sort( (a: Account, b: Account) => {
              return b.league_points - a.league_points;
            })
            .map((account, index) => (
              <AccountCard key={account.id} account={account} position={index} />
            ))}
        </div>
        :
        <div>loading...</div>
      }

    </div>
  );
};

export default Leaderboard;
