interface Profile {
  id: number;
  champion: string;
  champion_slug: string;
  primary_roles: string[];
  secondary_roles: string[];
  damage_profiles: DmgProfile[];
  jobs: Job[];
}

interface DmgProfile {
  label: 'AD' | 'AP' | 'True' | 'Multiple';
  level: number;
}

interface Job {
  category: string;
  level: number;
  name: string;
}

interface FormState {
  roles: any[];
  filteredRoles: any[];
  dmg: any[],
  filteredDmg: any[],
  jobs: Job[],
  filteredJobs: Job[],
}

const initialState: FormState = {
  roles: [
    { name: 'Top' },
    { name: 'Jungle' },
    { name: 'Mid' },
    { name: 'Bottom' },
    { name: 'Support' },
  ],
  filteredRoles: [],
  dmg: [
    {name: 'AD'},
    {name: 'Magic'},
    {name: 'True'},
    {name: 'Multiple'},
  ],
  filteredDmg: [],
  jobs: [],
  filteredJobs: [],
};

export { Profile, Job, DmgProfile, FormState, initialState };
