import React from 'react';
import * as style from './style.scss';

import { Team } from 'src/pages/Team/types';
import { Player } from 'src/pages/Player/types';
import { Link } from 'react-router-dom';
import { imgUrl, sortByRole } from 'src/utils/helper';

interface Props {
  team: Team;
}

const TeamCard: React.FC<Props> = ({ team }) => {
  return (
    <div className={style.container}>
      <Link to={`/teams/${team.name}`}>
        <div className={style.title}>{team.name}</div>
      </Link>
      <div className={style.players}>
        {team.players.length > 0 &&
          sortByRole(team.players).map((player: Player) => {
            return (
              <Link key={player.name} to={`/players/${player.name}`}>
                <img className={style.role} src={imgUrl(player.role)}></img>
                <div className={style.player}>{player.name}</div>
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default TeamCard;
