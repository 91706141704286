import React from 'react';
import { ChampionPick, ScrimGame } from 'src/pages/Scrims/types';

import { cx as classnames } from 'emotion';
import * as style from './style.scss';
import { Role, championImageUrl } from 'src/utils/helper';
import { formatUrl } from 'src/utils/requests';

interface Props {
  games: ScrimGame[];
  displayColor: string;
  displaySide: string;
  allyTeam?: string;
  allyAcronym?: string;
  allyLogo?: string;
}

const ScrimTable: React.FC<Props> = ({
  games,
  displayColor,
  displaySide,
  allyTeam = 'BK ROG Esports',
  allyAcronym = 'BKR',
  allyLogo = 'https://static.wikia.nocookie.net/lolesports_gamepedia_en/images/3/3a/BK_ROG_Esportslogo_square.png',
}) => {
  const getPick = (game: ScrimGame, team: string, role: string): ChampionPick | undefined => {
    return game.picks.find(pick => pick.team === team && pick.role === role);
  };

  const sideTeam = (game: ScrimGame, side: string): string =>
    game.side === side ? allyTeam : game.team;

  const sideLogo = (game: ScrimGame, side: string): string | undefined =>
    game.side === side ? allyLogo : game.logo;

  const sideBans = (game: ScrimGame, side: string) => {
    return game.bans.filter(ban => ban.team == sideTeam(game, side));
  };

  const getReplayUrl = (gameId: number): string => {
    return formatUrl(`/lolesports/scrim_replay_file_url/${gameId}`);
  };

  const date = (game: ScrimGame): string => {
    return game.date ? game.date.toString().split('T')[0] : 'zut';
  };

  const roles: Role[] = ['top', 'jun', 'mid', 'adc', 'sup'];

  const rowColor = (game: ScrimGame) => {
    if (displayColor === 'result') return game.result ? style.victory : style.defeat;

    return game.side === 'blue' ? style.blue : style.red;
  };

  const scrimRow = (game: ScrimGame) => {
    const allyBans = game.bans.filter(ban => ban.team === allyTeam);
    const oppBans = game.bans.filter(ban => ban.team != allyTeam);

    const leftBans = displaySide === 'side' ? sideBans(game, 'blue') : allyBans;
    const rightBans = displaySide === 'side' ? sideBans(game, 'red') : oppBans;

    const leftTeam = displaySide === 'side' ? sideTeam(game, 'blue') : allyTeam;
    const rightTeam = displaySide === 'side' ? sideTeam(game, 'red') : game.team;

    return (
      <tr className={classnames(rowColor(game), style.row)}>
        <td className={style.date}>{date(game)}</td>
        <td className={style.patch}>{game.patch}</td>
        <td className={style.patch}>{game.is_official ? 'official' : 'scrim'}</td>
        <td className={style.team}>
          <img className={style.teamLogo} src={sideLogo(game, 'blue')} />
        </td>
        <td className={style.team}>
          <img className={style.teamLogo} src={sideLogo(game, 'red')} />
        </td>
        <td className={style.team}>
          <img className={style.teamLogo} src={game.result ? allyLogo : game.logo} />
        </td>
        <td className={style.comp}>
          {leftBans.sort((a, b) => a.order - b.order).map(b => (
            <img className={style.championImage} src={championImageUrl(b.slug)} />
          ))}
        </td>
        <td className={style.comp}>
          {rightBans.sort((a, b) => a.order - b.order).map(b => (
            <img className={style.championImage} src={championImageUrl(b.slug)} />
          ))}
        </td>
        <td className={style.comp}>
          {roles.map(role => (
            <img
              className={style.championImage}
              src={championImageUrl(getPick(game, leftTeam, role)?.slug)}
            />
          ))}
        </td>
        <td className={style.comp}>
          {roles.map(role => (
            <img
              className={style.championImage}
              src={championImageUrl(getPick(game, rightTeam, role)?.slug)}
            />
          ))}
        </td>
        <td>
          {game.id && (
            <a className={style.replayLink} href={getReplayUrl(game.id)} target="_blank" rel="noopener noreferrer">
              replay
            </a>
          )}
        </td>
      </tr>
    );
  };

  return (
    <table className={style.table}>
      <tr>
        <th>Date</th>
        <th>P</th>
        <th>Type</th>
        <th>Blue</th>
        <th>Red</th>
        <th>W</th>
        <th>{displaySide === 'side' ? 'Blue' : allyAcronym} Bans</th>
        <th>{displaySide === 'side' ? 'Red' : 'Vs'} Bans</th>
        <th>{displaySide === 'side' ? 'Blue' : allyAcronym} Picks</th>
        <th>{displaySide === 'side' ? 'Red' : 'Vs'} Picks</th>
        <th>Replay</th>
      </tr>

      {games.map(g => scrimRow(g))}
    </table>
  );
};

export default ScrimTable;
