import React from 'react';
import * as style from './style.scss';
import { Link } from 'react-router-dom';
import { Account } from 'src/pages/Account/types';
import { championImageUrl, imgUrl } from 'src/utils/helper';
// import { championImageUrl } from 'src/utils/helper';

interface Props {
  account: Account;
  position: number;
}

const AccountCard: React.FC<Props> = ({ account, position }) => {
  const accountSummonerName = account.riot_name ? `${account.riot_name} #${account.riot_tag}` : `${account.summoner_name}`
  const link = account.name ? `/players/${account.name}` : `/accounts/${account.riot_name}-${account.riot_tag}`;

  return (
    <Link to={link} className={style.container}>
      <div className={style.info}>
        <div className={style.rank}>{position + 1}</div>
        <div className={style.image}>
          <img
            className={style.icon}
            src={`https://ddragon.leagueoflegends.com/cdn/13.9.1/img/profileicon/${account.profile_icon_id}.png`}
          ></img>
        </div>
        <div className={style.account}>{accountSummonerName}</div>
        <div className={style.elo}>
          {account.tier} {account.rank} - {account.league_points} lp{' '}
        </div>
        <div className={style.role}>
          {account.role && <img src={imgUrl(account.role)} className={style.roleImg} />}
        </div>
        <div className={style.player}>{account.name}</div>
        <div className={style.team}>{account.team}</div>
        <div className={style.top3}>
          {account.top3_champions && account.top3_champions.length > 0 && (
            <img
              className={style.icon}
              src={championImageUrl(account.top3_champions[0].champion)}
            ></img>
          )}
          {account.top3_champions && account.top3_champions.length > 1 && (
            <img
              className={style.icon}
              src={championImageUrl(account.top3_champions[1].champion)}
            ></img>
          )}
          {account.top3_champions && account.top3_champions.length > 2 && (
            <img
              className={style.icon}
              src={championImageUrl(account.top3_champions[2].champion)}
            ></img>
          )}
        </div>
      </div>
    </Link>
  );
};

export default AccountCard;
