import React, { useReducer } from 'react';
import * as style from '../style.scss';
import { cx as classnames } from 'emotion';

import { initialState, reducer } from './reducer';
import { addPlayer, isSubmittable } from './helper';

import { Account } from 'src/pages/Account/types';
import { Player } from 'src/pages/Player/types';
import { Team } from 'src/pages/Team/types';

import { sendGetRequest } from 'src/utils/requests';
import Autocomplete from 'src/components/Autocomplete';
import RadioButton from 'src/components/RadioButton';

interface Props {
  close: () => void;
  refresh: (account: Account) => void;
  account: Account;
}

const AddPlayer: React.FC<Props> = ({ account, close, refresh }) => {
  const [formState, dispatch] = useReducer(reducer, initialState);

  const [allPlayers, setAllPlayers] = React.useState<Player[]>([]);
  const [allTeams, setAllTeams] = React.useState<Team[]>([]);

  React.useEffect(() => {
    sendGetRequest('/players').then(data => setAllPlayers(data));
    sendGetRequest('/teams').then(data => setAllTeams(data));
  }, []);

  const handlePlayer = (value: string): void => {
    dispatch({ type: 'SET_PLAYER', payload: value });

    const players = allPlayers.filter(player => player.name === value);
    if (players.length === 1) {
      const player = players[0];
      dispatch({ type: 'SET_TEAM', payload: player.team });
      dispatch({ type: 'SET_ROLE', payload: player.role });
    }
  };

  const handleTeam = (value: string): void => {
    dispatch({ type: 'SET_TEAM', payload: value });
  };

  const handleRole = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch({ type: 'SET_ROLE', payload: event.currentTarget.value });
  };

  const add = (): void => {
    if (!isSubmittable(formState)) {
      return;
    }
    addPlayer(account, formState).then(data => {
      refresh(data);
    });
    close();
  };

  const roles = [
    { label: 'Top', value: 'top' },
    { label: 'Jungle', value: 'jun' },
    { label: 'Mid', value: 'mid' },
    { label: 'ADC', value: 'adc' },
    { label: 'Support', value: 'sup' },
  ];

  return (
    <div className={style.container}>
      <div className={style.title}>Link to a player</div>
      <div className={style.form}>
        <div>Player</div>
        <Autocomplete
          allSuggestions={allPlayers}
          field="name"
          handleChange={handlePlayer}
          value={formState.player}
        />
        <div>Team</div>
        <Autocomplete
          allSuggestions={allTeams}
          field="name"
          handleChange={handleTeam}
          value={formState.team ?? ''}
        />
        <div>Role</div>
        <RadioButton name="role" options={roles} current={formState.role} onChange={handleRole} />
      </div>
      <div className={style.footer}>
        <div className={style.button} onClick={close}>
          Cancel
        </div>
        <div
          className={classnames(
            style.button,
            style.add,
            isSubmittable(formState) ? '' : style.disabled,
          )}
          onClick={add}
        >
          Add
        </div>
      </div>
    </div>
  );
};

export default AddPlayer;
