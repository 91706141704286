import React from 'react';
import * as style from './style.scss';
import { sendGetRequest } from 'src/utils/requests';
import AccountHistory from 'src/components/AccountHistory';
import AddPlayer from 'src/components/Modal/AddPlayer';

import { useParams, Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { initialState, Account } from './types';

const Account: React.FC = () => {
  const { accountName } = useParams<{ accountName: string }>();
  const [account, setAccount] = React.useState<Account>(initialState);

  const accountSummonerName = account.riot_name
    ? `${account.riot_name} #${account.riot_tag}`
    : `${account.summoner_name}`;

  React.useEffect(() => {
    const url = `/accounts/by-summoner-name/${accountName}`;
    sendGetRequest(url).then(data => setAccount(data));
  }, [accountName]);

  const modalStyle = {
    width: '320px',
    borderRadius: '6px',
    borderColor: '#1D2E3E',
    backgroundColor: '#1D2E3E',
  };

  const refresh = (account: Account): void => {
    setAccount(account);
  };

  return (
    <div className={style.container}>
      {account && account.id && (
        <>
          <div className={style.title}>{accountSummonerName}</div>
          {account.name ? (
            <Link className={style.link} to={`/players/${account.name}`}>
              {account.name}
            </Link>
          ) : (
            <Popup modal trigger={<button>Link to a player</button>} contentStyle={modalStyle}>
              {(close): JSX.Element => (
                <AddPlayer close={close} account={account} refresh={refresh} />
              )}
            </Popup>
          )}
          <AccountHistory id={account.id} />
        </>
      )}
    </div>
  );
};

export default Account;
