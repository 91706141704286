import { assertNever } from './types';

export type Role = '' | 'top' | 'jun' | 'mid' | 'adc' | 'sup';

export const imgUrl = (role: Role | 'ban'): string => {
  switch (role) {
    case 'top':
      return 'https://gamepedia.cursecdn.com/lolesports_gamepedia_en/thumb/4/44/Toprole_icon.png/28px-Toprole_icon.png?version=7593941c621085967291a3a5b9cc58f5';
    case 'jun':
      return 'https://gamepedia.cursecdn.com/lolesports_gamepedia_en/thumb/f/fb/Junglerole_icon.png/28px-Junglerole_icon.png?version=4875f3b58da203358827d44ef9bea4e7';
    case 'mid':
      return 'https://gamepedia.cursecdn.com/lolesports_gamepedia_en/thumb/c/ce/Midrole_icon.png/28px-Midrole_icon.png?version=99d9d2916e5ea4e26080ca0aae107254';
    case 'adc':
      return 'https://gamepedia.cursecdn.com/lolesports_gamepedia_en/thumb/d/d1/AD_Carryrole_icon.png/28px-AD_Carryrole_icon.png?version=e5a7c6aec9e5fecb5729973b70ed4526';
    case 'sup':
      return 'https://gamepedia.cursecdn.com/lolesports_gamepedia_en/thumb/7/73/Supportrole_icon.png/28px-Supportrole_icon.png?version=a6f23a005ffcf5e17fc4a5e4a7aa5b22';
    case 'ban':
      return 'https://cdn0.iconfinder.com/data/icons/glyphpack/36/ban-1024.png';

    case '':
      return '';

    default:
      assertNever(role);
  }
};

export const championImageUrl = (championSlug: string | undefined | null): string =>
  `https://ddragon.leagueoflegends.com/cdn/14.15.1/img/champion/${championSlug}.png`;

export const summonerSpellUrl = (spellName: string, gameVersion: string): string =>
  `http://ddragon.leagueoflegends.com/cdn/${gameVersion}.1/img/spell/${formatSpellName(
    spellName,
  )}.png`;

export const formatDate = (sDate: Date): string => {
  const day = sDate.toString().split('T')[0];
  const hour = sDate
    .toString()
    .split('T')[1]
    .split('.')[0]
    .slice(0, -3);
  return day + ' ' + hour;
};

export const formatGameTime = (timeInSeconds: number) => {
  // Format the game time as needed (e.g., convert seconds to minutes and seconds)
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

const roles: { [key: string]: number } = { top: 1, jun: 2, mid: 3, adc: 4, sup: 5, '': 6 };

export const sortByRole = (players: any[]): any[] => {
  return players.sort((a, b) => roles[a.role] - roles[b.role]);
};

export const roleName = (role: Role): string => {
  switch (role) {
    case 'top':
      return 'Top';
    case 'jun':
      return 'Jungle';
    case 'mid':
      return 'Mid';
    case 'adc':
      return 'Adc';
    case 'sup':
      return 'Support';
    default:
      return '';
  }
};

export const itemImageUrl = (gameVersion: string, itemId: number): string => {
  return `http://ddragon.leagueoflegends.com/cdn/${gameVersion}.1/img/item/${itemId}.png`;
  // if (gameVersion !== '14.1') {
  // }

  // const newItem = newItems.find(i => i['id'] === itemId);

  // if (newItem === undefined) {
  //   return `http://ddragon.leagueoflegends.com/cdn/13.24.1/img/item/${itemId}.png`;
  // }

  // return newItem['image'];
};

export const lolalyticsChampionName = (championName: string): string => {
  return championName.replace(/ /g, '').toLowerCase();
};

export const formatSpellName = (spellRawName: string): string => {
  if (spellRawName.includes('SummonerTeleport')) return 'SummonerTeleport';
  if (spellRawName.includes('SummonerSmite')) return 'SummonerSmite';
  if (spellRawName.includes('SummonerFlash')) return 'SummonerFlash';

  return spellRawName;
};

// temporary list for new items display
const newItems = [
  {
    id: 2022,
    name: 'Glowing Mote',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/6/64/Glowing_Mote_item.png/',
  },
  {
    id: 3865,
    name: 'World Atlas',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/3/35/World_Atlas_item.png/',
  },
  {
    id: 3869,
    name: 'Celestial Opposition',
    image:
      'https://static.wikia.nocookie.net/leagueoflegends/images/c/ce/Celestial_Opposition_item.png/',
  },
  {
    id: 3870,
    name: 'Dream Maker',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/0/0b/Dream_Maker_item.png/',
  },
  {
    id: 3871,
    name: "Zaz'Zak's Realmspike",
    image:
      "https://static.wikia.nocookie.net/leagueoflegends/images/4/49/Zaz'Zak's_Realmspike_item.png/",
  },
  {
    id: 3876,
    name: 'Solstice Sleigh',
    image:
      'https://static.wikia.nocookie.net/leagueoflegends/images/a/ad/Solstice_Sleigh_item.png/',
  },
  {
    id: 3877,
    name: 'Bloodsong',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/a/a9/Bloodsong_item.png/',
  },
  {
    id: 6690,
    name: 'Rectrix',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/6/6c/Rectrix_item.png/',
  },
  {
    id: 2021,
    name: 'Tunneler',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/1/18/Tunneler_item.png/',
  },
  {
    id: 2019,
    name: 'Steel Sigil',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/7/7c/Steel_Sigil_item.png/',
  },
  {
    id: 3147,
    name: 'Haunting Guise',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/4/40/Haunting_Guise_item.png/',
  },
  {
    id: 2020,
    name: 'The Brutalizer',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/b/b4/The_Brutalizer_item.png',
  },
  {
    id: 3002,
    name: 'Trailblazer',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/f/f1/Trailblazer_item.png',
  },
  {
    id: 6621,
    name: 'Dawncore',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/7/72/Dawncore_item.png',
  },
  {
    id: 6701,
    name: 'Opportunity',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/a/af/Opportunity_item.png',
  },
  {
    id: 6610,
    name: 'Sundered Sky',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/4/4f/Sundered_Sky_item.png',
  },
  {
    id: 2502,
    name: 'Unending Despair',
    image:
      'https://static.wikia.nocookie.net/leagueoflegends/images/e/e6/Unending_Despair_item.png',
  },
  {
    id: 3118,
    name: 'Malignance',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/a/af/Malignance_item.png',
  },
  {
    id: 6664,
    name: 'Hollow Radiance',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/9/9f/Hollow_Radiance_item.png',
  },
  {
    id: 3137,
    name: 'Cryptbloom',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/b/b2/Cryptbloom_item.png',
  },
  {
    id: 3867,
    name: 'Bounty of Worlds',
    image:
      'https://static.wikia.nocookie.net/leagueoflegends/images/7/73/Bounty_of_Worlds_item_HD.png',
  },
  {
    id: 2504,
    name: 'Kaenic Rookern',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/c/cb/Kaenic_Rookern_item.png',
  },
  {
    id: 4646,
    name: 'Stormsurge',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/b/b4/Stormsurge_item.png',
  },
  {
    id: 6699,
    name: 'Voltaic Cyclosword',
    image:
      'https://static.wikia.nocookie.net/leagueoflegends/images/6/63/Voltaic_Cyclosword_item.png',
  },
  {
    id: 3073,
    name: 'Experimental Hexplate',
    image:
      'https://static.wikia.nocookie.net/leagueoflegends/images/0/03/Experimental_Hexplate_item.png',
  },
  {
    id: 3302,
    name: 'Terminus',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/4/49/Terminus_item.png',
  },
  {
    id: 6697,
    name: 'Hubris',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/5/54/Hubris_item.png',
  },
  {
    id: 6653,
    name: "Liandry's Torment",
    image:
      "https://static.wikia.nocookie.net/leagueoflegends/images/f/fd/Liandry's_Torment_item.png",
  },
  {
    id: 6655,
    name: "Luden's Companion",
    image:
      "https://static.wikia.nocookie.net/leagueoflegends/images/1/15/Luden's_Companion_item.png",
  },
  {
    id: 6698,
    name: 'Profane Hydra',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/f/f0/Profane_Hydra_item.png',
  },
  {
    id: 7008,
    name: 'Ataraxia',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/2/2a/Ataraxia_item.png',
  },
  {
    id: 7035,
    name: 'Daybreak',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/7/7c/Daybreak_item.png',
  },
  {
    id: 7022,
    name: 'Certainty',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/c/c4/Certainty_item.png/',
  },
  {
    id: 7004,
    name: 'Enmity of Masses',
    image:
      'https://static.wikia.nocookie.net/leagueoflegends/images/9/98/Enmity_of_Masses_item.png/',
  },
  {
    id: 7040,
    name: 'Eye of the Storm',
    image:
      'https://static.wikia.nocookie.net/leagueoflegends/images/3/35/Eye_of_the_Storm_item.png/',
  },
  {
    id: 7013,
    name: 'Force of Arms',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/d/d9/Force_of_Arms_item.png',
  },
  {
    id: 7039,
    name: 'Heavensfall',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/2/2d/Heavensfall_item.png',
  },
  {
    id: 7034,
    name: 'Hope Adrift',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/d/d2/Hope_Adrift_item.png',
  },
  {
    id: 7037,
    name: 'Obsidian Clever',
    image:
      'https://static.wikia.nocookie.net/leagueoflegends/images/4/46/Obsidian_Cleaver_item.png',
  },
  {
    id: 7003,
    name: "Rabadon's Deathcrown",
    image:
      "https://static.wikia.nocookie.net/leagueoflegends/images/a/ad/Rabadon's_Deathcrown_item.png",
  },
  {
    id: 7038,
    name: "Shojin's Resolve",
    image:
      "https://static.wikia.nocookie.net/leagueoflegends/images/b/b7/Shojin's_Resolve_item.png",
  },
  {
    id: 7007,
    name: 'Swordnado',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/b/b3/Swordnado_item.png',
  },
  {
    id: 7036,
    name: 'T.U.R.B.O.',
    image: 'https://static.wikia.nocookie.net/leagueoflegends/images/9/9b/T._U._R._B._O._item.png/',
  },
  {
    id: 7042,
    name: "The Baron's Gift",
    image:
      "https://static.wikia.nocookie.net/leagueoflegends/images/b/b9/The_Baron's_Gift_item.png",
  },
  {
    id: 7041,
    name: 'Wyrmfallen Sacrifice',
    image:
      'https://static.wikia.nocookie.net/leagueoflegends/images/d/da/Wyrmfallen_Sacrifice_item.png/',
  },
  {
    id: 2150,
    name: 'Elixir of Skill',
    image:
      'https://static.wikia.nocookie.net/leagueoflegends/images/3/31/Ichor_of_Illumination_item.png',
  },
];
// SummonerSmiteAvatarUtility
