import React from 'react';
import { cx as classnames } from 'emotion';
import { TeleportationEvent } from 'src/pages/Historical/types';
import { championImageUrl, formatGameTime } from 'src/utils/helper';

import * as style from './style.scss';

interface Props {
  event: TeleportationEvent;
  isSelected: boolean;
}

const Teleportation: React.FC<Props> = ({ event, isSelected }) => {
  const selectedStyle = () => {
    if (!isSelected) {
      return ''
    }

    return event.side === 'blue' ? style.selectedBlue : style.selectedRed
  }

  return (
    <div className={classnames(style.eventCard, selectedStyle())}>
      <div className={style.championPortrait}>
        <img
          src={championImageUrl(event.champion.slug)}
          alt={`${event.champion} Portrait`}
          className={style.championPortrait}
        />
      </div>
      <div className={style.eventName}>{event.player}</div>
      <div>used teleportation</div>
      <div className={style.gameTime}>{formatGameTime(event.game_time)}</div>
    </div>
  );
};

export default Teleportation;
