import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { login } from './helper';
import * as style from './style.scss';

interface Props {}

const Login: React.FC<Props> = ({}) => {
  const [token, setToken] = React.useState<string>('');
  const history = useHistory();
  const route = useLocation();

  const [authError, setAuthError] = React.useState<boolean>(false);

  const sendLogin = () => {
    login(token).then(loggedIn => {
      if (loggedIn) {
        setAuthError(false);
        history.push(route);
      } else {
        setAuthError(true);
      }
    });
    return false;
  };

  return (
    <form className={style.container} onSubmit={sendLogin} action="#">
      {authError && <div>Incorrect password</div>}
      <input
        className={style.input}
        type="password"
        onChange={value => setToken(value.target.value)}
      ></input>
      <button className={style.button} onClick={sendLogin}>Log in</button>
    </form>
  );
};

export default Login;
