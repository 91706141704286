// import { assertNever } from 'src/utils/types'

interface FormState {
  account: string,
}

const initialState: FormState = {
  account: '',
}

interface SetAccount {
  type: 'SET_ACCOUNT',
  payload: string,
}

type FormStateAction = SetAccount

const reducer = (state: FormState, action: FormStateAction): FormState => {
  switch (action.type) {
    case 'SET_ACCOUNT':
      return {
        ...state,
        account: action.payload,
      }
    default:
      return state
      //assertNever(action)
  }
}

export { initialState, reducer, FormState}