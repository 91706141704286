import { assertNever } from 'src/utils/types';
import { FormState } from './types';

interface SetTeams {
  type: 'SET_TEAMS';
  payload: any[];
}

interface SetFilteredTeams {
  type: 'SET_FILTERED_TEAMS';
  payload: any;
}

interface SetFilteredChampions {
  type: 'SET_FILTERED_CHAMPIONS';
  payload: any;
}

type FormStateAction = SetTeams | SetFilteredTeams | SetFilteredChampions;

const reducer = (state: FormState, action: FormStateAction): FormState => {
  switch (action.type) {
    case 'SET_TEAMS':
      return { ...state, teams: action.payload };
    case 'SET_FILTERED_TEAMS':
      return { ...state, filteredTeams: action.payload };
    case 'SET_FILTERED_CHAMPIONS':
      return { ...state, filteredChampions: action.payload };
    default:
      assertNever(action);
  }
};

export { reducer };
