import React from 'react'
import { useLocation } from 'react-router-dom'

interface Props {
  children: React.ReactNode
}

const defaultLogState = true; // to set false

export const LogContext = React.createContext(defaultLogState);

export const useLogState = () => {
  return React.useContext(LogContext);
}

export const ContextProvider: React.FC<Props> = ({ children }) => {
  const [login, setLogin] = React.useState(defaultLogState);
  const route = useLocation();

  React.useEffect(() => {
   const token = localStorage.getItem('token')
   setLogin(!!token)
  }, [route])

  return (
    <LogContext.Provider value={login}>
      {children}
    </LogContext.Provider>
  )
}

export default ContextProvider;