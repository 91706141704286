import { Role } from "src/utils/helper";
import { Game } from "../Game/types";

interface Account {
  id: number,
  name?: string,
  role?: Role
  summoner_name: string,
  profile_icon_id: number,
  riot_name: string | null,
  riot_tag: string | null,
  tier: string,
  rank: string,
  league_points: number,
  team: string,
  wins: number,
  losses: number,
  history: Game[],
  top3_champions?: Pick[]
}

interface Pick {
  champion: string,
}

const initialState: Account = {
  id: 0,
  summoner_name: '',
  profile_icon_id: 0,
  tier: '',
  rank: '',
  riot_name: null,
  riot_tag: null,
  league_points: 0,
  team: '',
  wins: 0,
  losses: 0,
  history: [],
}

export { initialState, Account, Pick };