import { assertNever } from 'src/utils/types';
import { FormState } from './types';

interface SetFilteredRoles {
  type: 'SET_FILTERED_ROLES';
  payload: any;
}

interface SetFilteredDmg {
  type: 'SET_FILTERED_DMG';
  payload: any;
}

interface SetJobs {
  type: 'SET_JOBS';
  payload: any;
}

interface SetFilteredJobs {
  type: 'SET_FILTERED_JOBS';
  payload: any;
}

type FormStateAction = SetFilteredRoles | SetFilteredDmg | SetJobs | SetFilteredJobs;

const reducer = (state: FormState, action: FormStateAction): FormState => {
  switch (action.type) {
    case 'SET_FILTERED_ROLES':
      return { ...state, filteredRoles: action.payload };

    case 'SET_FILTERED_DMG':
      return { ...state, filteredDmg: action.payload}

    case 'SET_JOBS':
      return { ...state, jobs: action.payload}

    case 'SET_FILTERED_JOBS':
      return { ...state, filteredJobs: action.payload}

    default:
      assertNever(action);
  }
};

export { reducer };
