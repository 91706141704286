import React, { useEffect, useReducer, useState } from 'react';
import { cx as classnames } from 'emotion';

import HistoricalSidebar from 'src/components/Historical/Sidebar';
import { sendGetRequest, formatUrl } from 'src/utils/requests';

import { Game, GamePlayer } from '../types';
import { FormState } from 'src/components/Historical/Sidebar/types';
import { reducer } from 'src/components/Historical/Pagination/reducer';
import { initialState } from 'src/components/Historical/Pagination/types';

import InfiniteScroll from 'react-infinite-scroll-component';

import * as style from './style.scss';
import { championImageUrl, formatDate } from 'src/utils/helper';
import { gameName, getPlayerByRole } from '../helper';

const HistoricalGames: React.FC = () => {
  const [games, setGames] = useState<Game[]>([]);
  // needed to keep filters on scroll, could probs be better handled
  const [urlParams, setUrlParams] = useState<string>('');
  const [paginationState, dispatch] = useReducer(reducer, initialState);

  const getGames = (currentGames: Game[], page: number, url_params: string): void => {
    const url = `/bayes/games?page=${page}&${url_params}`;
    dispatch({ type: 'SET_LOADING', payload: true });

    sendGetRequest(url).then(data => {
      dispatch({ type: 'SET_LOADING', payload: false });
      setGames(currentGames.concat(data));

      if (data.length === 0) {
        dispatch({ type: 'SET_HAS_MORE', payload: false });
      }
    });
  };

  useEffect(() => {
    getGames([], paginationState.page, '');
  }, []);

  const loadMoreGames = (): void => {
    const newPage = paginationState.page + 1;
    dispatch({ type: 'SET_PAGE', payload: newPage });
    getGames(games, newPage, urlParams);
  };

  const applyFilters = (filters: FormState): void => {
    dispatch({ type: 'SET_PAGE', payload: 0 });
    dispatch({ type: 'SET_HAS_MORE', payload: true });

    const champions = filters.champions.map(champion => champion.name);
    const leagues = filters.leagues.map(league => league.id);
    const patches = filters.patches.map(
      patch => patch.name.split('.')[0] + '.' + patch.name.split('.')[1],
    );
    const teams = filters.teams.map(team => team.id);

    const startDate = filters.start_date ? filters.start_date?.toISOString() : '';
    const endDate = filters.end_date ? filters.end_date?.toISOString() : '';

    const hasProview = filters.has_proview ? '&has_proview=true' : '';

    const newUrlParams = `teams=${teams}&champions=${champions}&leagues=${leagues}&patches=${patches}&start_date=${startDate}&end_date=${endDate}${hasProview}`;
    setUrlParams(newUrlParams);
    getGames([], 0, newUrlParams);
  };

  const getReplayUrl = (gameId: number): string => {
    return formatUrl(`/lolesports/replay_file_url/${gameId}`)
  }

  const picks = (picks: GamePlayer[]): React.ReactElement => {
    return (
      <div className={style.composition}>
        <div>
          <img
            className={style.icon}
            src={championImageUrl(getPlayerByRole(picks, 'top')?.champion_slug)}
          ></img>
        </div>
        <div>
          <img
            className={style.icon}
            src={championImageUrl(getPlayerByRole(picks, 'jun')?.champion_slug)}
          ></img>
        </div>
        <div>
          <img
            className={style.icon}
            src={championImageUrl(getPlayerByRole(picks, 'mid')?.champion_slug)}
          ></img>
        </div>
        <div>
          <img
            className={style.icon}
            src={championImageUrl(getPlayerByRole(picks, 'adc')?.champion_slug)}
          ></img>
        </div>
        <div>
          <img
            className={style.icon}
            src={championImageUrl(getPlayerByRole(picks, 'sup')?.champion_slug)}
          ></img>
        </div>
      </div>
    );
  };

  return (
    <div className={style.container}>
      <HistoricalSidebar onApply={applyFilters} pageType="games" />
      <div className={style.table}>
        <div className={style.header}>
          <div className={style.headerText}>Date</div>
          <div className={style.headerText}>League</div>
          <div className={style.headerText}>Patch</div>
          <div className={style.headerText}>Name</div>
          <div className={style.headerText}>Winner</div>
          <div className={classnames(style.headerLarge, style.rowCompo)}>Compositions</div>
          <div className={style.headerLarge}>Game Stats</div>
          <div className={style.headerLarge}>Replay File</div>
        </div>
        <div>
          <InfiniteScroll
            dataLength={games.length}
            next={loadMoreGames}
            hasMore={paginationState.hasMore}
            loader={<h4>Loading...</h4>}
          >
            {games.map(game => {
              return (
                <div key={game.id} className={style.row}>
                  <div className={style.rowText}>{formatDate(game.start_time)}</div>
                  <div className={style.rowText}>{game.league}</div>
                  <div className={style.rowText}>{game.version}</div>
                  <div className={style.rowText}>{gameName(game)}</div>
                  <div
                    className={classnames(
                      style.rowText,
                      game.winner.id ===
                        game.game_teams.find(game_team => game_team.side === 'blue')?.team.id
                        ? style.blue
                        : style.red,
                    )}
                  >
                    {game.winner ? game.winner.acronym : 'No winner'}
                  </div>
                  <div className={classnames(style.rowLarge, style.rowCompo)}>
                    {picks(game.game_players.filter(game_player => game_player.side === 'blue'))} vs{' '}
                    {picks(game.game_players.filter(game_player => game_player.side === 'red'))}
                  </div>
                  <div className={style.rowLink}>
                    <a
                      href={`/historical/games/${game.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      link
                    </a>
                  </div>
                  <div className={style.rowLink}>
                    <a href={getReplayUrl(game.id)}
                    target="_blank"
                    rel="noopener noreferrer"
                    > Replay File</a>
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default HistoricalGames;
