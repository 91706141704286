import { assertNever } from 'src/utils/types';
import { FilterObject, FormState } from './types';

interface SetLeagues {
  type: 'SET_LEAGUES';
  payload: FilterObject[];
}

interface SetPatches {
  type: 'SET_PATCHES';
  payload: FilterObject[];
}

interface SetStartDate {
  type: 'SET_START_DATE';
  payload: Date | null;
}

interface SetEndDate {
  type: 'SET_END_DATE';
  payload: Date | null;
}

interface SetChampions {
  type: 'SET_CHAMPIONS';
  payload: FilterObject[];
}

interface SetRoles {
  type: 'SET_ROLES';
  payload: FilterObject[];
}

interface SetTeams {
  type: 'SET_TEAMS';
  payload: FilterObject[];
}

interface SetHasProview {
  type: 'SET_HAS_PROVIEW';
  payload: boolean;
}

type FormStateAction =
  | SetLeagues
  | SetPatches
  | SetStartDate
  | SetEndDate
  | SetChampions
  | SetRoles
  | SetTeams
  | SetHasProview;

const reducer = (state: FormState, action: FormStateAction): FormState => {
  switch (action.type) {
    case 'SET_CHAMPIONS':
      return { ...state, champions: action.payload };
    case 'SET_LEAGUES':
      return { ...state, leagues: action.payload };
    case 'SET_PATCHES':
      return { ...state, patches: action.payload };
    case 'SET_ROLES':
      return { ...state, roles: action.payload };
    case 'SET_TEAMS':
      return { ...state, teams: action.payload };
    case 'SET_START_DATE':
      return { ...state, start_date: action.payload };
    case 'SET_END_DATE':
      return { ...state, end_date: action.payload };
    case 'SET_HAS_PROVIEW':
      return { ...state, has_proview: action.payload };
    default:
      assertNever(action);
  }
};

export { reducer };
