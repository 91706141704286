import React from 'react';
import * as style from './style.scss';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';

const Sidebar: React.FC<{}> = () => {
  const leaderboardsPath = useRouteMatch('/leaderboards');
  const playersPath = useRouteMatch('/players/');
  const accountsPath = useRouteMatch('/accounts/');
  const championsPath = useRouteMatch('/champions/');
  const teamsPath = useRouteMatch('/teams/');
  // const patchPath = useRouteMatch('/patch/');

  const scrimsPath = useRouteMatch('/scrims');
  const allScrimsPath = useRouteMatch('/scrim_games');
  const scrimsDraftsPath = useRouteMatch('/scrim_drafts');
  // const allBlitzScrimsPath = useRouteMatch('/scrim_blitz_games');

  // const academyScrimsPath = useRouteMatch('/academy_scrims');
  // const allAcademyScrimsPath = useRouteMatch('/academy_scrim_games');
  // const allAcademyBlitzScrimsPath = useRouteMatch('/academy_scrim_blitz_games');

  // const profilesPath = useRouteMatch('/profiles');
  // const theorycraftPath = useRouteMatch('/theorycraft');
  // const officialStatsPath = useRouteMatch('/official_stats');
  // const bayesPath = useRouteMatch('/bayes')

  const route = useLocation();
  const isHistorical = route.pathname.includes('historical');

  if (isHistorical) {
    return <></>;
  }

  const history = useHistory();
  const logOut = () => {
    localStorage.removeItem('token');
    history.push('/');
  };

  return (
    <nav className={style.container}>
      <ul>
        <li className={leaderboardsPath || playersPath || accountsPath ? style.active : ''}>
          <a href="/leaderboards">SoloQ Leaderboards</a>
        </li>
        <li className={championsPath ? style.active : ''}>
          <a href="/champions">Champions</a>
          {/* <ul>
            <li className={profilesPath ? style.active : style.inactive}>
              <a href="/profiles">Profiles</a>
            </li>
          </ul> */}
        </li>
        <li className={teamsPath ? style.active : ''}>
          <a href="/teams">Teams</a>
        </li>
        {/* <li className={patchPath ? style.active : ''}>
          <a href="/patch">Patch Winrates</a>
        </li> */}
        <li className={scrimsPath ? style.active : ''}>
          <a href="/scrims">Scrim stats</a>
          <ul>
            <li className={allScrimsPath ? style.active : style.inactive}>
              <a href="/scrim_games">All results</a>
            </li>
            <li className={scrimsDraftsPath ? style.active : style.inactive}>
              <a href="/scrim_drafts">Drafts</a>
            </li>
          </ul>
          {/* <ul>
            <li className={allBlitzScrimsPath ? style.active : style.inactive}>
              <a href="/scrim_blitz_games">All blitz results</a>
            </li>
          </ul> */}
        </li>
        {/* <li className={academyScrimsPath ? style.active : ''}>
          <a href="/academy_scrims">Academy Scrim stats</a>
          <ul>
            <li className={allAcademyScrimsPath ? style.active : style.inactive}>
              <a href="/academy_scrim_games">All results</a>
            </li>
          </ul>
          <ul>
            <li className={allAcademyBlitzScrimsPath ? style.active : style.inactive}>
              <a href="/academy_scrim_blitz_games">All blitz results</a>
            </li>
          </ul>
        </li> */}
        {/* <li className={officialStatsPath ? style.active : ''}>
          <a href="/official_stats">Official stats</a>
        </li> */}
        {/* <li className={theorycraftPath ? style.active : ''}>
          <a href="/theorycraft">Damage Calculator</a>
        </li> */}
        <li>
          <a href="/historical/games">Historical Data</a>
        </li>
        {/* <li>
          <a href="/historical/champions_queue_games">Champions Queue Data</a>
        </li> */}
        {/* <li className={bayesPath ? style.active : ''}><a href='/bayes'>Bayes Data fetcher</a></li> */}
        {localStorage.getItem('token') && (
          <li>
            <div className={style.logout} onClick={logOut}>Log out</div>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Sidebar;
