import { AxiosError } from 'axios';
import { sendGetRequest } from 'src/utils/requests';
import "regenerator-runtime" // avoid async/await

export const login = async (token: string): Promise<boolean> => {
  localStorage.setItem('token', token);

  return await sendGetRequest('/auth')
    .then(data => {
      if (data == 'ok') {
        return true;
      }
      return false;
    })
    .catch((error: AxiosError<any>) => {
      console.log(error);
      localStorage.removeItem('token');
      return false;
    });
};
