import { assertNever } from 'src/utils/types';
import { FormState, ChampionBaseData, Stats, ApheliosWeapon, initialState } from './types';

interface SetChampion {
  type: 'SET_CHAMPION_NAME';
  payload: string;
}

interface SetPatch {
  type: 'SET_PATCH';
  payload: string;
}

interface SetLevel {
  type: 'SET_LEVEL';
  payload: number;
}

interface SetItem {
  type: 'SET_ITEM';
  payload: { name: string; index: number };
}

interface SetRune {
  type: 'SET_RUNE';
  payload: { tier: number; value: string };
}

interface SetSpellLevel {
  type: 'SET_SPELL_LEVEL';
  payload: { key: 'Q' | 'W' | 'E' | 'R'; level: number };
}

interface SetSteroid {
  type: 'SET_STEROID';
  payload: { key: 'Q' | 'W' | 'E' | 'R' | 'P' | 'Form'; value: boolean };
}

interface SetApheliosWeapon {
  type: 'SET_APHELIOS_WEAPON';
  payload: ApheliosWeapon;
}

interface SetSylasUlt {
  type: 'SET_SYLAS_ULT';
  payload: string;
}

interface SetEnemyType {
  type: 'SET_ENEMY_TYPE';
  payload: string;
}

interface SetIngameTimer {
  type: 'SET_INGAME_TIMER';
  payload: string;
}

interface SetEnemyStats {
  type: 'SET_ENEMY_STATS';
  payload:
    | {
        armor: number;
        hp: number;
        magic_res: number;
      }
    | undefined;
}

interface SetChampionData {
  type: 'SET_CHAMPION_DATA';
  payload: ChampionBaseData;
}

interface SetStats {
  type: 'SET_STATS';
  payload: Stats;
}

interface SetSpellsDmg {
  type: 'SET_SPELLS_DMG';
  payload: {
    q_dmg: number;
    w_dmg: number;
    e_dmg: number;
    r_dmg: number;
    passive_dmg: number;
  };
}

interface ClearState {
  type: 'CLEAR_STATE';
}

type FormStateAction =
  | SetChampion
  | SetLevel
  | SetItem
  | SetRune
  | SetSpellLevel
  | SetSteroid
  | SetApheliosWeapon
  | SetSylasUlt
  | SetPatch
  | SetEnemyType
  | SetIngameTimer
  | SetEnemyStats
  | SetChampionData
  | SetStats
  | SetSpellsDmg
  | ClearState;

const reducer = (state: FormState, action: FormStateAction): FormState => {
  switch (action.type) {
    case 'SET_CHAMPION_NAME':
      return { ...state, name: action.payload };

    case 'SET_LEVEL':
      return { ...state, level: action.payload };

    case 'SET_ITEM':
      state.items[action.payload.index] = action.payload.name;
      return { ...state, items: state.items };

    case 'SET_RUNE':
      state.runes[action.payload.tier] = action.payload.value;
      return { ...state, runes: state.runes };

    case 'SET_SPELL_LEVEL':
      state.spells_levels[action.payload.key] = action.payload.level;
      return {
        ...state,
        spells_levels: state.spells_levels,
      };

    case 'SET_STEROID':
      state.steroids[action.payload.key] = action.payload.value;
      return {
        ...state,
        steroids: state.steroids,
      };

    case 'SET_APHELIOS_WEAPON':
      return { ...state, aphelios_weapon: action.payload };

    case 'SET_SYLAS_ULT':
      return { ...state, sylas_ult: action.payload };

    case 'SET_ENEMY_TYPE':
      return { ...state, enemy: { ...state.enemy, type: action.payload } };

    case 'SET_INGAME_TIMER':
      return { ...state, enemy: { ...state.enemy, time: action.payload } }

    case 'SET_ENEMY_STATS':
      return { ...state, enemy_stats: action.payload };

    case 'SET_PATCH':
      return { ...state, patch: action.payload };

    case 'SET_CHAMPION_DATA':
      return { ...state, champion: action.payload };

    case 'SET_STATS':
      return { ...state, stats: action.payload };

    case 'SET_SPELLS_DMG':
      return { ...state, spells: action.payload };

    case 'CLEAR_STATE':
      return { ...initialState };

    default:
      assertNever(action);
  }
};

export { reducer };
