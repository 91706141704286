interface ScrimGame {
  team: string,
  team_acronym: string,
  youtube_link: string,
  bayes_game_id: number | null,
  bayes_status: 'COMPLETE' | 'IN_PROGRESS' | null,
  side: 'blue' | 'red' | '',
  patch: string,
  result: boolean,
  date: Date | null,
  bans: Ban[],
  picks: ChampionPick[],
  is_official: boolean,
  is_blitz: boolean,
  is_main: boolean,
  is_internal: boolean,
  // only sent by backend
  id?: number,
  logo?: string,
}

interface Ban {
  id: number;
  team: string,
  order: number,
  champion: string,
  slug?: string,
}

interface ChampionPick {
  id: number,
  team: string,
  order: number,
  role: string,
  champion: string,
  slug?: string
}

interface FormState {
  teams: any[],
  filteredTeams: any[],
  filteredChampions: any[],
}

const initialState: FormState = {
  teams: [],
  filteredTeams: [],
  filteredChampions: [],
}

export { ScrimGame, Ban, ChampionPick, FormState, initialState }