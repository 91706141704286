import React from 'react';
import * as style from './style.scss';
import { cx as classnames } from 'emotion';
import { Totals, Champion } from 'src/pages/Player/types';

import Icon from 'src/components/Icon';
import up from 'src/icons/up.svg';
import down from 'src/icons/down.svg';
import { championImageUrl } from 'src/utils/helper';

type SortableField = keyof Champion | 'winrate' | 'total' | 'games' | 'blitz_games';

interface Props {
  history: Totals;
  hasKda: boolean;
  hasWinrate?: boolean;
  hasBlitzGames?: boolean;
  defaultSortedField?: string
}


const StatsTable: React.FC<Props> = ({
  history,
  hasKda,
  hasWinrate = true,
  hasBlitzGames = false,
  defaultSortedField = 'games'
}) => {
  const [sortedField, setSortedField] = React.useState<SortableField>(defaultSortedField as SortableField);
  const [sortOrder, setSortOrder] = React.useState<number>(1);

  const winrate = (wins: number, games: number): string | number => {
    if (games == 0) return '-';

    return Math.round((100 * wins) / games);
  };

  const date = ({ date }: Champion): string => {
    // 2020-04-13T22:04:58.0850
    const dateParts = date.split('T');
    const dateSubParts = dateParts[0].split('-');
    return dateSubParts[2] + '/' + dateSubParts[1] + '/' + dateSubParts[0];
  };

  const kda = ({ kills, deaths, assists }: Champion): string | number => {
    if (deaths === 0) {
      return 'perfect';
    }

    if (!kills || !assists || !deaths) {
      return 0;
    }

    return Math.round(((kills + assists) * 100) / deaths) / 100;
  };

  const wrColors = (value: number | string): string => {
    if (value == '-' || typeof(value) === 'string') return '';

    if (value <= 10) {
      return style.wr0;
    }
    if (value <= 20) {
      return style.wr1;
    }
    if (value <= 30) {
      return style.wr2;
    }
    if (value <= 40) {
      return style.wr3;
    }
    if (value <= 50) {
      return style.wr4;
    }
    if (value <= 60) {
      return style.wr5;
    }
    if (value <= 70) {
      return style.wr6;
    }
    if (value <= 80) {
      return style.wr7;
    }
    if (value <= 90) {
      return style.wr8;
    }
    return style.wr9;
  };

  const nbColors = (value: number): string => {
    const max = Math.max(...
      history.champions.map(function(champion: Champion) {
        return champion.games;
      }),
    );
    const ratio = (value * 100) / max;

    if (ratio <= 10) {
      return style.nb0;
    }
    if (ratio <= 20) {
      return style.nb1;
    }
    if (ratio <= 30) {
      return style.nb2;
    }
    if (ratio <= 40) {
      return style.nb3;
    }
    if (ratio <= 50) {
      return style.nb4;
    }
    if (ratio <= 60) {
      return style.nb5;
    }
    if (ratio <= 70) {
      return style.nb6;
    }
    if (ratio <= 80) {
      return style.nb7;
    }
    if (ratio <= 90) {
      return style.nb8;
    }
    return style.nb9;
  };

  const handleSort = (field: SortableField): void => {
    if (field === sortedField) {
      setSortOrder(-sortOrder);
      return;
    }
    setSortedField(field);
    setSortOrder(1);
  };

  return (
    <div className={style.stats}>
      <table>
        <thead>
          <tr>
            <th className={style.img}></th>
            <th className={style.name}>Champion</th>
            {hasWinrate && (
              <>
                <th className={style.games}>
                  <div className={style.sortButton} onClick={(): void => handleSort('games')}>
                    {sortedField === 'games' ? (
                      sortOrder === 1 ? (
                        <Icon className={style.icon} href={down} />
                      ) : (
                        <Icon className={style.icon} href={up} />
                      )
                    ) : (
                      <div className={style.icon} />
                    )}
                    Games
                  </div>
                </th>

                <th className={style.winrate}>
                  <div className={style.sortButton} onClick={(): void => handleSort('winrate')}>
                    {sortedField === 'winrate' ? (
                      sortOrder === 1 ? (
                        <Icon className={style.icon} href={down} />
                      ) : (
                        <Icon className={style.icon} href={up} />
                      )
                    ) : (
                      <div className={style.icon} />
                    )}
                    Winrate
                  </div>
                </th>
              </>
            )}
            {hasBlitzGames && (
              <th className={style.games}>
                <div className={style.sortButton} onClick={(): void => handleSort('blitz_games')}>
                  {sortedField === 'blitz_games' ? (
                    sortOrder === 1 ? (
                      <Icon className={style.icon} href={down} />
                    ) : (
                      <Icon className={style.icon} href={up} />
                    )
                  ) : (
                    <div className={style.icon} />
                  )}
                  Blitz Games
                </div>
              </th>
            )}
            {hasBlitzGames && hasWinrate && (
              <th className={style.games}>
                <div className={style.sortButton} onClick={(): void => handleSort('total')}>
                  {sortedField === 'total' ? (
                    sortOrder === 1 ? (
                      <Icon className={style.icon} href={down} />
                    ) : (
                      <Icon className={style.icon} href={up} />
                    )
                  ) : (
                    <div className={style.icon} />
                  )}
                  Total
                </div>
              </th>
            )}
            <th className={style.date}>Date Played</th>
            {hasKda && <th className={style.kda}>KDA</th>}
          </tr>
        </thead>
        <tbody className={style.championsList}>
          {history.champions.length > 0 &&
            history.champions
              .sort((a: Champion, b: Champion) => {
                let a1 = null;
                let b1 = null;
                if (sortedField === 'winrate') {
                  a1 = winrate(a.wins, a.games) as number;
                  b1 = winrate(b.wins, b.games) as number;
                } else {
                  a1 = a[sortedField] as number;
                  b1 = b[sortedField] as number;
                }

                if (a1 === b1) {
                  return Date.parse(b.date) - Date.parse(a.date);
                }
                return sortOrder * (b1 - a1);
              })
              .map(champion => {
                const wrClass = classnames(
                  style.winrate,
                  wrColors(winrate(champion.wins, champion.games)),
                );
                const nbClass = classnames(style.games, nbColors(champion.games));

                return (
                  <tr key={champion.name}>
                    <td className={style.img}>
                      {' '}
                      <img
                        className={style.championImg}
                        src={championImageUrl(champion.slug ? champion.slug : champion.name)}
                      />
                    </td>
                    <td className={style.name}>{champion.name}</td>
                    {hasWinrate && (
                      <>
                        <td className={nbClass}>{champion.games}</td>
                        <td className={wrClass}>{winrate(champion.wins, champion.games)} %</td>
                      </>
                    )}
                    {hasBlitzGames && <td className={nbClass}>{champion.blitz_games}</td>}
                    {hasBlitzGames && hasWinrate && <td className={nbClass}>{champion.total}</td>}
                    <td className={style.date}>{date(champion)}</td>
                    {hasKda && <td className={style.kda}>{kda(champion)}</td>}
                  </tr>
                );
              })}
        </tbody>
      </table>
    </div>
  );
};

export default StatsTable;
