import React, { useEffect, useState, useReducer } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { sendGetRequest } from 'src/utils/requests';

import GameCard from 'src/components/GameCard';
import { Game } from 'src/pages/Game/types';

import * as style from './style.scss';

interface Props {
  championSlug: string;
  type: 'soloq' | 'champions_queue' | 'official' | 'scrim';
}

import { reducer as paginationReducer } from 'src/components/Historical/Pagination/reducer';
import { initialState as initialPaginationState } from 'src/components/Historical/Pagination/types';

const ChampionGames: React.FC<Props> = ({ championSlug, type }) => {
  const [history, setHistory] = useState<Game[]>([]);

  const [paginationState, dispatchPagination] = useReducer(
    paginationReducer,
    initialPaginationState,
  );

  const getGames = (currentGames: Game[], page: number): void => {
    const url = type === 'soloq' ? `/champions/${championSlug}/history?page=${page}` : `/lolesports/champions/${championSlug}/games?page=${page}&type=${type}`;

    dispatchPagination({ type: 'SET_LOADING', payload: true });
    sendGetRequest(url).then(data => {
      dispatchPagination({ type: 'SET_LOADING', payload: false });
      setHistory(currentGames.concat(data));

      if (data.length === 0) {
        dispatchPagination({ type: 'SET_HAS_MORE', payload: false });
      }
    });
  };

  useEffect(() => {
    dispatchPagination({ type: 'SET_PAGE', payload: 0 });
    dispatchPagination({ type: 'SET_HAS_MORE', payload: true });
    getGames([], 0);
  }, [championSlug]);

  const loadMoreGames = (): void => {
    const newPage = paginationState.page + 1;
    dispatchPagination({ type: 'SET_PAGE', payload: newPage });
    getGames(history, newPage);
  };

  return (
    <div className={style.history}>
      <InfiniteScroll
        dataLength={history.length}
        next={loadMoreGames}
        hasMore={paginationState.hasMore}
        loader={<h4>Loading...</h4>}
      >
        {history.length > 0 &&
          history.map((game: Game) => <GameCard game={game} key={game.id} display_player={true} />)}
      </InfiniteScroll>
    </div>
  );
};

export default ChampionGames;
