import { Role } from 'src/utils/helper';
import { Champion } from 'src/pages/Player/types';

interface ScrimRole {
  champions: Champion[];
  number_of_champions: number;
  role: Role;
  games: number;
  wins: number;
}

interface ScrimDuo {
  champion1: string;
  champion1_slug: string;
  champion2: string;
  champion2_slug: string;
  wins: number;
  losses: number;
  date: Date;
  blitz_games: number;
  total: number;
  games: number;
}

interface FormState {
  teams: {id: number, name: string, acronym: string}[];
  champions: {id: number, name: string, slug: string}[];
  vs: any[];
  side: any[];
  role: ScrimRole[];
  vsrole: ScrimRole[];
  duos: ScrimDuo[];
  vs_duos: ScrimDuo[];
  startDate: Date | null;
  endDate: Date | null;
  filteredTeams: any[];
  filteredChampions: any[];
  scrim_type: string;
}

const initialState: FormState = {
  champions: [],
  teams: [],
  vs: [],
  side: [],
  role: [],
  vsrole: [],
  duos: [],
  vs_duos: [],
  startDate: new Date(Date.now() - 12096e5),
  endDate: new Date(),
  filteredTeams: [],
  filteredChampions: [],
  scrim_type: 'normal',
};

export { FormState, ScrimRole, ScrimDuo, initialState };
