import React, { useEffect, useReducer, useState } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import DatePicker from 'react-datepicker';

import Icon from 'src/components/Icon';
import arrow from 'src/icons/arrow.svg';
import calendar from 'src/icons/calendar.svg';
import { sendGetRequest } from 'src/utils/requests';

import { reducer } from './reducer';
import * as style from './style.scss';
import { FilterObject, initialState, FormState } from './types';

interface Props {
  onApply: (formState: FormState) => void;
  pageType?: string;
}

const HistoricalSidebar: React.FC<Props> = ({ onApply, pageType }) => {
  const [formState, dispatch] = useReducer(reducer, initialState);

  const [allChampions, setAllChampions] = useState<FilterObject[]>([]);
  const [allLeagues, setAllLeagues] = useState<FilterObject[]>([]);
  const [allPatches, setAllPatches] = useState<FilterObject[]>([]);
  const [allTeams, setAllTeams] = useState<FilterObject[]>([]);

  const allRoles = [{ id: 'top' }, { id: 'jun' }, { id: 'mid' }, { id: 'adc' }, { id: 'sup' }];

  const championsMS = React.createRef<any>();
  const leaguesMS = React.createRef<any>();
  const patchesMS = React.createRef<any>();
  const rolesMS = React.createRef<any>();
  const teamsMS = React.createRef<any>();

  useEffect(() => {
    sendGetRequest('/champions').then(data => setAllChampions(data));
    sendGetRequest('/patches/list').then(data => setAllPatches(data));
    sendGetRequest('/teams').then(data => setAllTeams(data));
    sendGetRequest('/tournaments').then(data => {
      const tags = data
        .map((tag: any) => ({ id: tag.id, name: tag.league_name }));
      setAllLeagues(tags);
    });
  }, []);

  const setDate = (date: Date | null, type: 'SET_START_DATE' | 'SET_END_DATE'): void => {
    // it sets itself at like 10pm day before, but I'm handling that stuff backend
    if (date) date.setHours(date.getHours() + 3);
    dispatch({ type, payload: date });
  };

  const onApplyClick = (): void => {
    onApply(formState);
  };

  const onResetClick = (): void => {
    dispatch({ type: 'SET_CHAMPIONS', payload: initialState.champions });
    dispatch({ type: 'SET_LEAGUES', payload: initialState.leagues });
    dispatch({ type: 'SET_PATCHES', payload: initialState.patches });
    dispatch({ type: 'SET_ROLES', payload: initialState.roles });
    dispatch({ type: 'SET_TEAMS', payload: initialState.teams });
    dispatch({ type: 'SET_START_DATE', payload: initialState.start_date });
    dispatch({ type: 'SET_END_DATE', payload: initialState.end_date });
    dispatch({ type: 'SET_HAS_PROVIEW', payload: initialState.has_proview });

    championsMS.current?.resetSelectedValues();
    leaguesMS.current?.resetSelectedValues();
    patchesMS.current?.resetSelectedValues();
    rolesMS.current?.resetSelectedValues();
    teamsMS.current?.resetSelectedValues();

    onApply(initialState);
  };

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerText}>FILTERS</div>
      </div>
      <div className={style.body}>
        <div className={style.filterBlock}>
          <div>{pageType?.includes('champions_queue') ? 'Region' : 'League'}</div>
          <div className={style.multiselect}>
            <Multiselect
              options={allLeagues}
              onSelect={(list: FilterObject[]): void =>
                dispatch({ type: 'SET_LEAGUES', payload: list })
              }
              onRemove={(list: FilterObject[]): void =>
                dispatch({ type: 'SET_LEAGUES', payload: list })
              }
              displayValue="name"
              ref={leaguesMS}
            />
          </div>
        </div>
        {(pageType === 'games' || pageType === 'champions') && (
          <div className={style.filterBlock}>
            <div>Patch</div>
            <div className={style.multiselect}>
              <Multiselect
                options={allPatches}
                onSelect={(list: FilterObject[]): void =>
                  dispatch({ type: 'SET_PATCHES', payload: list })
                }
                onRemove={(list: FilterObject[]): void =>
                  dispatch({ type: 'SET_PATCHES', payload: list })
                }
                displayValue="name"
                ref={patchesMS}
              />
            </div>
          </div>
        )}
        {pageType === 'games' && (
          <div className={style.filterBlock}>
            <div>Date</div>
            <div className={style.calendar}>
              <Icon className={style.calendarIcon} href={calendar} />
              <DatePicker
                selected={formState.start_date}
                onChange={(d: Date): void => setDate(d, 'SET_START_DATE')}
                selectsStart
                startDate={formState.start_date}
                endDate={formState.end_date}
                className={style.datepicker}
                dateFormat="dd/MM/yyyy"
              />
              <Icon className={style.icon} href={arrow} />
              <DatePicker
                selected={formState.end_date}
                onChange={(d: Date): void => setDate(d, 'SET_END_DATE')}
                selectsEnd
                startDate={formState.start_date}
                endDate={formState.end_date}
                className={style.datepicker}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
        )}
        {(pageType === 'games' || pageType === 'champions_queue_0') && (
          <div className={style.filterBlock}>
            <div>Champion</div>
            <div className={style.multiselect}>
              <Multiselect
                options={allChampions}
                onSelect={(list: FilterObject[]): void =>
                  dispatch({ type: 'SET_CHAMPIONS', payload: list })
                }
                onRemove={(list: FilterObject[]): void =>
                  dispatch({ type: 'SET_CHAMPIONS', payload: list })
                }
                displayValue="name"
                ref={championsMS}
              />
            </div>
          </div>
        )}
        {pageType === 'players' && (
          <div className={style.filterBlock}>
            <div>Role</div>
            <div className={style.multiselect}>
              <Multiselect
                options={allRoles}
                onSelect={(list: FilterObject[]): void =>
                  dispatch({ type: 'SET_ROLES', payload: list })
                }
                onRemove={(list: FilterObject[]): void =>
                  dispatch({ type: 'SET_ROLES', payload: list })
                }
                displayValue="id"
                ref={rolesMS}
              />
            </div>
          </div>
        )}
        {!pageType?.includes('champions_queue') && (
          <div className={style.filterBlock}>
            <div>Team</div>
            <div className={style.multiselect}>
              <Multiselect
                options={allTeams}
                onSelect={(list: FilterObject[]): void =>
                  dispatch({ type: 'SET_TEAMS', payload: list })
                }
                onRemove={(list: FilterObject[]): void =>
                  dispatch({ type: 'SET_TEAMS', payload: list })
                }
                displayValue="name"
                ref={teamsMS}
              />
            </div>
          </div>
        )}
        {/* {pageType === 'games' && (
          <div className={style.filterBlock}>
            <input
              type="checkbox"
              name="hasProview"
              checked={formState.has_proview}
              onChange={(): void =>
                dispatch({ type: 'SET_HAS_PROVIEW', payload: !formState.has_proview })
              }
            />
            <label htmlFor="hasProview">Has proview</label>
          </div>
        )} */}
        <div className={style.buttonsBlock}>
          <button className={style.applyButton} onClick={onApplyClick}>
            Apply filters
          </button>
          <button className={style.resetButton} onClick={onResetClick}>
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default HistoricalSidebar;
