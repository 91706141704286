import React from 'react';
import { sendGetRequest } from 'src/utils/requests';

import { cx as classnames } from 'emotion';
import * as style from './style.scss';

import Icon from 'src/components/Icon';
import up from 'src/icons/up.svg';
import down from 'src/icons/down.svg';

interface Winrate {
  name: string;
  previous: number;
  current: number;
}

type SortableField = 'previous' | 'current' | 'diff';

const Patch: React.FC<{}> = () => {
  const [winrates, setWiinrates] = React.useState<Winrate[]>([]);

  const [sortedField, setSortedField] = React.useState<SortableField>('current');
  const [sortOrder, setSortOrder] = React.useState<number>(1);

  const [patch1, setPatch1] = React.useState<string>('');
  const [patch2, setPatch2] = React.useState<string>('');

  React.useEffect(() => {
    const url = `/patches`;

    sendGetRequest(url).then(response => {
      setWiinrates(response.data);
      setPatch1(response.previous_patch);
      setPatch2(response.current_patch);
    });
  }, []);

  const handleSort = (field: SortableField): void => {
    if (field === sortedField) {
      setSortOrder(-sortOrder);
      return;
    }
    setSortedField(field);
    setSortOrder(1);
  };

  const winrate = (raw: number): number => {
    return Math.round(raw * 10000) / 100;
  };

  const wrColors = (value: number, type: 'previous' | 'current'): string => {
    const max = Math.max(
      ...winrates.map(function(winrate: Winrate) {
        return winrate[type];
      }),
    );
    const min = Math.min(
      ...winrates.map(function(winrate: Winrate) {
        return winrate[type];
      }),
    );
    const ratio = ((value - min) * 100) / (max - min);

    if (ratio <= 10) {
      return style.wr0;
    }
    if (ratio <= 20) {
      return style.wr1;
    }
    if (ratio <= 30) {
      return style.wr2;
    }
    if (ratio <= 40) {
      return style.wr3;
    }
    if (ratio <= 50) {
      return style.wr4;
    }
    if (ratio <= 60) {
      return style.wr5;
    }
    if (ratio <= 70) {
      return style.wr6;
    }
    if (ratio <= 80) {
      return style.wr7;
    }
    if (ratio <= 90) {
      return style.wr8;
    }
    return style.wr9;
  };

  const nbColors = (value: number): string => {
    const max = Math.max(
      ...winrates.map(function(winrate: Winrate) {
        return winrate.current - winrate.previous;
      }),
    );
    const min = Math.min(
      ...winrates.map(function(winrate: Winrate) {
        return winrate.current - winrate.previous;
      }),
    );
    const ratio = ((value - min) * 100) / (max - min);

    if (ratio <= 10) {
      return style.nb0;
    }
    if (ratio <= 20) {
      return style.nb1;
    }
    if (ratio <= 30) {
      return style.nb2;
    }
    if (ratio <= 40) {
      return style.nb3;
    }
    if (ratio <= 50) {
      return style.nb4;
    }
    if (ratio <= 60) {
      return style.nb5;
    }
    if (ratio <= 70) {
      return style.nb6;
    }
    if (ratio <= 80) {
      return style.nb7;
    }
    if (ratio <= 90) {
      return style.nb8;
    }
    return style.nb9;
  };

  return (
    <div className={style.container}>
      <div className={style.title}>Patch Winrates</div>
      <div>Plat + - NA / EU / KR</div>

      <table>
        <thead>
          <tr>
            <th className={style.champion}>Champion</th>
            <th className={style.column}>
              <div className={style.sortButton} onClick={(): void => handleSort('previous')}>
                {sortedField === 'previous' ? (
                  sortOrder === 1 ? (
                    <Icon className={style.icon} href={down} />
                  ) : (
                    <Icon className={style.icon} href={up} />
                  )
                ) : (
                  <div className={style.icon} />
                )}
                {patch1} Win Rate
              </div>
            </th>
            <th className={style.column}>
              <div className={style.sortButton} onClick={() => handleSort('current')}>
                {sortedField === 'current' ? (
                  sortOrder === 1 ? (
                    <Icon className={style.icon} href={down} />
                  ) : (
                    <Icon className={style.icon} href={up} />
                  )
                ) : (
                  <div className={style.icon} />
                )}
                {patch2} Win Rate
              </div>
            </th>
            <th className={style.column}>
              <div className={style.sortButton} onClick={() => handleSort('diff')}>
                {sortedField === 'diff' ? (
                  sortOrder === 1 ? (
                    <Icon className={style.icon} href={down} />
                  ) : (
                    <Icon className={style.icon} href={up} />
                  )
                ) : (
                  <div className={style.icon} />
                )}
                Win Rate delta
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {winrates
            .sort((a: Winrate, b: Winrate) => {
              if (sortedField === 'diff') {
                return sortOrder * (b.current - b.previous - (a.current - a.previous));
              }
              return sortOrder * (b[sortedField] - a[sortedField]);
            })
            .map(champion => {
              const previousClass = classnames(
                style.column,
                wrColors(champion.previous, 'previous'),
              );
              const currentClass = classnames(style.column, wrColors(champion.current, 'current'));
              const diffClass = classnames(
                style.column,
                nbColors(champion.current - champion.previous),
              );

              return (
                <tr key={champion.name}>
                  <td className={style.champion}>{champion.name}</td>
                  <td className={previousClass}>{winrate(champion.previous)} %</td>
                  <td className={currentClass}>{winrate(champion.current)} %</td>
                  <td className={diffClass}>{winrate(champion.current - champion.previous)} %</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default Patch;
