interface QueueTotals {
  patch: string;
  winrate: number;
  presence: number;
  previous_patch_winrate?: number;
}

interface Totals {
  champion: string | null;
  soloq: QueueTotals | null;
  champions_queue: QueueTotals | null;
}

const initialState: Totals = {
  champion: null,
  soloq: null,
  champions_queue: null,
};

export { initialState, Totals };
