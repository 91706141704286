import React, { useEffect, useState } from 'react';

import { Game, GameEvent, Snapshot, SnapshotPlayer } from '../../types';

import * as style from './style.scss';
import { sendGetRequest } from 'src/utils/requests';
import {
  championImageUrl,
  formatGameTime,
  formatSpellName,
  summonerSpellUrl,
} from 'src/utils/helper';
import Minimap from 'src/components/Minimap';
import EventsList from './EventsList';

interface Props {
  game: Game;
}

const HistoricalGameTimeline: React.FC<Props> = ({ game }) => {
  const baseSnapshot: Snapshot = {
    game_time: -1,
    blue_team: [],
    blue_players: [],
    red_team: [],
    red_players: [],
  };
  const [snapshotId, setSnapshotId] = useState<number>(-1);
  const [snapshot, setSnapshot] = useState<Snapshot>(baseSnapshot);
  const [events, setEvents] = useState<any[]>([]);

  useEffect(() => {
    sendGetRequest(`/lolesports/events/${game.id}`).then(data => setEvents(data));
  }, [game.id]);

  useEffect(() => {
    if (snapshotId === -1) {
      return;
    }
    sendGetRequest(`/grid/game_snaphots/${snapshotId}`).then(data => setSnapshot(data));
  }, [snapshotId]);

  const getHpBarStyle = () => {
    return {
      width: '61px',
      height: '10px',
      backgroundColor: '#dddddd',
      borderRadius: '5px',
      marginBottom: '5px',
      overflow: 'hidden',
    };
  };

  const onSelectEvent = (event: GameEvent) => {
    setSnapshotId(event.game_snapshot_id);
  };

  const shiftTimer = (shift: number) => {
    const gameTime = snapshot.game_time + shift;
    sendGetRequest(`/grid/game_snaphots/${game.id}/by-time/${gameTime}`).then(data =>
      setSnapshotId(data),
    );
  };

  const renderSummonerSpell = (spell: string, cooldown: number) => {
    return (
      <div key={`${spell}-${cooldown}`} className={style.summonerSpell}>
        <img
          src={summonerSpellUrl(spell, game.version)}
          alt={`Summoner Spell ${formatSpellName(spell)}`}
          style={{
            width: '20px',
            height: '20px',
            marginRight: '5px',
            opacity: cooldown > 0 ? 0.5 : 1,
          }}
        />
        {cooldown > 0 && <div className={style.spellCooldown}>{cooldown.toFixed(0)}s</div>}
      </div>
    );
  };

  const renderTeamPlayers = (players: SnapshotPlayer[], side: 'blue' | 'red') => {
    return players.map((player, index) => (
      <div
        key={index}
        className={style.playerInfo}
        style={{ alignItems: side === 'blue' ? 'flex-start' : 'flex-end' }}
      >
        <div>
          <img
            src={championImageUrl(player.champion_slug)}
            alt={`Champion ${player.champion}`}
            className={style.championImage}
            style={{ opacity: player.hp > 0 ? 1 : 0.5 }}
          />
          <span>Lvl {player.level}</span>
        </div>
        <div>
          <div style={getHpBarStyle()}>
            <div
              style={{
                width: `${(player.hp / player.max_hp) * 61}px`,
                height: '100%',
                backgroundColor: 'green',
                borderRadius: '5px',
              }}
            />
          </div>
          <div>
            {renderSummonerSpell(player.spell_1_name, player.spell_1_cooldown)}
            {renderSummonerSpell(player.spell_2_name, player.spell_2_cooldown)}
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className={style.timelineContainer}>
      <div className={style.timelineHeader}>
        {snapshot.game_time != -1 && (
          <>
          <button className={style.fastButton} onClick={() => shiftTimer(-30)}>-30sec</button>
          <button className={style.fastButton} onClick={() => shiftTimer(-5)}>-5sec</button>
          <button className={style.fastButton} onClick={() => shiftTimer(-1)}>-1sec</button>
          </>
        )}
        <div>Timer: {snapshot.game_time != -1 && formatGameTime(snapshot.game_time)}</div>
        {snapshot.game_time != -1 && (
          <>
          <button className={style.fastButton} onClick={() => shiftTimer(1)}>+1sec</button>
          <button className={style.fastButton} onClick={() => shiftTimer(5)}>+5sec</button>
          <button className={style.fastButton} onClick={() => shiftTimer(30)}>+30sec</button>
          </>
        )}
      </div>
      <div className={style.explorerContainer}>
        {snapshot.game_time === -1 || event === undefined ? (
          <div className={style.snapshotContainer}>select an event first</div>
        ) : (
          <div className={style.snapshotContainer}>
            <div>{renderTeamPlayers(snapshot.blue_players, 'blue')}</div>
            <div className={style.mapContainer}>
              <Minimap
                blue_players={snapshot.blue_players.filter(player => player.is_alive)}
                red_players={snapshot.red_players.filter(player => player.is_alive)}
                events={events.filter(event => event.game_time === snapshot.game_time)}
              />
            </div>

            <div>{renderTeamPlayers(snapshot.red_players, 'red')}</div>
          </div>
        )}
        <div>
          <EventsList events={events} onSelect={onSelectEvent} gameTime={snapshot.game_time} />
        </div>
      </div>
    </div>
  );
};

export default HistoricalGameTimeline;
