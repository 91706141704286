import React, { useEffect, useReducer, useState } from 'react';
import HistoricalSidebar from 'src/components/Historical/Sidebar';
import { FormState } from 'src/components/Historical/Sidebar/types';
import { sendGetRequest } from 'src/utils/requests';
import { Player } from '../types';

import { reducer } from 'src/components/Historical/Pagination/reducer';
import { initialState } from 'src/components/Historical/Pagination/types';
import { championImageUrl } from 'src/utils/helper';

import InfiniteScroll from 'react-infinite-scroll-component';

import * as style from './style.scss';
import { getTournamentTag } from '../helper';

const HistoricalPlayers: React.FC = () => {
  const [players, setPlayers] = useState<Player[]>([]);
  // needed to keep filters on scroll, could probs be better handled
  const [urlParams, setUrlParams] = useState<string>('');
  const [paginationState, dispatch] = useReducer(reducer, initialState);

  const getPlayers = (currentPlayers: Player[], page: number, url_params: string): void => {
    const url = `/bayes/players?page=${page}&${url_params}`;
    dispatch({ type: 'SET_LOADING', payload: true });

    sendGetRequest(url).then(data => {
      dispatch({ type: 'SET_LOADING', payload: false });
      setPlayers(currentPlayers.concat(data));

      if (data.length === 0) {
        dispatch({ type: 'SET_HAS_MORE', payload: false });
      }
    });
  };

  useEffect(() => {
    getPlayers([], paginationState.page, '');
  }, []);

  const loadMorePlayers = (): void => {
    const newPage = paginationState.page + 1;
    dispatch({ type: 'SET_PAGE', payload: newPage });
    getPlayers(players, newPage, urlParams);
  };

  const applyFilters = (filters: FormState): void => {
    dispatch({ type: 'SET_PAGE', payload: 0 });
    dispatch({ type: 'SET_HAS_MORE', payload: true });

    const leagues = filters.leagues.map(league => league.id);
    const roles = filters.roles.map(role => role.id);
    const teams = filters.teams.map(team => team.id);

    const newUrlParams = `teams=${teams}&leagues=${leagues}&roles=${roles}`;
    setUrlParams(newUrlParams);
    getPlayers([], 0, newUrlParams);
  };

  return (
    <div className={style.container}>
      <HistoricalSidebar onApply={applyFilters} pageType="players" />

      <div className={style.table}>
        <div className={style.header}>
          <div className={style.headerText}>Player</div>
          <div className={style.headerText}>Team</div>
          <div className={style.headerText}>League</div>
          <div className={style.headerText}>Role</div>
          <div className={style.headerText}>Game</div>
          <div className={style.mostPlayedHeader}>Most Games</div>
          <div className={style.headerText}>KDA</div>
          <div className={style.headerText}>KP%</div>
          <div className={style.headerText}>CSPM</div>
          <div className={style.headerText}>DPM</div>
          <div className={style.headerLarge}>Soloq profile</div>
        </div>
        <div>
          <InfiniteScroll
            dataLength={players.length}
            next={loadMorePlayers}
            hasMore={paginationState.hasMore}
            loader={<h4>Loading...</h4>}
          >
            {players.map(player => {
              return (
                <div key={player.id} className={style.row}>
                  <div className={style.rowText}>{player.name}</div>
                  <div className={style.rowText}>{player.team_acronym}</div>
                  <div className={style.rowText}>{getTournamentTag(player.league)}</div>
                  <div className={style.rowText}>{player.role}</div>
                  <div className={style.rowText}>{player.games}</div>
                  <div className={style.mostPlayedBlock}>
                    {player.champions.map(champion => (
                      <div key={champion.slug} className={style.mostPlayed}>
                        <img className={style.icon} src={championImageUrl(champion.slug)}></img>
                        <div>({champion.count})</div>
                      </div>
                    ))}
                  </div>
                  <div className={style.rowText}>
                    {player.kda === 'perfect' ? 'perfect' : player.kda.toFixed(2)}
                  </div>
                  <div className={style.rowText}>{(player.kp * 100).toFixed(0)}%</div>
                  <div className={style.rowText}>{player.cspm.toFixed(2)}</div>
                  <div className={style.rowText}>{player.dpm.toFixed(2)}</div>
                  <div className={style.rowLink}>
                    <a href={`/players/${player.name}`}>link</a>
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default HistoricalPlayers;
