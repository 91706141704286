import React from 'react';
import * as style from './style.scss'
import { sendGetRequest } from 'src/utils/requests'

import { Champion } from '../Champion/types'
import { Link } from 'react-router-dom';
import { championImageUrl } from 'src/utils/helper';

const Champions: React.FC = () => {
  const [champions, setChampions] = React.useState<Champion[]>([]);


  React.useEffect(() => {
    const url = `/champions`
    sendGetRequest(url).then(data => setChampions(data));
  }, []);

  return <div className={style.container}>
    <div className={style.title}></div>

    <div className={style.list}>
      {
        champions.length > 0 &&
        champions.sort((a: Champion, b: Champion) => {
          if (a.name > b.name) {
            return 1;
          }
          if (b.name > a.name) {
            return -1;
          }
          return 0;
        }).map((champion: Champion) => {
          return (
            <Link to={`/champions/${champion.slug}`} key={champion.name} className={style.champion}>
              <img className={style.image} src={championImageUrl(champion.slug)} />
              <div >{champion.name}</div>
            </Link>
          )
        })
      }
    </div>
  </div>
};

export default Champions;