import React from 'react';
import LoadingBar from 'src/components/LoadingBar';
import { formatDate, imgUrl, sortByRole } from 'src/utils/helper';
import { FullTeam } from '../../types';

import { cx as classnames } from 'emotion';

import * as style from './style.scss';

interface Props {
  team: FullTeam;
}

const HistoricalTeamSummary: React.FC<Props> = ({ team }) => {
  const totalWinrate: number = (100 * (team.blue_wins + team.red_wins)) / team.total_games;
  const blueWinrate: number = (100 * team.blue_wins) / team.blue_total;
  const redWinrate: number = (100 * team.red_wins) / team.red_total;

  const length = (timestamp: number): string => {
    const minutes = Math.floor(timestamp / 60);
    let seconds = (timestamp - minutes * 60).toFixed(0);
    seconds = seconds.length < 2 ? '0' + seconds : seconds;
    return minutes + ':' + seconds;
  };

  return (
    <div className={style.container}>
      <div className={style.players}>
        <div className={style.playersTitle}>Players</div>
        <div>
          {sortByRole(team.players).map(player => (
            <div key={player.id} className={style.playerRow}>
              <div className={style.playerName}>{player.name}</div>
              <img className={style.roleImage} src={imgUrl(player.role)}></img>
            </div>
          ))}
        </div>
      </div>
      <div>
        <div className={style.winrates}>
          <div>
            <LoadingBar
              leftLabel={`Winrate - ${team.blue_wins + team.red_wins}W / ${team.blue_losses +
                team.red_losses}L`}
              rightLabel={totalWinrate.toFixed(0)+'%'}
              progressValue={totalWinrate}
              color="#27AE60"
            />
            {team.blue_total > 0 && (
              <LoadingBar
                leftLabel={`Blue side - ${team.blue_wins}W / ${team.blue_losses}L`}
                rightLabel={blueWinrate.toFixed(0)+'%'}
                progressValue={blueWinrate}
                color="#1F41F7"
              />
            )}
            {team.red_total > 0 && (
              <LoadingBar
                leftLabel={`Red side - ${team.red_wins}W / ${team.red_losses}L`}
                rightLabel={redWinrate.toFixed(0)+'%'}
                progressValue={redWinrate}
                color="#EB5757"
              />
            )}
          </div>
          <div className={style.avgGameLength}>
            Average game duration: {length(team.average_game_length)}
          </div>
        </div>
        <div className={style.objectives}>
          <div className={style.objectivesTitle}>OBJECTIVES %</div>
          <div className={style.objectivesBars}>
            <LoadingBar
              leftLabel="First Dragon"
              rightLabel={team.first_dragon_pct?.toFixed(0)+'%'}
              progressValue={team.first_dragon_pct}
              color="#1F41F7"
            />
              <LoadingBar
                leftLabel="First Herald"
                rightLabel={team.first_herald_pct?.toFixed(0)+'%'}
                progressValue={team.first_herald_pct}
                color="#1F41F7"
              />
            <LoadingBar
              leftLabel="Voidgrubs pre 10min"
              rightLabel={team.voidgrubs_pre_10_pct?.toFixed(1)+' /3'}
              progressValue={team.voidgrubs_pre_10_pct * 100 / 3}
              color="#1F41F7"
            />
            <LoadingBar
              leftLabel="Voidgrubs post 10min"
              rightLabel={team.voidgrubs_post_10_pct?.toFixed(1)+' /3'}
              progressValue={team.voidgrubs_post_10_pct * 100 / 3}
              color="#1F41F7"
            />
          </div>
        </div>
      </div>
      <div className={style.latestGames}>
        <div className={style.latestGamesTitle}>Latest games</div>
        {team.recent_games
          .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
          .map(game => (
            <div
              key={game.id}
              className={classnames(style.game, game.result ? style.gameWon : style.gameLost)}
            >
              <div className={style.gameDescription}>
                <div className={style.gameDate}>{formatDate(game.date)}</div>
                <div className={style.gamePatch}>
                  {game.patch.split('.')[0] + '.' + game.patch.split('.')[1]}
                </div>
                <img src={game.opponent_logo} className={style.gameIcon} />
                <div className={style.gameOpponent}>vs {game.opponent}</div>
              </div>
              <div>
                <a href={`/historical/games/${game.id}`} className={style.gameLink}>
                  view game
                </a>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default HistoricalTeamSummary;
