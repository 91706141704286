import React, { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';

import { sendGetRequest } from 'src/utils/requests';

import 'react-datepicker/dist/react-datepicker.css';

import * as style from './style.scss';
import { reducer } from './reducer';
import { initialState } from './types';
import TeamSoloqCounts from './SoloqCounts';
import ScrimDrafts from '../Scrims/ScrimDrafts';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import TeamProximity from './Proximity';

const Team: React.FC = () => {
  const { teamName } = useParams<{ teamName: string }>();
  const [formState, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const url = `/teams/${teamName}/find`;

    sendGetRequest(url).then(data => {
      dispatch({ type: 'SET_TEAM', payload: data });
    });
  }, [teamName]);

  return (
    <div>
      <div className={style.container}>
        <div className={style.title}>{teamName}</div>
        {formState.team && (
          <a href={`/historical/teams/${formState.team.id}`}>go to official games data</a>
        )}
      </div>

      <Tabs selectedTabClassName={style.selectedTab}>
        <TabList className={style.tabs}>
          <Tab key="soloq_draft" className={style.tab}>
            SoloQ and Drafts
          </Tab>
          <Tab key="proximity" className={style.tab}>
            Proximity
          </Tab>
        </TabList>

        <TabPanel key="soloq_drafts">
          <div className={style.container}>
            <TeamSoloqCounts teamName={teamName} />

            {formState.team && (
              <ScrimDrafts
                teamID={formState.team.id}
                allyTeam={formState.team.name}
                allyAcronym={formState.team.acronym}
                allyLogo={formState.team.logo}
                isOfficial="all"
              />
            )}
          </div>
        </TabPanel>

        <TabPanel key="proximity">
          {formState.team ? (
            <TeamProximity teamID={formState.team.id} gameType="official" />
          ) : (
            'loading...'
          )}
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Team;
