import { FormJob, FormState, RequestState } from './types';

const formatPatchState = (formState: FormState): RequestState => {
  const {
    id,
    profile,
    primary_roles,
    secondary_roles,
    ad_dmg,
    ap_dmg,
    multiple_dmg,
    true_dmg,
    jobs,
    old_jobs,
  } = formState;

  const roles: any = { Top: 1, Jungle: 2, Mid: 3, Bot: 4, Support: 5 };

  const pPrimaryRoles = primary_roles
    .map((a: any) => a['name'])
    .sort((a, b) => roles[a] - roles[b])
    .join(',');
  const pSecondaryRoles = secondary_roles
    .map((a: any) => a['name'])
    .sort((a, b) => roles[a] - roles[b])
    .join(',');

  const pJobs: FormJob[] = jobs.map(job => {
    return {
      ...job,
      // added if not found in old jobs
      added: !old_jobs.find(old_job => job.name == old_job.name),
    };
  });

  const oldJobs: FormJob[] = old_jobs
    .filter(old_job => {
      return !pJobs.find(new_job => new_job.name == old_job.name);
    })
    .map(old_job => {
      return {
        ...old_job,
        removed: true,
      };
    });

  const pAdDmg = ad_dmg === '0' ? null : parseInt(ad_dmg);
  const pApDmg = ap_dmg === '0' ? null : parseInt(ap_dmg);
  const pMultDmg = multiple_dmg === '0' ? null : parseInt(multiple_dmg);
  const pTrueDmg = true_dmg === '0' ? null : parseInt(true_dmg);

  return {
    id: id ? id : null,
    champion_name: profile,
    primary_roles: pPrimaryRoles,
    secondary_roles: pSecondaryRoles,
    jobs: pJobs.concat(oldJobs),
    ad_dmg: pAdDmg,
    ap_dmg: pApDmg,
    multiple_dmg: pMultDmg,
    true_dmg: pTrueDmg,
  };
};

const isSubmittable = (formState: FormState): boolean => {
  const { profile, primary_roles } = formState;

  if (profile === '') {
    return false;
  }

  if (primary_roles.length === 0) {
    return false;
  }

  return true;
};

export { formatPatchState, isSubmittable };
