interface PaginationState {
  page: number;
  hasMore: boolean;
  loading: boolean;
}

const initialState: PaginationState = {
  page: 0,
  hasMore: true,
  loading: false,
};

export { initialState, PaginationState };
