import React from 'react';
import { championImageUrl } from 'src/utils/helper';

import * as style from './style.scss';

interface Props {
  bans: { champion: string; slug: string; count: number }[];
  title: string;
}

const HistoricalBansTable: React.FC<Props> = ({ bans, title }) => {
  return (
    <div className={style.bans}>
      <div className={style.bansTitle}>{title}</div>
      <table>
        {bans.map(ban => (
          <tr key={ban.champion}>
            <td>
              <img src={championImageUrl(ban.slug)} className={style.championImage} />
            </td>
            <td>{ban.count}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default HistoricalBansTable;
