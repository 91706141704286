import React from 'react';

import * as style from './style.scss';
import { cx as classnames } from 'emotion';

import Icon from 'src/components/Icon';
import up from 'src/icons/up.svg';
import down from 'src/icons/down.svg';

import { ScrimDuo } from 'src/pages/Scrims/ScrimStats/types';

import { championImageUrl, formatDate } from 'src/utils/helper';

type SortableField = keyof ScrimDuo | 'winrate' | 'total' | 'games' | 'blitz_games';

interface Props {
  duos: ScrimDuo[];
  hasWinrate?: boolean;
  hasBlitzGames?: boolean;
  defaultSortedField?: string;
}

const DuosTable: React.FC<Props> = ({
  duos,
  hasWinrate = true,
  hasBlitzGames = false,

  defaultSortedField = 'games',
}) => {
  const [sortedField, setSortedField] = React.useState<SortableField>(
    defaultSortedField as SortableField,
  );
  const [sortOrder, setSortOrder] = React.useState<number>(1);

  const winrate = (wins: number, games: number): string | number => {
    if (games == 0) return '-';

    return Math.round((100 * wins) / games);
  };

  const wrColors = (value: number | string): string => {
    if (value == '-') return '';

    if (value <= 10) {
      return style.wr0;
    }
    if (value <= 20) {
      return style.wr1;
    }
    if (value <= 30) {
      return style.wr2;
    }
    if (value <= 40) {
      return style.wr3;
    }
    if (value <= 50) {
      return style.wr4;
    }
    if (value <= 60) {
      return style.wr5;
    }
    if (value <= 70) {
      return style.wr6;
    }
    if (value <= 80) {
      return style.wr7;
    }
    if (value <= 90) {
      return style.wr8;
    }
    return style.wr9;
  };

  const nbColors = (value: number): string => {
    const max = Math.max(
      ...duos.map(function(duo: ScrimDuo) {
        return duo.games;
      }),
    );
    const ratio = (value * 100) / max;

    if (ratio <= 10) {
      return style.nb0;
    }
    if (ratio <= 20) {
      return style.nb1;
    }
    if (ratio <= 30) {
      return style.nb2;
    }
    if (ratio <= 40) {
      return style.nb3;
    }
    if (ratio <= 50) {
      return style.nb4;
    }
    if (ratio <= 60) {
      return style.nb5;
    }
    if (ratio <= 70) {
      return style.nb6;
    }
    if (ratio <= 80) {
      return style.nb7;
    }
    if (ratio <= 90) {
      return style.nb8;
    }
    return style.nb9;
  };

  const handleSort = (field: SortableField): void => {
    if (field === sortedField) {
      setSortOrder(-sortOrder);
      return;
    }
    setSortedField(field);
    setSortOrder(1);
  };

  return (
    <div className={style.stats}>
      <table>
        <thead>
          <tr>
            <th className={style.img}></th>
            <th className={style.name}>Champion 1</th>
            <th className={style.img}></th>
            <th className={style.name}>Champion 2</th>

            {hasWinrate && (
              <>
                <th className={style.games}>
                  <div className={style.sortButton} onClick={(): void => handleSort('games')}>
                    {sortedField === 'games' ? (
                      sortOrder === 1 ? (
                        <Icon className={style.icon} href={down} />
                      ) : (
                        <Icon className={style.icon} href={up} />
                      )
                    ) : (
                      <div className={style.icon} />
                    )}
                    Games
                  </div>
                </th>

                <th className={style.winrate}>
                  <div className={style.sortButton} onClick={(): void => handleSort('winrate')}>
                    {sortedField === 'winrate' ? (
                      sortOrder === 1 ? (
                        <Icon className={style.icon} href={down} />
                      ) : (
                        <Icon className={style.icon} href={up} />
                      )
                    ) : (
                      <div className={style.icon} />
                    )}
                    Winrate
                  </div>
                </th>
              </>
            )}
            {hasBlitzGames && (
              <th className={style.games}>
                <div className={style.sortButton} onClick={(): void => handleSort('blitz_games')}>
                  {sortedField === 'blitz_games' ? (
                    sortOrder === 1 ? (
                      <Icon className={style.icon} href={down} />
                    ) : (
                      <Icon className={style.icon} href={up} />
                    )
                  ) : (
                    <div className={style.icon} />
                  )}
                  Blitz Games
                </div>
              </th>
            )}
            {hasBlitzGames && hasWinrate && (
              <th className={style.games}>
                <div className={style.sortButton} onClick={(): void => handleSort('total')}>
                  {sortedField === 'total' ? (
                    sortOrder === 1 ? (
                      <Icon className={style.icon} href={down} />
                    ) : (
                      <Icon className={style.icon} href={up} />
                    )
                  ) : (
                    <div className={style.icon} />
                  )}
                  Total
                </div>
              </th>
            )}
            <th className={style.date}>Date Played</th>
          </tr>
        </thead>
        <tbody className={style.championsList}>
          {duos.length > 0 &&
            duos
              .sort((a: ScrimDuo, b: ScrimDuo) => {
                let a1 = null;
                let b1 = null;
                if (sortedField === 'winrate') {
                  a1 = winrate(a.wins, a.games) as number;
                  b1 = winrate(b.wins, b.games) as number;
                } else {
                  a1 = a[sortedField] as number;
                  b1 = b[sortedField] as number;
                }

                if (a1 === b1) {
                  return +b.date - +a.date;
                }
                return sortOrder * (b1 - a1);
              })
              .map(duo => {
                const wrClass = classnames(style.winrate, wrColors(winrate(duo.wins, duo.games)));
                const nbClass = classnames(style.games, nbColors(duo.games));

                return (
                  <tr key={`${duo.champion1}.${duo.champion2}`}>
                    <td className={style.img}>
                      {' '}
                      <img
                        className={style.championImg}
                        src={championImageUrl(
                          duo.champion1_slug ? duo.champion1_slug : duo.champion1,
                        )}
                      />
                    </td>
                    <td className={style.name}>{duo.champion1}</td>
                    <td className={style.img}>
                      {' '}
                      <img
                        className={style.championImg}
                        src={championImageUrl(
                          duo.champion2_slug ? duo.champion2_slug : duo.champion2,
                        )}
                      />
                    </td>
                    <td className={style.name}>{duo.champion2}</td>
                    {hasWinrate && (
                      <>
                        <td className={nbClass}>{duo.games}</td>
                        <td className={wrClass}>{winrate(duo.wins, duo.games)} %</td>
                      </>
                    )}
                    {hasBlitzGames && <td className={nbClass}>{duo.blitz_games}</td>}
                    {hasBlitzGames && hasWinrate && <td className={nbClass}>{duo.total}</td>}
                    <td className={style.date}>{formatDate(duo.date)}</td>
                  </tr>
                );
              })}
        </tbody>
      </table>
    </div>
  );
};

export default DuosTable;
