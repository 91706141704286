import React, { useState } from 'react';
import * as style from './style.scss';
import { Player, Totals } from '../types';
import { sendGetRequest } from 'src/utils/requests';

import Icon from 'src/components/Icon';
import arrow from 'src/icons/arrow.svg';
import calendar from 'src/icons/calendar.svg';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import StatsTable from 'src/components/StatsTable';

interface Props {
  player: Player;
}

const initialTotal = {
  champions: [],
  games: null,
  number_of_champions: 0,
  wins: 0,
};

const StatsTotal: React.FC<Props> = ({ player }) => {
  const [total, setTotal] = useState<Totals>(initialTotal);

  const [startDate, setStartDate] = useState<Date | null>(new Date(Date.now() - 12096e5));
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  React.useEffect(() => {
    if (player && player.name) {
      const from = startDate ? 'from=' + startDate.toISOString() + '&' : '';
      const to = endDate ? 'to=' + endDate.toISOString() + '&' : '';

      const url = `/players/${player.name}/totals?${from}${to}`;

      sendGetRequest(url).then(data => setTotal(data));
    }
  }, [player, startDate, endDate]);

  const winrate = (wins: number, games: number | null) => {
    if (games === null) {
      return '';
    }
    return Math.round((100 * wins) / games);
  };

  const handleStartDate = (date: Date | null) => {
    if (date && endDate && date > endDate) {
      setStartDate(endDate);
      setEndDate(date);
      return;
    }
    setStartDate(date);
  };

  const handleEndDate = (date: Date | null) => {
    if (date && startDate && date < startDate) {
      setEndDate(startDate);
      setStartDate(date);
      return;
    }
    setEndDate(date);
  };

  return (
    <div className={style.container}>
      <div className={style.filters}>
        <div className={style.header}>
          <div className={style.title}>SoloQ Totals</div>
          <div className={style.calendar}>
            <Icon className={style.calendarIcon} href={calendar} />
            <DatePicker
              selected={startDate}
              onChange={date => handleStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              className={style.datepicker}
              dateFormat="dd/MM/yyyy"
            />
            <Icon className={style.icon} href={arrow} />
            <DatePicker
              selected={endDate}
              onChange={date => handleEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              className={style.datepicker}
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>
        {total.games && total.games > 0 && (
          <div>
            <div className={style.stat}>
              <div className={style.label}>Games played</div>
              <div className={style.total}>{total.games}</div>
            </div>
            <div className={style.stat}>
              <div className={style.label}>Champions played</div>
              <div className={style.total}>{total.number_of_champions}</div>
            </div>
            <div className={style.stat}>
              <div className={style.label}>Winrate</div>
              <div className={style.total}>{winrate(total.wins, total.games)} %</div>
            </div>
          </div>
        )}
      </div>

      {total.games && total.games > 0 ? (
        <StatsTable history={total} hasKda={true} />
      ) : total.games === null ? (
        <div>
          loading games... it may take a while when loading a time period for the first time...
        </div>
      ) : (
        <div>No data for the given timeframe</div>
      )}
    </div>
  );
};

export default StatsTotal;
