import React, { useEffect, useReducer, useState } from 'react';
import { reducer } from './reducer';
import { setInitialState, FormJob } from './types';
import Autocomplete from 'src/components/Autocomplete';
import { Champion } from 'src/pages/Champion/types';
import { sendGetRequest, sendPostRequest } from 'src/utils/requests';
import { Multiselect } from 'multiselect-react-dropdown';
import * as style from './style.scss';
import { formatPatchState, isSubmittable } from './helper';
import { Profile } from 'src/pages/Champions/Profiles/types';

interface Props {
  onCancel: () => void;
  onPatch: (data: any[]) => void;
  profile?: Profile;
}

const AddProfile: React.FC<Props> = ({ onPatch, onCancel, profile }) => {
  const [allChampions, setAllChampions] = useState<Champion[]>([]);
  const [allJobs, setAllJobs] = useState<FormJob[]>([]);
  const initialState = setInitialState(profile)
  const [formState, dispatch] = useReducer(reducer, initialState);

  const roles = [
    { name: 'Top' },
    { name: 'Jungle' },
    { name: 'Mid' },
    { name: 'Bottom' },
    { name: 'Support' },
  ];

  useEffect(() => {
    sendGetRequest('/champions/names').then(data => setAllChampions(data));
    sendGetRequest('/profiles/jobs').then(data => setAllJobs(data));
  }, []);

  const onSendProfile = () => {
    if (!isSubmittable(formState)) {
      return;
    }

    const payload = formatPatchState(formState);
    sendPostRequest('/profiles', payload).then(data => onPatch(data));
  };

  return (
    <div>
      <div className={style.container}>
        <div className={style.championInput}>
          <Autocomplete
            allSuggestions={allChampions}
            field="name"
            handleChange={(value: string) => dispatch({ type: 'SET_PROFILE', payload: value })}
            value={formState.profile}
          />
        </div>
        <div className={style.roles}>
          <Multiselect
            options={roles}
            displayValue="name"
            onSelect={(list: any[]) => dispatch({ type: 'SET_PRIMARY_ROLES', payload: list })}
            onRemove={(list: any[]) => dispatch({ type: 'SET_PRIMARY_ROLES', payload: list })}
            style={{
              optionContainer: { backgroundColor: '#1D2E3E' },
              inputField: { color: 'rgb(167, 167, 171)' },
            }}
            selectedValues={formState.primary_roles}
          />
        </div>
        <div className={style.roles}>
          <Multiselect
            options={roles}
            displayValue="name"
            onSelect={(list: any[]) => dispatch({ type: 'SET_SECONDARY_ROLES', payload: list })}
            onRemove={(list: any[]) => dispatch({ type: 'SET_SECONDARY_ROLES', payload: list })}
            style={{
              optionContainer: { backgroundColor: '#1D2E3E' },
              inputField: { color: 'rgb(167, 167, 171)' },
            }}
            selectedValues={formState.secondary_roles}
          />
        </div>
        <div className={style.damages}>
          <div>
            <div>AD</div>
            <select
              value={formState.ad_dmg}
              placeholder="AD"
              onChange={event =>
                dispatch({
                  type: 'SET_DMG_PROFILES',
                  payload: { type: 'AD', value: event.target.value },
                })
              }
            >
              <option value={1}>High</option>
              <option value={2}>Low</option>
              <option value={0}>None</option>
            </select>
          </div>
          <div>
            <div>AP</div>
            <select
              value={formState.ap_dmg}
              placeholder="AP"
              onChange={event =>
                dispatch({
                  type: 'SET_DMG_PROFILES',
                  payload: { type: 'AP', value: event.target.value },
                })
              }
            >
              <option value={1}>High</option>
              <option value={2}>Low</option>
              <option value={0}>None</option>
            </select>
          </div>
          <div>
            <div>Mult.</div>
            <select
              value={formState.multiple_dmg}
              placeholder="MULTIPLE"
              onChange={event =>
                dispatch({
                  type: 'SET_DMG_PROFILES',
                  payload: { type: 'MULTIPLE', value: event.target.value },
                })
              }
            >
              <option value={1}>High</option>
              <option value={2}>Low</option>
              <option value={0}>None</option>
            </select>
          </div>
          <div>
            <div>True</div>
            <select
              value={formState.true_dmg}
              placeholder="TRUE"
              onChange={event =>
                dispatch({
                  type: 'SET_DMG_PROFILES',
                  payload: { type: 'TRUE', value: event.target.value },
                })
              }
            >
              <option value={1}>High</option>
              <option value={2}>Low</option>
              <option value={0}>None</option>
            </select>
          </div>
        </div>
        <div className={style.jobs}>
          <Multiselect
            options={allJobs}
            displayValue="name"
            onSelect={(list: any[]) => dispatch({ type: 'SET_JOBS', payload: list })}
            onRemove={(list: any[]) => dispatch({ type: 'SET_JOBS', payload: list })}
            style={{
              optionContainer: { backgroundColor: '#1D2E3E' },
              inputField: { color: 'rgb(167, 167, 171)' },
            }}
            selectedValues={formState.jobs}
          />
        </div>
        <button
          className={isSubmittable(formState) ? style.okButton : style.koButton}
          onClick={onSendProfile}
        >
          OK
        </button>
        <button className={style.isAddProfile} onClick={onCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AddProfile;
