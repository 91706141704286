import React from 'react';
import * as style from './style.scss';
import { cx as classnames } from 'emotion';
import { Game, Item } from 'src/pages/Game/types';

import { championImageUrl, itemImageUrl } from 'src/utils/helper';
import { Link } from 'react-router-dom';
import { formatUrl } from 'src/utils/requests';

interface Props {
  game: Game;
  display_player?: boolean;
}

const GameCard: React.FC<Props> = ({ game, display_player = false }) => {
  const border = game.result ? style.victory : style.defeat;
  const itemSlot = game.result ? style.itemVictory : style.itemDefeat;

  const playerLink = game.name ? `/players/${game.name}` : `/accounts/${game.riot_name}-${game.riot_tag}`;

  const riotGameId = (): string => {
    // "https://matchhistory.euw.leagueoflegends.com/en/#match-details/EUW1/4562015163?tab=overview"
    const mhInfo = game.mh_link.split('match-details/')[1];
    const gamePart = mhInfo.split('/')[1];

    return gamePart.split('?')[0];
  };

  const getReplayUrl = (gameId: number): string => {
    return formatUrl(`/lolesports/scrim_replay_file_url/${gameId}`);
  };

  const kda = (): string => {
    if (game.deaths == 0) {
      return 'perfect';
    }
    return `${Math.round((100 * (game.kills + game.assists)) / game.deaths) / 100}`;
  };

  const per_minute = (value: number): string => {
    const pm_raw = (value * 60) / game.length;
    return pm_raw.toFixed(1);
  };

  const date = (): JSX.Element => {
    // 2020-04-13T22:04:58.0850
    const dateParts = game.date.split('T');
    const day = dateParts[0];
    // const hour = dateParts[1].substring(0, 5);
    return (
      <>
        <div>{day}</div>
      </>
    );
  };

  const length = (timestamp: number): string => {
    const minutes = Math.floor(timestamp / 60);
    let seconds = (timestamp - minutes * 60).toString();
    seconds = seconds.length < 2 ? '0' + seconds : seconds;
    return minutes + ':' + seconds + 's';
  };

  const game_type_style = (): string => {
    if (game.type === 'soloq') return style.soloq;
    if (game.type === 'scrim') return style.scrim;
    return style.official;
  };

  const items = (): JSX.Element => {
    return (
      <>
        {game.items
          .sort((a: Item, b: Item) => {
            return a.position - b.position;
          })
          .map((item: Item) => {
            return (
              <div className={classnames(style.item, itemSlot)} key={item.position}>
                {item.id && item.id != 0 ? (
                  <img className={style.item} src={itemImageUrl(game.patch, item.id)} />
                ) : (
                  ''
                )}
              </div>
            );
          })}
        <div className={classnames(style.item, itemSlot)} key={game.trinket.position}>
          {game.trinket.id && (
            <img className={style.item} src={itemImageUrl(game.patch, game.trinket.id)} />
          )}
        </div>
      </>
    );
  };

  return (
    <div key={game.id} className={classnames(border, style.container)}>
      {display_player && (
        <Link to={playerLink} className={style.playerInfo}>
          {game.name ? game.name : `${game.riot_name} #${game.riot_tag}`}
        </Link>
      )}
      <div className={style.gameInfo}>
        <div>{date()}</div>
        <div className={style.result}>{game.result ? 'Victory' : 'Defeat'}</div>
        <div>{length(game.length)}</div>
      </div>
      <img className={style.champion} src={championImageUrl(game.champion_slug)} />
      <div className={style.spells}>
        <img
          className={style.spell}
          src={`http://ddragon.leagueoflegends.com/cdn/${game.patch}.1/img/spell/${game.spell1}.png`}
        />
        <img
          className={style.spell}
          src={`http://ddragon.leagueoflegends.com/cdn/${game.patch}.1/img/spell/${game.spell2}.png`}
        />
      </div>
      <div className={style.runes}>
        {game.rune1 ? (
          <img
            className={style.rune}
            src={`https://opgg-static.akamaized.net/images/lol/perk/${game.rune1}.png`}
          />
        ) : (
          ''
        )}
        {game.rune2 ? (
          <img
            className={style.rune}
            src={`https://opgg-static.akamaized.net/images/lol/perkStyle/${game.rune2}.png`}
          />
        ) : (
          ''
        )}
      </div>
      <div className={classnames(style.gameType, game_type_style())}>{game.type.toUpperCase()}</div>
      <div className={style.kdaInfo}>
        <div className={style.detailed}>
          {game.kills} / {game.deaths} / {game.assists}
        </div>
        <div className={style.average}>{kda()} KDA</div>
      </div>

      <div className={style.statsInfo}>
        <div className={classnames(style.detailed, style.cs)}>
          CSM: {per_minute(game.minions_killed + game.neutral_minions_killed)}
        </div>
        <div className={classnames(style.detailed, style.gpm)}>
          GPM: {per_minute(game.total_gold)}
        </div>
        <div className={classnames(style.detailed, style.dpm)}>
          DPM: {per_minute(game.total_damage_dealt_champions)}
        </div>
      </div>

      <div className={style.items}>{items()}</div>

      {game.type === 'soloq' ? (
        <a
          className={style.link}
          href={`https://www.leagueofgraphs.com/match/euw/${riotGameId()}#participant${
            game.participant_id
          }`}
          target="_blank"
          rel="noopener noreferrer"
        >
          See more
        </a>
      ) : (
        <a
          className={style.link}
          href={`/historical/games/${game.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View scoreboard
        </a>
      )}
      {['scrim', 'official', 'champ queue'].includes(game.type) && (
        <a
          className={style.link}
          href={getReplayUrl(game.id)}
          target="_blank"
          rel="noopener noreferrer"
        >
          Replay File
        </a>
      )}
    </div>
  );
};

export default GameCard;
