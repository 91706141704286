import { Role } from 'src/utils/helper';
import { Game, GamePlayer } from './types';

export const formatGold = (gold: number): string => {
  const roundValue = Math.round(gold / 100);
  const cleanValue = roundValue / 10;
  return `${cleanValue}K`;
};

export const formatLength = (length: number): string => {
  const minutes = Math.floor(length / 60);
  const seconds = Math.floor((length - 60 * minutes));
  const strSeconds = seconds < 10 ? `0${seconds}` : seconds;
  return `${minutes}:${strSeconds}`;
};

export const formatCs = (player: GamePlayer): number => {
  return Math.round(player.minions_killed) + Math.round(player.neutral_minions_killed);
};

export const gameName = (game: Game): string => {
  return game.game_teams[0].team.acronym + ' vs ' + game.game_teams[1].team.acronym;
};

export const getPlayerByRole = (game_players: GamePlayer[], role: Role): GamePlayer => {
  return game_players.find(game_player => game_player.role === role) as GamePlayer;
};

export const getTournamentTag = (tags: string[]): string => {
  if (tags.length === 0) return ''

  return tags.reduce((a, b) => a.length <= b.length ? a : b)
}