import { assertNever } from 'src/utils/types';
import { PaginationState } from './types';

interface SetPage {
  type: 'SET_PAGE';
  payload: number;
}

interface SetLoading {
  type: 'SET_LOADING';
  payload: boolean;
}

interface SetHasMore {
  type: 'SET_HAS_MORE';
  payload: boolean;
}

type PaginationStateAction = SetHasMore | SetLoading | SetPage;

const reducer = (state: PaginationState, action: PaginationStateAction): PaginationState => {
  switch (action.type) {
    case 'SET_PAGE':
      return { ...state, page: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_HAS_MORE':
      return { ...state, hasMore: action.payload };
    default:
      assertNever(action);
  }
};

export { reducer };
