import React, { useEffect, useReducer, useState } from 'react';
import * as style from './style.scss';
import { sendGetRequest } from 'src/utils/requests';

import GameCard from 'src/components/GameCard';
import { Game } from 'src/pages/Game/types';

import { reducer as paginationReducer } from 'src/components/Historical/Pagination/reducer';
import { initialState as initialPaginationState } from 'src/components/Historical/Pagination/types';

import InfiniteScroll from 'react-infinite-scroll-component';

interface Props {
  id: number;
}

const AccountHistory: React.FC<Props> = ({ id }) => {
  const [history, setHistory] = useState<Game[]>([]);
  // storing riot id to reset game list on page change
  // const [prevRiotId, setPrevRiotId] = useState<number>(id);

  const [paginationState, dispatchPagination] = useReducer(
    paginationReducer,
    initialPaginationState,
  );

  const getGames = (currentGames: Game[], page: number): void => {
    const url = `/accounts/by-id/${id}/games?page=${page}`;

    dispatchPagination({ type: 'SET_LOADING', payload: true });
    sendGetRequest(url).then(data => {
      dispatchPagination({ type: 'SET_LOADING', payload: false });
      setHistory(currentGames.concat(data));

      if (data.length === 0) {
        dispatchPagination({ type: 'SET_HAS_MORE', payload: false });
      }
    });
  };

  useEffect(() => {
    setHistory([]);
    dispatchPagination({ type: 'SET_PAGE', payload: 0 });
    dispatchPagination({ type: 'SET_HAS_MORE', payload: true });
    getGames([], 0);
  }, [id]);

  const loadMoreGames = (): void => {
    const newPage = paginationState.page + 1;
    dispatchPagination({ type: 'SET_PAGE', payload: newPage });
    getGames(history, newPage);
  };

  return (
    <div className={style.container}>
      <div className={style.history}>
        <InfiniteScroll
          dataLength={history.length}
          next={loadMoreGames}
          hasMore={paginationState.hasMore}
          loader={<h4>Loading...</h4>}
        >
          {history.length > 0 &&
            history.map((game: Game) => <GameCard game={game} key={game.id} />)}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default AccountHistory;
