import React, { useEffect, useState } from 'react';
import Autocomplete from 'src/components/Autocomplete';
import { sendGetRequest, sendPostRequest } from 'src/utils/requests';
import * as style from './style.scss';

interface Props {
  isAddJob: () => void;
}

const AddJob: React.FC<Props> = ({isAddJob}) => {
  const [allCategories, setAllCategories] = useState<any>([]);

  const [jobName, setJobName] = useState<string>('');
  const [jobCategory, setJobCategory] = useState<string>('');
  const [jobLevel, setJobLevel] = useState<number>(1);

  const isSubmittable = () => {
    if (jobName === '' || jobCategory === '') {
      return false;
    }
    return true;
  };

  const onSendJob = () => {
    if (!isSubmittable()) {
      return;
    }

    const payload = {
      name: jobName,
      category: jobCategory,
      level: jobLevel,
    };
    sendPostRequest('/profiles/jobs', payload);
    isAddJob();
  };

  useEffect(() => {
    sendGetRequest('/profiles/categories').then(data => setAllCategories(data));
  }, []);

  return (
    <>
      <div className={style.title}> Add a Job</div>
      <div className={style.container}>
        <div>
          <input
            placeholder="Job Name"
            className={style.jobName}
            type="text"
            value={jobName}
            onChange={value => setJobName(value.target.value)}
          ></input>
        </div>
        <div className={style.categoryInput}>
          <Autocomplete
            placeholder="Category"
            allSuggestions={allCategories}
            field="name"
            handleChange={(value: string) => setJobCategory(value)}
            value={jobCategory}
          />
        </div>
        <div className={style.color}>
          <div>Color</div>
          <select
            value={jobLevel}
            placeholder="Color"
            onChange={event => setJobLevel(parseInt(event.target.value))}
          >
            <option value={1}>Green</option>
            <option value={2}>Yellow</option>
            <option value={3}>Orange</option>
            <option value={4}>Purple</option>
            <option value={5}>Red</option>
          </select>
        </div>
        <button className={isSubmittable() ? style.okButton : style.koButton} onClick={onSendJob}>
          OK
        </button>
      </div>
      <button className={style.isAddJob} onClick={isAddJob}>
        Cancel
      </button>
    </>
  );
};

export default AddJob;
