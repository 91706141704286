import React from 'react';
import * as style from './style.scss';
import { Account } from 'src/pages/Account/types';
import { Player } from 'src/pages/Player/types';

import Popup from 'reactjs-popup';
import AddAccount from 'src/components/Modal/AddAccount';
import AccountMedal from 'src/components/AccountMedal';
import PlayerHistory from 'src/components/PlayerHistory';

interface Props {
  player: Player;
  accounts: Account[];
  handleNewAccount: (accounts: Account[]) => void;
}

const Accounts: React.FC<Props> = ({ player, accounts, handleNewAccount }) => {
  const modalStyle = {
    width: '320px',
    borderRadius: '6px',
    borderColor: '#1D2E3E',
    backgroundColor: '#1D2E3E',
  };

  const tiers = [
    'challenger',
    'grandmaster',
    'master',
    'diamond',
    'emerald',
    'platinum',
    'gold',
    'silver',
    'bronze',
    'iron',
    null,
  ];

  const ranks = ['I', 'II', 'III', 'IV', null];

  const sortedAccounts = accounts.sort((a: Account, b: Account) => {
    if (a.tier != b.tier) {
      return tiers.indexOf(a.tier) - tiers.indexOf(b.tier);
    }

    if (a.rank != b.rank) {
      return ranks.indexOf(a.rank) - ranks.indexOf(b.rank);
    }

    return b.league_points - a.league_points;
  });

  return (
    <div className={style.container}>
      <div className={style.title}>Accounts</div>

      <div className={style.accountMedals}>
        {sortedAccounts.map((account: Account) => (
          <AccountMedal account={account} key={account.id} />
        ))}
      </div>
      <Popup modal trigger={<button>Add an account</button>} contentStyle={modalStyle}>
        {(close): JSX.Element => (
          <AddAccount close={close} player={player} onChange={handleNewAccount} />
        )}
      </Popup>

      <PlayerHistory id={player.id} />
    </div>
  );
};

export default Accounts;
