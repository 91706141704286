import React from 'react';
import Autosuggest, { SuggestionSelectedEventData } from 'react-autosuggest';
import { getSuggestions, getSuggestionValue } from './helper'
import * as style from './style.scss'
import { sendGetRequest } from 'src/utils/requests'

interface Props {
  allSuggestions: any[],
  field: string,
  handleChange: (value: string, type?: string) => void,
  value: string,
  placeholder?: string,
  multiSection?: boolean,
  handleEnter?: () => void
}

const Autocomplete: React.FC<Props> =
  ({
    allSuggestions,
    field,
    value,
    handleChange,
    placeholder,
    multiSection,
    handleEnter,
  }) => {

    const [suggestions, setSuggestions] = React.useState<string[]>([])

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    const onSuggestionsFetchRequested = ({ value }: { value: string }) => {
      if (multiSection) {
        const url = `/search/${value}`
        sendGetRequest(url).then(data => setSuggestions(data));
      } else {
        setSuggestions(getSuggestions(allSuggestions, field, value));
      }
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    const onSuggestionsClearRequested = () => {
      setSuggestions([]);
    };

    const renderSuggestion = (suggestion: string) => (
      <div>
        {suggestion}
      </div>
    );

    const onChange = (event: React.ChangeEvent<HTMLInputElement>, { newValue }: { newValue: string }) => {
      handleChange(newValue)
    }

    const onKeyUp = (event: React.KeyboardEvent) => {
      if (event.keyCode === 13 && value) {
        if (handleEnter != undefined) {
          handleEnter()
        }
      }
    }

    const inputProps = {
      value,
      onChange: onChange,
      placeholder: placeholder ? placeholder : '',
      onKeyUp: onKeyUp
    };

    const onSuggestionSelected = (event: React.FormEvent<any>,
      data: SuggestionSelectedEventData<any>) => {
      multiSection && data.sectionIndex != undefined ? handleChange(data.suggestion[field], suggestions[data.sectionIndex]) : handleChange(data.suggestion)
    }

    // When multiSection ON
    const renderSectionTitle = (section: any) => {
      return <strong>{section.title}</strong>;
    }

    const getSectionSuggestions = (section: any) => {
      return section.suggestions;
    }

    const getMultiSuggestionValue = (suggestion: any) => {
      return suggestion[field];
    }

    const renderMultiSuggestion = (suggestion: any) => (
      <div>
        {suggestion[field]}
      </div>
    );

    return (
      multiSection ? (
        <Autosuggest
          multiSection={true}
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getMultiSuggestionValue}
          renderSuggestion={renderMultiSuggestion}
          renderSectionTitle={renderSectionTitle}
          getSectionSuggestions={getSectionSuggestions}
          theme={{
            input: style.input,
            suggestionsContainerOpen: style.suggestionsContainerOpen,
            suggestionHighlighted: style.suggestionHighlighted,
            sectionTitle: style.sectionTitle,
            suggestion: style.suggestion,
          }}
          onSuggestionSelected={onSuggestionSelected}
          inputProps={inputProps}
          highlightFirstSuggestion={true}
        />
      ) : (
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            theme={{
              input: style.input,
              suggestionsContainerOpen: style.suggestionsContainerOpen,
              suggestionHighlighted: style.suggestionHighlighted,
            }}
            onSuggestionSelected={onSuggestionSelected}
          />
        )
    )
  }

export default Autocomplete;