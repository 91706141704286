import React, { useEffect, useState } from 'react';
import HistoricalBansTable from 'src/components/Historical/BansTable';
import HistoricalPicksTable from 'src/components/Historical/PicksTable';
import { roleName, sortByRole } from 'src/utils/helper';
import { sendGetRequest } from 'src/utils/requests';
import { PicksAndBans } from '../../types';

import * as style from './style.scss';

interface Props {
  teamId: number;
  leagues: number[];
}

const initialPicksAndBans = {
  bans_vs: [],
  bans_by: [],
  players: [],
};

const HistoricalTeamsPicksAndBans: React.FC<Props> = ({ teamId, leagues }) => {
  const [picksAndBans, setPicksAndBans] = useState<PicksAndBans>(initialPicksAndBans);

  useEffect(() => {
    sendGetRequest(`/lolesports/teams/${teamId}/picks_and_bans?leagues=${leagues}`).then(data =>
      setPicksAndBans(data),
    );
  }, [teamId, leagues.length]);

  return (
    <div className={style.container}>
      <HistoricalBansTable bans={picksAndBans.bans_by} title="Banned by" />
      <HistoricalBansTable bans={picksAndBans.bans_vs} title="Banned vs" />

      <div className={style.players}>
        {picksAndBans.players.length > 0 &&
          sortByRole(picksAndBans.players).map(player => (
            <div key={player.name} className={style.player}>
              <div className={style.playerHeader}>
                {roleName(player.role)} - {player.name}
              </div>
              <div>
                <HistoricalPicksTable picks={player.picks} />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default HistoricalTeamsPicksAndBans;
