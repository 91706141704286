import React, { ReactNode } from 'react';
import { cx as classnames } from 'emotion';

import * as style from '../style.scss';

import { Champion } from '../../types';

import { championImageUrl } from 'src/utils/helper';
import { assertNever } from 'src/utils/types';

import Icon from 'src/components/Icon';
import sort from 'src/icons/sort.svg';
import up from 'src/icons/up.svg';
import down from 'src/icons/down.svg';

type SortableField = 'champion' | 'presence' | 'winrate' | 'pick_rate' | 'ban_rate';

interface Props {
  champions: Champion[];
}

const HistoricalChampionsList: React.FC<Props> = ({ champions }) => {
  const [sortedField, setSortedField] = React.useState<SortableField>('presence');
  const [sortOrder, setSortOrder] = React.useState<number>(1);

  const winrate = (nbGames: number, nbWins: number): string => {
    if (nbGames === 0) {
      return '';
    }
    return ((nbWins * 100) / nbGames).toFixed(0) + '%';
  };

  const numWinrate = (nbGames: number, nbWins: number): number => {
    if (nbGames === 0) {
      return 0;
    }
    return ((nbWins * 100) / nbGames);
  }

  const handleSort = (field: SortableField): void => {
    if (field === sortedField) {
      setSortOrder(-sortOrder);
      return;
    }
    setSortedField(field);
    field === 'champion' ? setSortOrder(-1) : setSortOrder(1);
  };

  const sortedHeader = (
    label: string,
    rowSortedField: SortableField,
    rowStyle: string,
  ): ReactNode => {
    return (
      <div
        className={classnames(style.sortButton, rowStyle)}
        onClick={(): void => handleSort(rowSortedField)}
      >
        {sortedField === rowSortedField ? (
          sortOrder === 1 ? (
            <Icon className={style.icon} href={down} />
          ) : (
            <Icon className={style.icon} href={up} />
          )
        ) : (
          <Icon className={style.icon} href={sort} />
        )}
        <div>{label}</div>
      </div>
    );
  };

  const sortChampions = (c1: Champion, c2: Champion): number => {
    switch (sortedField) {
      case 'champion':
        return c2.name > c1.name ? sortOrder * 1 : sortOrder * -1;
      case 'ban_rate':
        return c2.ban_rate > c1.ban_rate ? sortOrder * 1 : sortOrder * -1;
      case 'pick_rate':
        return c2.pick_rate > c1.pick_rate ? sortOrder * 1 : sortOrder * -1;
      case 'presence':
        return c2.presence > c1.presence ? sortOrder * 1 : sortOrder * -1;
      case 'winrate':
        return numWinrate(c2.picks, c2.wins) > numWinrate(c1.picks, c1.wins)
          ? sortOrder * 1
          : sortOrder * -1;
      default:
        assertNever(sortedField);
    }
  };

  return (
    <div className={style.table}>
      <div className={style.header}>
        {sortedHeader('Champion', 'champion', style.headerLarge)}
        {sortedHeader('Presence', 'presence', style.headerText)}

        <div className={style.headerText}>Wins</div>
        {sortedHeader('Winrate', 'winrate', style.headerText)}
        <div className={style.headerText}>Picks</div>
        {sortedHeader('Pick rate', 'pick_rate', style.headerText)}
        <div className={style.headerText}>Picks blue</div>
        <div className={style.headerText}>Picks red</div>
        <div className={style.headerText}>Bans</div>
        {sortedHeader('Ban rate', 'ban_rate', style.headerText)}
        <div className={style.headerText}>Bans blue</div>
        <div className={style.headerText}>Bans red</div>
      </div>
      <div>
        {champions.sort(sortChampions).map(champion => {
          return (
            <div key={champion.id} className={style.row}>
              <div className={style.rowLarge}>
                <img className={style.championImage} src={championImageUrl(champion.slug)}></img>
                <div>{champion.name}</div>
              </div>
              <div className={style.rowText}>{(100 * champion.presence).toFixed(0)}%</div>
              <div className={style.rowText}>{champion.wins}</div>
              <div className={style.rowText}>{winrate(champion.picks, champion.wins)}</div>
              <div className={style.rowText}>{champion.picks}</div>
              <div className={style.rowText}>{(100 * champion.pick_rate).toFixed(0)}%</div>
              <div className={classnames(style.rowText, style.blue)}>{champion.blue_picks}</div>
              <div className={classnames(style.rowText, style.red)}>{champion.red_picks}</div>
              <div className={style.rowText}>{champion.bans}</div>
              <div className={style.rowText}>{(100 * champion.ban_rate).toFixed(0)}%</div>
              <div className={classnames(style.rowText, style.blue)}>{champion.blue_bans}</div>
              <div className={classnames(style.rowText, style.red)}>{champion.red_bans}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HistoricalChampionsList;
