import React from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { cx as classnames } from 'emotion';

import * as style from './style.scss';
import SearchBar from '../SearchBar';

const Header: React.FC = () => {
  const route = useLocation();
  const isHistorical = route.pathname.includes('historical');

  const gamesPath = useRouteMatch('/historical/games');
  const championsQueuePath = useRouteMatch('/historical/champions_queue_games');
  const playersPath = useRouteMatch('/historical/players');
  const teamsPath = useRouteMatch('/historical/teams');
  const championsPath = useRouteMatch('/historical/champions');

  return (
    <div className={isHistorical ? style.historicalHeader : style.header}>
      <img
        className={style.logo}
        src="https://static.wikia.nocookie.net/lolesports_gamepedia_en/images/3/3a/BK_ROG_Esportslogo_square.png"
      />
      {isHistorical ? (
        <div className={style.nav}>
          <div>
            <p className={style.name}>BK ROG</p>
            <p className={style.subname}>Historical data</p>
          </div>
          <a href="/" className={style.navText}>
            Home
          </a>
          <a
            href="/historical/teams"
            className={classnames(style.navText, teamsPath ? style.active : '')}
          >
            Teams
          </a>
          <a
            href="/historical/players"
            className={classnames(style.navText, playersPath ? style.active : '')}
          >
            Players
          </a>
          <a
            href="/historical/games"
            className={classnames(style.navText, gamesPath ? style.active : '')}
          >
            Games
          </a>
          {/* <a
            href="/historical/champions_queue_games"
            className={classnames(style.navText, championsQueuePath ? style.active : '')}
          >
            Champions Queue
          </a> */}
          <a
            href="/historical/champions"
            className={classnames(style.navText, championsPath ? style.active : '')}
          >
            Champions
          </a>
        </div>
      ) : (
        <p className={style.name}>BK ROG</p>
      )}
      <SearchBar />
    </div>
  );
};

export default Header;
