import React, { useEffect, useState, useReducer } from 'react';
import * as style from './style.scss';

import ScrimCard from 'src/components/ScrimCard';
import ScrimTable from 'src/components/ScrimTable';
import { ScrimGame } from './types';
import { sendGetRequest } from 'src/utils/requests';
import { Multiselect } from 'multiselect-react-dropdown';
import { reducer } from './reducer';
import { initialState } from './types';

import { reducer as paginationReducer } from 'src/components/Historical/Pagination/reducer';
import { initialState as initialPaginationState } from 'src/components/Historical/Pagination/types';

import InfiniteScroll from 'react-infinite-scroll-component';
import RadioButton from 'src/components/RadioButton';

interface Props {
  is_blitz?: boolean;
  is_main?: boolean;
}

const Scrims: React.FC<Props> = ({ is_blitz = false, is_main = true }) => {
  const [history, setHistory] = useState<ScrimGame[]>([]);
  const [formState, dispatch] = useReducer(reducer, initialState);

  const [sides, setSides] = useState<('' | 'blue' | 'red')[]>(['blue', 'red']);
  const [results, setResults] = useState<(true | false)[]>([true, false]);

  const [displayType, setDisplayType] = useState<string>('card');
  const [displayColor, setDisplayColor] = useState<string>('side');
  const [displaySide, setDisplaySide] = useState<string>('side');

  const [champions, setChampions] = useState([]);

  // needed to keep filters on scroll, could probs be better handled
  // const [urlParams, setUrlParams] = useState<string>('');
  const [paginationState, dispatchPagination] = useReducer(
    paginationReducer,
    initialPaginationState,
  );

  const getGames = (currentGames: ScrimGame[], page: number): void => {
    const teams =
      formState.filteredTeams.length === 0
        ? ''
        : 'teams=' + formState.filteredTeams.map(e => e.name).join(',') + '&';

    const allyChampions =
      formState.filteredChampions.length === 0
        ? ''
        : 'champions=' + formState.filteredChampions.map((e: any) => e.name).join(',') + '&';

    const url = is_blitz
      ? `/scrims/blitz?${teams}&page=${page}&is_main=${is_main}`
      : `/scrims?${teams}${allyChampions}&page=${page}&is_main=${is_main}`;

    dispatchPagination({ type: 'SET_LOADING', payload: true });
    sendGetRequest(url).then(data => {
      dispatchPagination({ type: 'SET_LOADING', payload: false });
      setHistory(currentGames.concat(data));

      if (data.length === 0) {
        dispatchPagination({ type: 'SET_HAS_MORE', payload: false });
      }
    });
  };

  useEffect(() => {
    dispatchPagination({ type: 'SET_PAGE', payload: 0 });
    dispatchPagination({ type: 'SET_HAS_MORE', payload: true });
    getGames([], 0);
  }, [formState.filteredTeams.length, formState.filteredChampions.length]);

  useEffect(() => {
    const url = `/scrims/teams`;
    sendGetRequest(url).then(data => {
      dispatch({
        type: 'SET_TEAMS',
        payload: data.map((t: any) => ({
          id: t.id,
          name: t.name,
          acronym: t.acronym,
          full_name: `${t.name} - ${t.acronym}`,
        })),
      });
    });
  }, []);

  useEffect(() => {
    const url = '/champions';
    sendGetRequest(url).then(data => setChampions(data));
  }, []);

  const loadMoreGames = (): void => {
    const newPage = paginationState.page + 1;
    dispatchPagination({ type: 'SET_PAGE', payload: newPage });
    getGames(history, newPage);
  };

  const displayTypes = [
    { label: 'Card', value: 'card' },
    { label: 'Line', value: 'line' },
  ];

  const displayColors = [
    { label: 'Color on Side (blue / red)', value: 'side' },
    { label: 'Color on Result (green / red)', value: 'result' },
  ];

  const displaySides = [
    { label: 'BK ROG on game side', value: 'side' },
    { label: 'BK ROG always on the left', value: 'left' },
  ];

  return (
    <div className={style.container}>
      <div className={style.title}>
        {!is_main && 'Academy '}
        {is_blitz && 'Blitz'} Scrim results
      </div>

      <div className={style.subtitle}>Latest results</div>

      <div className={style.teamsFilter}>
        <Multiselect
          options={formState.teams}
          onSelect={(list: any[]) => dispatch({ type: 'SET_FILTERED_TEAMS', payload: list })}
          onRemove={(list: any[]) => dispatch({ type: 'SET_FILTERED_TEAMS', payload: list })}
          displayValue="full_name"
          style={{
            optionContainer: { backgroundColor: '#1D2E3E' },
            searchBox: { backgroundColor: 'rgb(167, 167, 171)' },
          }}
          placeholder="Search team"
        />
      </div>

      <div className={style.teamsFilter}>
        <Multiselect
          options={champions}
          onSelect={(list: any[]): void =>
            dispatch({ type: 'SET_FILTERED_CHAMPIONS', payload: list })
          }
          onRemove={(list: any[]): void =>
            dispatch({ type: 'SET_FILTERED_CHAMPIONS', payload: list })
          }
          displayValue="name"
          style={{
            optionContainer: { backgroundColor: '#1D2E3E' },
            searchBox: { backgroundColor: 'rgb(167, 167, 171)' },
          }}
          placeholder="Search ally champion"
        />
      </div>

      <div className={style.filters}>
        <div className={style.dataFilters}>
          <div className={style.teamsFilter}>
            <input
              type="checkbox"
              name="blueSide"
              checked={sides.includes('blue')}
              className={style.inputCheckbox}
              onChange={(): void =>
                sides.includes('blue')
                  ? setSides(sides.filter(side => side !== 'blue'))
                  : setSides(sides.concat(['blue']))
              }
            />
            <label htmlFor="blueSide">Blue side</label>
            <input
              type="checkbox"
              name="redSide"
              checked={sides.includes('red')}
              className={style.inputCheckbox}
              onChange={(): void =>
                sides.includes('red')
                  ? setSides(sides.filter(side => side !== 'red'))
                  : setSides(sides.concat(['red']))
              }
            />
            <label htmlFor="redSide">Red side</label>
          </div>

          <div className={style.teamsFilter}>
            <input
              type="checkbox"
              name="winCheckbox"
              checked={results.includes(true)}
              className={style.inputCheckbox}
              onChange={(): void =>
                results.includes(true)
                  ? setResults(results.filter(result => !result))
                  : setResults(results.concat([true]))
              }
            />
            <label htmlFor="winCheckbox">Wins</label>
            <input
              type="checkbox"
              name="loseCheckbox"
              checked={results.includes(false)}
              className={style.inputCheckbox}
              onChange={(): void =>
                results.includes(false)
                  ? setResults(results.filter(result => !!result))
                  : setResults(results.concat([false]))
              }
            />
            <label htmlFor="loseCheckbox">Losses</label>
          </div>
        </div>

        <div>
          <div>Display options</div>
          <RadioButton
            name="display-type"
            options={displayTypes}
            current={displayType}
            onChange={d => setDisplayType(d.currentTarget.value)}
          />

          <RadioButton
            name="display-color"
            options={displayColors}
            current={displayColor}
            onChange={d => setDisplayColor(d.currentTarget.value)}
          />

          <RadioButton
            name="display-side"
            options={displaySides}
            current={displaySide}
            onChange={d => setDisplaySide(d.currentTarget.value)}
          />
        </div>
      </div>

      <div className={style.history}>
        <InfiniteScroll
          dataLength={history.length}
          next={loadMoreGames}
          hasMore={paginationState.hasMore}
          loader={<h4>Loading...</h4>}
        >
          {displayType === 'card' ? (
            <>
              {history.length > 0 &&
                history
                  .filter(game => sides.includes(game.side))
                  .filter(game => game.is_blitz || results.includes(game.result))
                  .map((game: ScrimGame) => (
                    <ScrimCard
                      game={game}
                      key={game.id}
                      champions={champions}
                      displayColor={displayColor}
                      displaySide={displaySide}
                    />
                  ))}
            </>
          ) : (
            <ScrimTable
              games={history
                .filter(game => sides.includes(game.side))
                .filter(game => game.is_blitz || results.includes(game.result))}
              displayColor={displayColor}
              displaySide={displaySide}
            />
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Scrims;
